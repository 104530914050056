<template>
  <b-container fluid class="content">
    <div class="so-list" v-if="currpage == 'list'">
      <b-card>
        <b-form>
          <el-row :gutter="10" style="margin-bottom: 10px">
            <el-col :span="6">
              <b-input-group :prepend="$t('Start Date')">
                <flat-pickr
                  :config="picker_config2"
                  class="form-control datepicker"
                  v-model="cur_start_dt"
                >
                </flat-pickr>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group :prepend="$t('End Date')">
                <flat-pickr
                  :config="picker_config2"
                  class="form-control datepicker"
                  v-model="cur_end_dt"
                >
                </flat-pickr>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group :prepend="$t('Date Type') + ' : '">
                <select class="form-control" v-model="cur_date_type">
                  <option value=""></option>
                  <option value="Order">{{ $t("Order Date") }}</option>
                  <option value="Invoice">{{ $t("Invoice Date") }}</option>
                  <option value="Delivery">{{ $t("Delivery Date") }}</option>
                  <option value="Return">{{ $t("Return Date") }}</option>
                </select>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group :prepend="$t('Customer') + ' : '">
                <el-autocomplete
                  class="inline-input el-dropdown6"
                  v-model="cur_customer_search"
                  :fetch-suggestions="querySearch_list"
                  :placeholder="$t('Select Customer')"
                  @select="listpage_customer_selected"
                  :clearable="true"
                  @clear="customer_clear_list"
                >
                  <template slot-scope="{ item }">
                    <span>{{ item.CompanyName }}</span>
                  </template>
                </el-autocomplete>
              </b-input-group>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="margin-bottom: 10px">
            <el-col :span="6">
              <b-input-group :prepend="$t('Order #') + ' : '">
                <b-form-input v-model="cur_order_no"></b-form-input>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group :prepend="$t('Invoice #') + ' : '">
                <b-form-input v-model="cur_invoice_no"></b-form-input>
              </b-input-group>
            </el-col>
            <el-col :span="6" v-if="!is_shipping()">
              <b-input-group :prepend="$t('Return #') + ' : '">
                <b-form-input v-model="cur_return_no"></b-form-input>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group :prepend="$t('Remarks') + ' : '">
                <b-form-input v-model="cur_remarks"></b-form-input>
              </b-input-group>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <SearchAutocomplete
                :url="apiBaseUrl + '/Web/product/search_allforlist'"
                :placeholdertxt="$t('PLU/Barcode/Name') + ' : '"
                id="list_search_product"
                @input="set_cur_product_name4"
              ></SearchAutocomplete>
            </el-col>
            <el-col :span="6">
              <b-input-group :prepend="$t('Store') + ' : '">
                <select class="form-control" v-model="cur_LocationID">
                  <option value=""></option>
                  <option
                    v-for="store in store_list"
                    :key="store.LocationID"
                    :value="store.LocationID"
                  >
                    {{ store.LocationName }}
                  </option>
                </select>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group :prepend="$t('Sale') + ' : '">
                <select class="form-control" v-model="cur_user_idn">
                  <option value=""></option>
                  <option
                    v-for="sales in sales_list"
                    :key="sales.IDn"
                    :value="sales.IDn"
                  >
                    {{ sales.UserName }}
                  </option>
                </select>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <el-button
                type="primary"
                class="mt-2"
                icon="el-icon-search"
                size="small"
                @click="get_lists"
                >{{ $t("Search") }}</el-button
              >
              <el-button
                type="primary"
                class="mt-2"
                icon="el-icon-refresh-right"
                size="small"
                @click="reset_search"
                >{{ $t("Reset") }}</el-button
              >
              <el-button
                v-if="!is_shipping()"
                type="primary"
                class="mt-2"
                icon="el-icon-plus"
                size="small"
                @click="add_draft_so"
                v-acl:NewSO.enable.show
                >{{ $t("Add Draft") }}</el-button
              >
            </el-col>
          </el-row>
        </b-form>
      </b-card>
      <b-card>
        <el-tabs v-model="activeName" @tab-click="clickTab">
          <el-tab-pane :label="$t('Draft')" name="Draft" v-if="!is_shipping()">
            <el-table
              v-if="activeName == 'Draft'"
              stripe
              class="table-responsive table product-table mt-2"
              style="width: 100%"
              header-row-class-name="thead-light"
              :cell-style="{ textAlign: 'center' }"
              :data="cur_so_list"
              @row-click="edit_so"
            >
              <el-table-column label="OrderDate" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">
                    {{ $t("Order Date") }}
                  </div>
                </template>
                <template v-slot="{ row }">
                  {{ row.OrderDate.substr(0, 10) }}
                </template>
              </el-table-column>
              <el-table-column label="Customer" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Customer") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{
                    row.SoldToOtherName
                      ? row.SoldToName + "(" + row.SoldToOtherName + ")"
                      : row.SoldToName
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="Total"
                prop="TotalAmount"
                class="p-0"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Total") }}</div>
                </template>
                <template v-slot="{ row }">
                  ${{ row.TotalAmount }}
                </template>
              </el-table-column>
              <el-table-column
                label="Store"
                prop="ComTitle"
                class="p-0"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Store") }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="BySales"
                prop="OrderByName"
                class="p-0"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("BySales") }}</div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane :label="$t('Pending')" name="Pending">
            <el-table
              v-if="activeName == 'Pending'"
              stripe
              class="table-responsive table product-table mt-2"
              style="width: 100%"
              header-row-class-name="thead-light"
              :data="cur_so_list"
              :cell-style="{ textAlign: 'center' }"
              @row-click="edit_so"
            >
              <el-table-column
                label="OrderNo"
                prop="OrderID"
                class="p-0"
                min-width="100px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("OrderNo") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{ row.OrderID }}
                </template>
              </el-table-column>
              <el-table-column label="OrderDate" class="p-0" min-width="100px" v-if="!is_shipping()">
                <template slot="header">
                  <div class="w-100 p-0 text-center">
                    {{ $t("Order Date") }}
                  </div>
                </template>
                <template v-slot="{ row }">
                  <div class="w-100 p-0 text-center">
                    {{ row.OrderDate.substr(0, 10) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="DeliveryDate" class="p-0" min-width="100px" v-if="is_shipping()">
                <template slot="header">
                  <div class="w-100 p-0 text-center">
                    {{ $t("Delivery Date") }}
                  </div>
                </template>
                <template v-slot="{ row }">
                  <div class="w-100 p-0 text-center">
                    {{ row.ShipDate.substr(0, 10) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="Customer"
                class="p-0"
                min-width="100px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Customer") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{
                    row.SoldToOtherName
                      ? row.SoldToName + "(" + row.SoldToOtherName + ")"
                      : row.SoldToName
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="Total"
                prop="TotalAmount"
                class="p-0"
                align="center"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Total") }}</div>
                </template>
                <template v-slot="{ row }">
                  ${{ row.TotalAmount }}
                </template>
              </el-table-column>
              <el-table-column
                label="Store"
                prop="ComTitle"
                class="p-0"
                min-width="100px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Store") }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="BySales"
                prop="OrderByName"
                class="p-0"
                min-width="100px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("BySales") }}</div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane :label="$t('Complete')" name="Complete" v-if="!is_shipping()">
            <el-table
              v-if="activeName == 'Complete'"
              stripe
              class="table-responsive table product-table mt-2"
              style="width: 100%"
              header-row-class-name="thead-light"
              :data="cur_so_list"
              :cell-style="{ textAlign: 'center' }"
              @row-click="edit_so"
              show-summary
              :summary-method="getSummariesForComplete"
            >
              <el-table-column
                label="InvoiceDate"
                prop="SOEndDate"
                class="p-0"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">
                    {{ $t("InvoiceDate") }}
                  </div>
                </template>
                <template v-slot="{ row }">
                  {{ row.SOEndDate && row.SOEndDate.length > 10 ? row.SOEndDate.substr(0, 10) : row.SOEndDate }}
                </template>
              </el-table-column>
              <el-table-column
                label="Invoice"
                prop="InvoiceNo"
                class="p-0"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">
                    {{ $t("Invoice") }}
                  </div>
                </template>
                <template v-slot="{ row }">
                  {{ row.InvoiceNo ? (row.SubInvoiceNo ? row.InvoiceNo + "-" + row.SubInvoiceNo : row.InvoiceNo) : "-" }}
                </template>
              </el-table-column>
              <el-table-column
                label="OrderNo"
                prop="OrderID"
                class="p-0"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("OrderNo") }}</div>
                </template>
              </el-table-column>
              <el-table-column label="Customer" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Customer") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{
                    row.SoldToOtherName
                      ? row.SoldToName + "(" + row.SoldToOtherName + ")"
                      : row.SoldToName
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="Total"
                prop="TotalAmount"
                class="p-0"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Total") }}</div>
                </template>
                <template v-slot="{ row }">
                  ${{ row.TotalAmount }}
                </template>
              </el-table-column>
              <el-table-column label="Balance" prop="Balance" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Balance") }}</div>
                </template>
                <template v-slot="{ row }">
                  ${{ parseFloat(row.Balance).toFixed(2) }}
                </template>
              </el-table-column>
              <el-table-column label="Returns" prop="ReturnAmount" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Returns") }}</div>
                </template>
                <template v-slot="{ row }">
                  ${{ parseFloat(row.ReturnAmount).toFixed(2) }}
                </template>
              </el-table-column>
              <el-table-column
                label="ShipStatus"
                prop="ShipStatus"
                class="p-0"
                min-width="120px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Shipping Status") }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="Partial"
                prop="Partial"
                class="p-0"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Status") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{ row.Partial ? row.Partial : row.Status }}
                </template>
              </el-table-column>
              <el-table-column
                label="Store"
                prop="ComTitle"
                class="p-0"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Store") }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="BySales"
                prop="OrderByName"
                class="p-0"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("BySales") }}</div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane :label="$t('BackOrder')" name="BackOrder">
            <el-table
              v-if="activeName == 'BackOrder'"
              stripe
              class="table-responsive table product-table mt-2"
              style="width: 100%"
              header-row-class-name="thead-light"
              :data="cur_so_list"
              :cell-style="{ textAlign: 'center' }"
              @row-click="edit_so"
            >
              <el-table-column
                label="OrderNo"
                prop="OrderID"
                class="p-0"
                min-width="100px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("OrderNo") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{ row.OrderID }}
                </template>
              </el-table-column>
              <el-table-column label="OrderDate" class="p-0" min-width="100px" v-if="!is_shipping()">
                <template slot="header">
                  <div class="w-100 p-0 text-center">
                    {{ $t("Order Date") }}
                  </div>
                </template>
                <template v-slot="{ row }">
                  <div class="w-100 p-0 text-center">
                    {{ row.OrderDate.substr(0, 10) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="DeliveryDate" class="p-0" min-width="100px" v-if="is_shipping()">
                <template slot="header">
                  <div class="w-100 p-0 text-center">
                    {{ $t("Delivery Date") }}
                  </div>
                </template>
                <template v-slot="{ row }">
                  <div class="w-100 p-0 text-center">
                    {{ row.ShipDate.substr(0, 10) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="Customer"
                class="p-0"
                min-width="100px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Customer") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{
                    row.SoldToOtherName
                      ? row.SoldToName + "(" + row.SoldToOtherName + ")"
                      : row.SoldToName
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="Total"
                prop="TotalAmount"
                class="p-0"
                align="center"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Total") }}</div>
                </template>
                <template v-slot="{ row }">
                  ${{ row.TotalAmount }}
                </template>
              </el-table-column>
              <el-table-column
                label="Store"
                prop="ComTitle"
                class="p-0"
                min-width="100px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Store") }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="BySales"
                prop="OrderByName"
                class="p-0"
                min-width="100px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("BySales") }}</div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane :label="$t('Packed')" name="Packed" v-if="is_shipping()">
            <el-table
              v-if="activeName == 'Packed'"
              stripe
              class="table-responsive table product-table mt-2"
              style="width: 100%"
              header-row-class-name="thead-light"
              :data="cur_so_list"
              :cell-style="{ textAlign: 'center' }"
              @row-click="edit_so"
            >
              <el-table-column
                label="OrderNo"
                prop="OrderID"
                class="p-0"
                min-width="100px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("OrderNo") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{ row.OrderID }}
                </template>
              </el-table-column>
              <el-table-column label="OrderDate" class="p-0" min-width="100px" v-if="!is_shipping()">
                <template slot="header">
                  <div class="w-100 p-0 text-center">
                    {{ $t("Order Date") }}
                  </div>
                </template>
                <template v-slot="{ row }">
                  <div class="w-100 p-0 text-center">
                    {{ row.OrderDate.substr(0, 10) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="DeliveryDate" class="p-0" min-width="100px" v-if="is_shipping()">
                <template slot="header">
                  <div class="w-100 p-0 text-center">
                    {{ $t("Delivery Date") }}
                  </div>
                </template>
                <template v-slot="{ row }">
                  <div class="w-100 p-0 text-center">
                    {{ row.ShipDate.substr(0, 10) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="Customer"
                class="p-0"
                min-width="100px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Customer") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{
                    row.SoldToOtherName
                      ? row.SoldToName + "(" + row.SoldToOtherName + ")"
                      : row.SoldToName
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="Total"
                prop="TotalAmount"
                class="p-0"
                align="center"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Total") }}</div>
                </template>
                <template v-slot="{ row }">
                  ${{ row.TotalAmount }}
                </template>
              </el-table-column>
              <el-table-column
                label="Store"
                prop="ComTitle"
                class="p-0"
                min-width="100px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Store") }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="BySales"
                prop="OrderByName"
                class="p-0"
                min-width="100px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("BySales") }}</div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane :label="$t('Returns')" name="Return" v-if="!is_shipping()">
            <el-table
              v-if="activeName == 'Return'"
              stripe
              class="table-responsive table product-table mt-2"
              style="width: 100%"
              header-row-class-name="thead-light"
              :data="cur_so_list"
              :cell-style="{ textAlign: 'center' }"
              @row-click="edit_so"
              show-summary
              :summary-method="getSummariesForComplete"
            >
              <el-table-column
                label="InvoiceDate"
                prop="SOEndDate"
                class="p-0"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">
                    {{ $t("InvoiceDate") }}
                  </div>
                </template>
                <template v-slot="{ row }">
                  {{ row.SOEndDate && row.SOEndDate.length > 10 ? row.SOEndDate.substr(0, 10) : row.SOEndDate }}
                </template>
              </el-table-column>
              <el-table-column
                label="Invoice"
                prop="InvoiceNo"
                class="p-0"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">
                    {{ $t("Invoice") }}
                  </div>
                </template>
                <template v-slot="{ row }">
                  {{ row.InvoiceNo ? (row.SubInvoiceNo ? row.InvoiceNo + "-" + row.SubInvoiceNo : row.InvoiceNo) : "-" }}
                </template>
              </el-table-column>
              <el-table-column
                label="OrderNo"
                prop="OrderID"
                class="p-0"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("OrderNo") }}</div>
                </template>
              </el-table-column>
              <el-table-column label="Customer" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Customer") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{
                    row.SoldToOtherName
                      ? row.SoldToName + "(" + row.SoldToOtherName + ")"
                      : row.SoldToName
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="Total"
                prop="TotalAmount"
                class="p-0"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Total") }}</div>
                </template>
                <template v-slot="{ row }">
                  ${{ row.TotalAmount }}
                </template>
              </el-table-column>
              <el-table-column label="Balance" prop="Balance" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Balance") }}</div>
                </template>
                <template v-slot="{ row }">
                  ${{ parseFloat(row.Balance).toFixed(2) }}
                </template>
              </el-table-column>
              <el-table-column label="Returns" prop="ReturnAmount" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Returns") }}</div>
                </template>
                <template v-slot="{ row }">
                  ${{ parseFloat(row.ReturnAmount).toFixed(2) }}
                </template>
              </el-table-column>
              <el-table-column
                label="Store"
                prop="ComTitle"
                class="p-0"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Store") }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="BySales"
                prop="OrderByName"
                class="p-0"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("BySales") }}</div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane :label="$t('Deleted')" name="Deleted" v-if="!is_shipping()">
            <el-table
              v-if="activeName == 'Deleted'"
              stripe
              class="table-responsive table product-table mt-2"
              style="width: 100%"
              header-row-class-name="thead-light"
              :data="cur_so_list"
              :cell-style="{ textAlign: 'center' }"
              @row-click="edit_so"
            >
              <el-table-column
                label="OrderDate"
                prop="OrderDate"
                class="p-0"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">
                    {{ $t("Order Date") }}
                  </div>
                </template>
                <template v-slot="{ row }">
                  {{ row.OrderDate.substr(0, 10) }}
                </template>
              </el-table-column>
              <el-table-column
                label="OrderNo"
                prop="OrderID"
                class="p-0"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("OrderNo") }}</div>
                </template>
              </el-table-column>
              <el-table-column label="Customer" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Customer") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{
                    row.SoldToOtherName
                      ? row.SoldToName + "(" + row.SoldToOtherName + ")"
                      : row.SoldToName
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="Total"
                prop="TotalAmount"
                class="p-0"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Total") }}</div>
                </template>
                <template v-slot="{ row }">
                  ${{ row.TotalAmount }}
                </template>
              </el-table-column>
              <el-table-column
                label="Store"
                prop="ComTitle"
                class="p-0"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Store") }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="BySales"
                prop="OrderByName"
                class="p-0"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("BySales") }}</div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane :label="$t('Prepaid')" name="PreOrder" v-if="false">
            <el-table
              stripe
              class="table-responsive table product-table mt-2"
              style="width: 100%"
              header-row-class-name="thead-light"
              :data="cur_so_list"
              @row-click="edit_so"
            >
              <el-table-column
                label="ShippingDate"
                prop="ShipDate"
                class="p-0"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">
                    {{ $t("ShippingDate") }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="OrderNo"
                prop="OrderID"
                class="p-0"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("OrderNo") }}</div>
                </template>
              </el-table-column>
              <el-table-column label="Customer" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Customer") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{
                    row.SoldToOtherName
                      ? row.SoldToName + "(" + row.SoldToOtherName + ")"
                      : row.SoldToName
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="Total"
                prop="TotalAmount"
                class="p-0"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Total") }}</div>
                </template>
                <template v-slot="{ row }">
                  ${{ row.TotalAmount }}
                </template>
              </el-table-column>
              <el-table-column label="Balance" prop="Balance" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Balance") }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="Store"
                prop="ComTitle"
                class="p-0"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Store") }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="BySales"
                prop="OrderByName"
                class="p-0"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("BySales") }}</div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane :label="$t('Recurrent')" name="Contract" v-if="false">
            <el-table
              stripe
              class="table-responsive table product-table mt-2"
              style="width: 100%"
              header-row-class-name="thead-light"
              :data="cur_so_list"
              :cell-style="{ textAlign: 'center' }"
              @row-click="edit_so"
            >
              <el-table-column
                label="OrderNo"
                prop="OrderID"
                class="p-0"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("OrderNo") }}</div>
                </template>
              </el-table-column>
              <el-table-column label="Customer" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Customer") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{
                    row.SoldToOtherName
                      ? row.SoldToName + "(" + row.SoldToOtherName + ")"
                      : row.SoldToName
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="Total"
                prop="TotalAmount"
                class="p-0"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Total") }}</div>
                </template>
                <template v-slot="{ row }">
                  ${{ row.TotalAmount }}
                </template>
              </el-table-column>
              <el-table-column
                label="BySales"
                prop="OrderByName"
                class="p-0"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("BySales") }}</div>
                </template>
              </el-table-column>
              <el-table-column label="RepeatType" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">
                    {{ $t("RepeatType") }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Frequency" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Frequency") }}</div>
                </template>
              </el-table-column>
              <el-table-column label="Warehouse" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Warehouse") }}</div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane :label="$t('SpecialReturn')" name="Special" v-if="false">
            <el-table
              stripe
              class="table-responsive table product-table mt-2"
              style="width: 100%"
              header-row-class-name="thead-light"
              :data="cur_so_list"
              :cell-style="{ textAlign: 'center' }"
              @row-click="edit_so"
            >
              <el-table-column label="OrderDate" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">
                    {{ $t("Order Date") }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="OrderNo" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("OrderNo") }}</div>
                </template>
              </el-table-column>
              <el-table-column label="Customer" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Customer") }}</div>
                </template>
              </el-table-column>
              <el-table-column label="Total" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Total") }}</div>
                </template>
              </el-table-column>
              <el-table-column label="Status" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Status") }}</div>
                </template>
              </el-table-column>
              <el-table-column label="Store" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Store") }}</div>
                </template>
              </el-table-column>
              <el-table-column label="BySales" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("BySales") }}</div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
        <el-row style="margin-top: 20px">
          <el-col :span="20">
            <div>
              <b-pagination
                v-model="current_page"
                :total-rows="page_total"
                :per-page="per_page"
              ></b-pagination>
            </div>
          </el-col>
          <el-col :span="4">
            <div>
              <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">
                  {{ $t("Max Rows per page") }} {{ this.per_page
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="page_option in per_page_option"
                    :key="page_option"
                    :command="page_option"
                    >{{ page_option }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </el-col>
        </el-row>
      </b-card>
    </div>
    <div class="so-edit" v-if="currpage == 'edit'">
      <el-row v-if="cur_so">
        <el-col>
          <div class="so_title p-2" v-if="is_link_so">
            {{ link_from_so.OrderID }} > {{ cur_so.OrderID }} {{ cur_so.Partial ? cur_so.Partial : cur_so.Status }}
          </div>
          <div class="so_title p-2" v-else>
            {{ cur_so.OrderID }} {{ cur_so.Partial ? cur_so.Partial : cur_so.Status }}
          </div>
        </el-col>
      </el-row>
      <el-collapse v-model="activeCollapse1">
        <el-collapse-item
          class="collapseheader addressinfo"
          :title="$t('Address Info')"
          name="AddressInfo"
          @click.native="calculate_el_select_width2()"
        >
          <el-row :gutter="10">
            <!----------------------------------- Customer form ----------------------------------->
            <el-col :span="6">
              <el-card shadow="always">
                <div slot="header" class="clearfix">
                  <el-row>
                    <el-col :span="14">
                      <div class="so_card_title">
                      {{ $t("Customer") }}
                      </div>
                    </el-col>
                    <el-col :span="8" style="float: right" v-if="is_so_draft()">
                      <div style="float: right">
                        <el-button type="primary" icon="el-icon-edit" circle @click="disableCustomerInput" v-acl:SOEditAddress.enable.show></el-button>
                        <el-button type="success" icon="el-icon-plus" circle @click="addNewCustomer" v-acl:SOEditAddress.enable.show></el-button>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <div>
                  <section style="margin: 0 5px; height: 300px; margin-bottom: 29px;"> 
                    <el-row>
                      <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                        {{ $t("Name") + " : " }}
                      </el-col>
                      <el-col :span="16">
                        <el-autocomplete
                          class="inline-input el-dropdown2"
                          v-model="cur_so_customer_search"
                          :fetch-suggestions="querySearch_edit"
                          :placeholder="$t('Select Customer')"
                          @select="customer_selected"
                          :disabled="!is_so_draft() || (cur_so_payment != undefined && cur_so_payment.length > 0)"
                          :clearable="true"
                          @clear="customer_clear_edit"
                        >
                          <template slot-scope="{ item }">
                            <span>{{ item.CompanyName }}</span>
                          </template>
                        </el-autocomplete>
                      </el-col>
                    </el-row>                 
                    <el-row>
                      <el-col :span="8" class="text-right pr-3">
                        {{ $t("Other Name") + " : " }}
                      </el-col>
                      <el-col :span="16">
                        <b-input
                          class="form-control"
                          :disabled="disable_customer_input"
                          v-model="cur_so.SoldToOtherName"
                        />
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="text-right pr-3">
                        {{ $t("Contact") + " : " }}
                      </el-col>
                      <el-col :span="16">
                          <b-input
                            class="form-control"
                            :disabled="disable_customer_input"
                            v-model="cur_so.SoldToContact"
                          />
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="text-right pr-3">
                        {{ $t("Tel") + " : " }}
                      </el-col>
                      <el-col :span="16">
                          <b-input
                            class="form-control"
                            :disabled="disable_customer_input"
                            v-model="cur_so.SoldToTEL"
                          />
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="text-right pr-3">
                        {{ $t("Fax") + " : " }}
                      </el-col>
                      <el-col :span="16">
                          <b-input
                            class="form-control"
                            :disabled="disable_customer_input"
                            v-model="cur_so.SoldToFAX"
                          />
                      </el-col>
                    </el-row>
                  </section>
                </div>
              </el-card>
            </el-col>
            <!----------------------------------- Billing address form ----------------------------------->
            <el-col :span="6">
              <el-card shadow="always">
                <div slot="header" class="clearfix">
                  <el-row>
                    <el-col :span="14">
                      <div class="so_card_title">
                        {{ $t("Billing Address") }}
                      </div>
                    </el-col>
                    <el-col :span="8" style="float: right" v-if="!is_view_mode()">
                      <div style="float: right">
                        <el-button type="primary" icon="el-icon-edit" circle :disabled="to_lock_so() || is_view_mode()"
                                   @click="disableBillingInput" v-acl:SOEditAddress.enable.show></el-button>
                        <el-button type="success" icon="el-icon-plus" circle :disabled="to_lock_so() || is_view_mode()"
                                   @click="addNewBilling" v-acl:SOEditAddress.enable.show></el-button>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <div>
                  <section style="margin: 0 5px; height: 300px; margin-bottom: 29px;"> 
                    <el-row>
                      <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                        {{ $t("Name") + " : " }}
                      </el-col>
                        <el-col :span="16">
                        <el-select
                          class="el-dropdown2"
                          v-model="cur_so.SoldToAddressIDn"
                          :disabled="to_lock_so() || is_view_mode()"
                        >
                          <el-option
                            v-for="addr in cur_address_list"
                            :key="addr.IDn"
                            :value="addr.IDn"
                            :label="addr.Name"
                            @click.native="setinput_soldto_address();calculate_el_select_width2();"
                          >
                          </el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                        {{ $t("Address1") + " : " }}
                      </el-col>
                      <el-col :span="16">
                        <b-input
                          class="form-control"
                          :disabled="disable_billing_input"
                          v-model="cur_so.SoldToAddress1"
                        />
                      </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                        {{ $t("Address2") + " : " }}
                      </el-col>
                      <el-col :span="16">
                        <b-input
                          class="form-control"
                          :disabled="disable_billing_input"
                          v-model="cur_so.SoldToAddress2"
                        />
                      </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                        {{ $t("Postal Code") + " : " }}
                      </el-col>
                      <el-col :span="16">
                        <b-input
                          class="form-control"
                          :disabled="disable_billing_input"
                          v-model="cur_so.SoldToZIP"
                        />
                      </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                        {{ $t("Country") + " : " }}
                      </el-col>
                      <el-col :span="8">
                        <el-select
                          class="el-dropdown2"
                          v-model="cur_so.SoldToCountryIDn"
                          :disabled="disable_billing_input"
                        >
                          <el-option
                            v-for="(country, IDn) in country_list"
                            :key="IDn"
                            :label="country.Country"
                            :value="parseInt(IDn)"
                            @click.native="changed_country(cur_so.SoldToCountryIDn, 2)"
                          >
                          </el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                        {{ $t("Province") + " : " }}
                      </el-col>
                      <el-col :span="8">
                        <el-select
                          class="el-dropdown2"
                          v-model="cur_so.SoldToProvinceIDn"
                          :disabled="disable_billing_input"
                        >
                          <el-option
                            v-for="province in cur_billing_provincelist"
                            :key="province.IDn"
                            :label="province.Province"
                            :value="province.IDn"
                            @click.native="soldto_province_changed()"
                          >
                          </el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                        {{ $t("City") + " : " }}
                      </el-col>
                      <el-col :span="8">
                        <base-input>
                          <InputAutocomplete
                            :key="cur_so.SoldToCity"
                            :items="city_list"
                            :initval="cur_so.SoldToCity"
                            :disable="disable_billing_input"
                            v-model="cur_so.SoldToCity"
                            @input="setinput_city(cur_so.SoldToCity, 2)"
                            class="el-dropdown3"
                          ></InputAutocomplete>
                        </base-input>
                      </el-col>
                    </el-row>
                  </section>
                </div>
              </el-card>
            </el-col>
            <!----------------------------------- Shipping address form ----------------------------------->
            <el-col :span="6">
              <el-card shadow="always">
                <div slot="header" class="clearfix">
                  <el-row>
                    <el-col :span="14">
                      <div class="so_card_title">
                        {{ $t("Shipping Address") }}
                      </div>
                    </el-col>
                    <el-col :span="8" style="float: right" v-if="!is_view_mode()">
                      <div style="float: right">
                        <el-button type="primary" icon="el-icon-edit" circle :disabled="to_lock_so() || is_view_mode()"
                                   @click="disableShippingInput" v-acl:SOEditAddress.enable.show></el-button>
                        <el-button type="success" icon="el-icon-plus" circle :disabled="to_lock_so() || is_view_mode()"
                                   @click="addNewShipping" v-acl:SOEditAddress.enable.show></el-button>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <div>
                  <section style="margin: 0 5px; height: 300px; margin-bottom: 29px;"> 
                    <el-row>
                      <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                        {{ $t("Name") + " : " }}
                      </el-col>
                        <el-col :span="16">
                        <el-select
                          class="el-dropdown2"
                          v-model="cur_so.ShipToAddressIDn"
                          :disabled="to_lock_so() || is_view_mode()"
                        >
                          <el-option
                            v-for="addr in cur_address_list"
                            :key="addr.IDn"
                            :value="addr.IDn"
                            :label="addr.Name"
                            @click.native="setinput_shipto_address();calculate_el_select_width2();"
                          >
                          </el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                        {{ $t("Address1") + " : " }}
                      </el-col>
                      <el-col :span="16">
                        <b-input
                          class="form-control"
                          :disabled="disable_shipping_input"
                          v-model="cur_so.ShipToAddress1"
                        />
                      </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                        {{ $t("Address2") + " : " }}
                      </el-col>
                      <el-col :span="16">
                        <b-input
                          class="form-control"
                          :disabled="disable_shipping_input"
                          v-model="cur_so.ShipToAddress2"
                        />
                      </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                        {{ $t("Postal Code") + " : " }}
                      </el-col>
                      <el-col :span="16">
                        <b-input
                          class="form-control"
                          :disabled="disable_shipping_input"
                          v-model="cur_so.ShipToZIP"
                        />
                      </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                        {{ $t("Country") + " : " }}
                      </el-col>
                      <el-col :span="8">
                        <el-select
                          class="el-dropdown2"
                          v-model="cur_so.ShipToCountryIDn"
                          :disabled="disable_shipping_input"
                        >
                          <el-option
                            v-for="(country, IDn) in country_list"
                            :key="IDn"
                            :label="country.Country"
                            :value="parseInt(IDn)"
                            @click.native="changed_country(cur_so.ShipToCountryIDn, 3)"
                          >
                          </el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                        {{ $t("Province") + " : " }}
                      </el-col>
                      <el-col :span="8">
                        <el-select
                          class="el-dropdown2"
                          v-model="cur_so.ShipToProvinceIDn"
                          :disabled="disable_shipping_input"
                        >
                          <el-option
                            v-for="province in cur_shipping_provincelist"
                            :key="province.IDn"
                            :label="province.Province"
                            :value="province.IDn"
                          >
                          </el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                        {{ $t("City") + " : " }}
                      </el-col>
                      <el-col :span="8">
                        <base-input>
                          <InputAutocomplete
                            :key="cur_so.ShipToCity"
                            :items="city_list"
                            :initval="cur_so.ShipToCity"
                            :disable="disable_shipping_input"
                            v-model="cur_so.ShipToCity"
                            @input="setinput_city(cur_so.ShipToCity, 3)"
                            class="el-dropdown3"
                          ></InputAutocomplete>
                        </base-input>
                      </el-col>
                    </el-row>
                  </section>
                </div>
              </el-card>
            </el-col>
            <!----------------------------------- store form ----------------------------------->
            <el-col :span="6">
              <el-card shadow="always">
                <div slot="header" class="clearfix">
                  <el-row style="height: 40px">
                    <el-col :span="14">
                      <div class="so_card_title">
                        {{ $t("Store Info") }}
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <div>
                  <section style="margin: 0 5px; height: 300px; margin-bottom: 29px;"> 
                    <el-row>
                      <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                        {{ $t("Name") + " : " }}
                      </el-col>
                        <el-col :span="16">
                        <el-select
                          class="el-dropdown2"
                          v-model="cur_so.LocationID"
                          :disabled="to_lock_so() || is_view_mode()"
                        >
                          <el-option
                            v-for="store in store_list"
                            :key="store.LocationID"
                            :value="store.LocationID"
                            :label="store.LocationName"
                            @click.native="store_changed();calculate_el_select_width2();"
                          >
                          </el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                        {{ $t("Address1") + " : " }}
                      </el-col>
                      <el-col :span="16">
                        <b-input
                          class="form-control"
                          :disabled="disable_store_input"
                          v-model="cur_so.ComAddress1"
                        />
                      </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                        {{ $t("Address2") + " : " }}
                      </el-col>
                      <el-col :span="16">
                        <b-input
                          class="form-control"
                          :disabled="disable_store_input"
                          v-model="cur_so.ComAddress2"
                        />
                      </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                        {{ $t("Postal Code") + " : " }}
                      </el-col>
                      <el-col :span="16">
                        <b-input
                          class="form-control"
                          :disabled="disable_store_input"
                          v-model="cur_so.ComZIP"
                        />
                      </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                        {{ $t("Country") + " : " }}
                      </el-col>
                      <el-col :span="8">
                        <el-select
                          class="el-dropdown2"
                          v-model="cur_so.ComCountryIDn"
                          :disabled="disable_store_input"
                        >
                          <el-option
                            v-for="(country, IDn) in country_list"
                            :key="IDn"
                            :label="country.Country"
                            :value="parseInt(IDn)"
                            @click.native="changed_country(cur_so.ComCountryIDn, 1)"
                          >
                          </el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                        {{ $t("Province") + " : " }}
                      </el-col>
                      <el-col :span="8">
                        <el-select
                          class="el-dropdown2"
                          v-model="cur_so.ComProvinceIDn"
                          :disabled="disable_store_input"
                        >
                          <el-option
                            v-for="province in cur_store_provincelist"
                            :key="province.IDn"
                            :label="province.Province"
                            :value="province.IDn"
                          >
                          </el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="8" class="text-right pr-3 pt-1" style="margin-top: 2px">
                        {{ $t("City") + " : " }}
                      </el-col>
                      <el-col :span="8">
                        <base-input>
                          <InputAutocomplete
                            :key="cur_so.ComCity"
                            :items="city_list"
                            :initval="cur_so.ComCity"
                            :disable="disable_store_input"
                            v-model="cur_so.ComCity"
                            @input="setinput_city(cur_so.ComCity, 1)"
                            class="el-dropdown3"
                          ></InputAutocomplete>
                        </base-input>
                      </el-col>
                    </el-row>
                  </section>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </el-collapse-item>
        <el-collapse-item
          class="collapseheader sodetail"
          :title="$t('SO Detail')"
          name="SODetail"
          @click.native="calculate_el_select_width2()"
        >
          <el-row :gutter="10">
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('SO Number') + ':'">
                <b-input
                  class="form-control height_adjust_input left_padding"
                  v-model="cur_so.OrderID"
                  disabled
                />
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2 w-100">
                <b-input-group-prepend
                  :style="{ flex: '1' }"
                  class="group-append-text"
                  is-text
                >
                  {{ $t("Prepaid Order") + ":" }}
                </b-input-group-prepend>
                <b-input-group-append is-text>
                  <b-form-checkbox
                    class="form-checkbox"
                    v-model="cur_so.PreOrder"
                    :value="1"
                    :unchecked-value="0"
                    switch
                    inline
                    style="float: right"
                    :disabled="!is_so_draft()"
                  >
                  </b-form-checkbox>
                </b-input-group-append>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group
                class="mb-2 mt-2"
                :prepend="$t('Customer PO NO.') + ':'"
              >
                <b-input
                  class="form-control height_adjust_input left_padding"
                  v-model="cur_so.CustomerPONumber"
                  :disabled="to_lock_so() || is_view_mode()" />
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Term') + ':'">                  
                <el-select
                  class="el-dropdown4"
                  v-model="cur_so.TermIDn"
                  :disabled="to_lock_so() || is_view_mode()"
                >
                  <el-option
                    v-for="(period, idx) in pay_period_list"
                    :key="idx"
                    :value="parseInt(period.IDn)"
                    :label="period.PayPeriod"
                  >
                  </el-option>
                </el-select>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2" :prepend="$t('Employee') + ':'">
                <el-select
                  class="el-dropdown4"
                  v-model="cur_so.OrderByIDn"
                  disabled
                >
                  <el-option
                    v-for="(sales, idx) in sales_list"
                    :key="idx"
                    :value="parseInt(sales.IDn)"
                    :label="sales.UserName"
                  >
                  </el-option>
                </el-select>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2" :prepend="$t('Order Date') + ':'">
                <flat-pickr
                  v-model="cur_so.OrderDate"
                  :config="pickr_config"
                  class="form-control height_adjust_input"
                  :disabled="to_lock_so() || is_view_mode()"
                >
                </flat-pickr>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2" :prepend="$t('Delivery Date') + ':'">
                <flat-pickr
                  v-model="cur_so.ShipDate"
                  :config="pickr_config"
                  class="form-control height_adjust_input"
                  :disabled="to_lock_so() || is_view_mode()"
                >
                </flat-pickr>
              </b-input-group>
            </el-col>
            <el-col :span="6" v-if="is_so_complete()">
              <b-input-group class="mb-2" :prepend="$t('Invoice Date') + ':'">
                <flat-pickr
                  v-model="cur_so.SOEndDate"
                  :config="pickr_config"
                  class="form-control height_adjust_input"
                  :disabled="!hasUtilityAuth('EditInvoiceDate')"
                >
                </flat-pickr>
              </b-input-group>
            </el-col>
          </el-row>
          <hr class="solid mb-2 mt-0" />
          <el-row :gutter="10">
            <el-col :span="6">
              <b-input-group class="mb-2" :prepend="$t('Shipping') + ':'">
                <el-select
                  class="el-dropdown4"
                  v-model="cur_so.ShippingMode"
                  :disabled="to_lock_so() || is_view_mode()"
                >
                  <el-option
                    v-for="item in shipping_status_option"
                    :key="item.IDn"
                    :label="item.Status"
                    :value="item.IDn"
                  >
                  </el-option>
                </el-select>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2" :prepend="$t('Carrier') + ':'">
                <el-autocomplete
                  class="inline-input el-dropdown4"
                  v-model="cur_so.CarrierName"
                  :fetch-suggestions="querySearchCarrierName"
                  :disabled="to_lock_so() || is_view_mode()"
                  @select="carrier_selected">
                  <template slot-scope="{ item }">
                    <span>{{ item.CarrierName }}</span>
                  </template>
                </el-autocomplete>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2" :prepend="$t('Container No') + ':'">
                <b-input
                  class="form-control height_adjust_input left_padding"
                  v-model="cur_so.ContainerNo"
                  :disabled="to_lock_so() || is_view_mode()"
                />
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2" :prepend="$t('Track NO.') + ':'">
                <b-input
                  class="form-control height_adjust_input left_padding"
                  v-model="cur_so.TrackNo"
                  :disabled="!is_shipping() || to_lock_so() || is_so_shipped() || is_so_deleted()"
                />
              </b-input-group>
            </el-col>
          </el-row>
        </el-collapse-item>
        <el-collapse-item
          v-if="!is_so_draft() && !is_shipping() && cur_related_orders && cur_related_orders.length > 0"
          class="collapseheader"
          :title="$t('Related Orders')"
          name="RelatedOrders"
        >
          <el-table
            stripe
            class="table-responsive table product-table mt-2"
            style="width: 100%"
            header-row-class-name="thead-light"
            :cell-style="{ textAlign: 'center' }"
            :data="cur_related_orders"
            @row-dblclick="link_other_order"
          >
            <el-table-column label="OrderNo" prop="OrderID" class="p-0" min-width="100px">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("OrderNo") }}</div>
              </template>
            </el-table-column>
            <el-table-column label="OrderDate" class="p-0" min-width="100px">
              <template slot="header">
                <div class="w-100 p-0 text-center">
                  {{ $t("Order Date") }}
                </div>
              </template>
              <template v-slot="{ row }">
                {{ row.OrderDate.substr(0, 10) }}
              </template>
            </el-table-column>
            <el-table-column label="Invoice" prop="InvoiceNo" class="p-0" min-width="100px">
              <template slot="header">
                <div class="w-100 p-0 text-center">
                  {{ $t("Invoice") }}
                </div>
              </template>
              <template v-slot="{ row }">
                {{ row.InvoiceNo ? (row.SubInvoiceNo ? row.InvoiceNo + "-" + row.SubInvoiceNo : row.InvoiceNo) : "-" }}
              </template>
            </el-table-column>
            <el-table-column label="Total" prop="TotalAmount" class="p-0" min-width="100px">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Total") }}</div>
              </template>
              <template v-slot="{ row }">
                ${{ row.TotalAmount }}
              </template>
            </el-table-column>
            <el-table-column label="Balance" prop="Balance" class="p-0" min-width="100px">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Balance") }}</div>
              </template>
              <template v-slot="{ row }">
                ${{ parseFloat(row.Balance).toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column label="Returns" prop="ReturnAmount" class="p-0" min-width="100px">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Returns") }}</div>
              </template>
              <template v-slot="{ row }">
                ${{ parseFloat(row.ReturnAmount).toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column label="Status" prop="Status" class="p-0" min-width="100px">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Status") }}</div>
              </template>
            </el-table-column>
            <el-table-column label="Remarks" prop="Remarks" class="p-0" min-width="100px">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Remarks") }}</div>
              </template>
            </el-table-column>
          </el-table>
        </el-collapse-item>
        <el-collapse-item
          class="collapseheader productlist"
          :title="$t('Product List')"
          name="ProductList"
        >
          <b-card>
            <el-row>
              <el-col :span="16" >
                <el-button
                  type="primary"
                  v-if="!is_view_mode()"
                  @click="add_product"
                  :disabled="to_lock_so() || is_view_mode()"
                  >{{ $t("Add Product") }}</el-button>
                <el-button
                  type="primary"
                  v-if="!is_view_mode()"
                  @click="last_order_product"
                  :disabled="to_lock_so() || is_view_mode()"
                  >{{ $t("Last Order") }}</el-button>
                <el-button
                  type="primary"
                  v-if="!is_view_mode()"
                  @click="import_product"
                  :disabled="to_lock_so() || is_view_mode()"
                  >{{ $t("Import") }}</el-button>
                <el-button
                  type="primary"
                  v-if="!is_so_draft() && cur_so.show_ori"
                  @click="view_original"
                  v-acl:ViewOriginalSO.enable.show
                  >{{ $t("View Original") }}</el-button>
              </el-col>
              <!-- quick search add -->
              <el-col :span="6" v-if="!is_view_mode()">
                <b-input-group :prepend="$t('PLU/Barcode/Name') + ' : '">
                  <b-form-input
                    v-model="quick_add_input"
                    style="height: 46px"
                    :disabled="to_lock_so() || is_view_mode()"
                    @keyup.enter.native="quickAdd"
                  >
                  </b-form-input>
                </b-input-group>
              </el-col>
              <el-col :span="2" v-if="!is_view_mode()">
                <el-button type="primary" icon="el-icon-plus" circle @click="quickAdd" style="margin-left: 30px"
                           :disabled="to_lock_so() || is_view_mode()"></el-button>
              </el-col>
            </el-row>
            <!------------------------- so product list -------------------------->
            <el-table
              stripe
              class="table-responsive table product-table mt-2"
              style="width: 100%"
              id="product_list"
              header-row-class-name="thead-light"
              :cell-style="{ textAlign: 'center' }"
              :data="cur_so_product"
              :style="{ marginBottom: 1.5 + 'rem' }"
              :default-expand-all=true
            >
              <el-table-column type="expand" width="1px">
                <template slot="header">
                  <div class="hide_element"></div>
                </template>
                <template slot-scope="props" >
                  <b-textarea
                    :placeholder="$t('Remarks')"
                    type="textarea"
                    rows="1"
                    max-rows="1"
                    v-model="props.row.Remarks"
                    no-resize
                    :disabled="to_lock_so() || is_so_deleted()"
                  ></b-textarea>
                  <b-textarea
                    v-if="props.row.HasSerialNumber && !is_so_draft()"
                    type="textarea"
                    rows="1"
                    max-rows="1"
                    :value="(props.row.SerialNumbers ? JSON.parse(props.row.SerialNumbers) : []).join(',')"
                    no-resize
                    disabled
                  ></b-textarea>
                </template>
              </el-table-column>
              <el-table-column prop="PLU" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("PLU") }}</div>
                </template>
              </el-table-column>
              <!-- <el-table-column prop="Barcode" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Barcode") }}</div>
                </template>
              </el-table-column> -->
              <el-table-column class="p-0" min-width="150px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">
                    {{ $t("Product Name") }}
                  </div>
                </template>
                <template v-slot="{ row }">
                  <div>
                    {{
                      row.ProductName2 && so_show_name2
                        ? row.ProductName1 + "(" + row.ProductName2 + ")"
                        : row.ProductName1
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="Unit" class="p-0" min-width="50px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Unit") }}</div>
                </template>
              </el-table-column>
              <el-table-column
                prop="OriginalPrice"
                class="p-0"
                min-width="70px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">
                    {{ $t("MSRP") }}
                  </div>
                </template>
                <template v-slot="{ row }">
                  ${{ row.OriginalPrice }}
                </template>
              </el-table-column>
              <el-table-column class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">
                    {{ $t("Sale Price") }}
                  </div>
                </template>
                <template v-slot="{ row }">
                  <div v-if="is_so_complete()">
                    ${{ row.UnitPrice }}
                  </div>
                  <div v-else>
                    <el-input
                      size="mini"
                      v-model="row.UnitPrice"
                      @change="product_unitprice_change(row)"
                      :disabled="to_lock_so() || is_view_mode()"
                    >
                      <template slot="prepend">&nbsp;$&nbsp;</template>
                    </el-input>
                  </div>
                </template>
              </el-table-column>
              <el-table-column class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Discount") }}</div>
                </template>
                <template v-slot="{ row }">
                  <div v-if="is_so_complete()">
                    {{ row.Discount }}%
                  </div>
                  <div v-else>
                    <el-input
                      size="mini"
                      v-model="row.Discount"
                      @change="product_discount_change(row)"
                      :disabled="to_lock_so() || is_view_mode()"
                    >
                      <template slot="append">&nbsp;%&nbsp;</template>
                    </el-input>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="OrderQty" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Qty") }}</div>
                </template>
                <template v-slot="{ row }">
                  <div v-if="is_so_complete()">
                    {{ row.OrderQty }}
                  </div>
                  <div v-else>
                    <el-input
                      size="mini"
                      v-model="row.OrderQty"
                      @change="qty_changed(row)"
                      :disabled="to_lock_so() || is_view_mode()"
                    ></el-input>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="TotalPrice" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Subtotal") }}</div>
                </template>
                <template v-slot="{ row }">
                  ${{ row.TotalPrice }}
                </template>
              </el-table-column>

              <!-- for shipping -->
              <el-table-column prop="ShippingQty" class="p-0" min-width="120px" v-if="!is_so_draft()">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Shipping Qty") }}</div>
                </template>
                <template v-slot="{ row }">
                  <div v-if="is_so_pending() && is_shipping()">
                    <el-input
                      size="mini"
                      v-model="row.ShippingQty"
                      @change="qty_changed(row)"
                      :disabled="to_lock_so() || is_view_mode()"
                    ></el-input>
                  </div>
                  <div v-else>
                    {{row.ShippingQty}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="BackQty" class="p-0" min-width="100px" v-if="!is_so_draft()">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Back Qty") }}</div>
                </template>
              </el-table-column>
              <!-- S/N -->
              <el-table-column class="p-0" width="60px" v-if="is_so_pending() && is_shipping()">
                <template slot="header">
                  <div class="w-100 p-0 text-center">S/N</div>
                </template>
                <template v-slot="{ row }">
                  <div
                    v-if="row.HasSerialNumber == 1"
                    class="btn-inner--icon text-center w-100"
                    @click="selectSN(row)"
                  >
                    <i class="el-icon-connection hover_hand red_color"></i>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="WarrantyDate" class="p-0" min-width="100px" v-if="is_retail && !is_so_draft()">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Warranty") }}</div>
                </template>
                <template v-slot="{ row }">
                  <div v-if="is_so_pending() && is_shipping()">
                    <flat-pickr
                      :config="pickr_config"
                      class="form-control datepicker"
                      v-model="row.WarrantyDate"
                    >
                    </flat-pickr>
                  </div>
                  <div v-else>
                    {{ row.WarrantyDate ? row.WarrantyDate.substr(0, 10) : "" }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column class="p-0" min-width="100px">
                <template v-slot="scope">
                  <div
                    v-if="scope.row.OrderQty > 0 && !(to_lock_so() || is_view_mode())"
                    class="btn-inner--icon text-center w-100"
                    @click="remove_so_product(scope.$index, scope.row)"
                  >
                    <i class="ni ni-fat-remove icon-remove"></i>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <el-row :gutter="6">
              <el-col :span="12">
                <el-input
                  :placeholder="$t('Remarks')"
                  type="textarea"
                  :autosize="{ minRows: 6, maxRows: 9 }"
                  v-model="cur_so.Remarks"
                  :disabled="is_so_deleted()"
                >
                </el-input>
              </el-col>
              <el-col :span="6" :offset="3">
                <el-row>
                  <el-col :span="12" class="text-right pr-3">
                    {{ $t("Item Discount") + " : " }}
                  </el-col>
                  <el-col :span="12">
                    {{ parseFloat(cur_so.ProductDiscount).toFixed(2) }}
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12" class="text-right pr-3">
                    {{ $t("Subtotal") + " : " }}
                  </el-col>
                  <el-col :span="12">
                    {{ parseFloat(cur_so.SubTotal).toFixed(2) }}
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12" class="text-right pr-3">
                    {{ $t("SO Discount") + " : " }}
                  </el-col>
                  <el-col :span="12">
                    {{ parseFloat(cur_so.soDiscount).toFixed(2) }}
                  </el-col>
                </el-row>
                <el-row v-for="(tax, idx) in cur_so_tax" :key="idx">
                  <el-col :span="12" class="text-right pr-3">
                    {{ tax.TaxType + " : " }}
                  </el-col>
                  <el-col :span="12">
                    {{ parseFloat(tax.TaxAmount).toFixed(2) }}
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12" class="text-right pr-3">
                    {{ $t("Other Fee") + " : " }}
                  </el-col>
                  <el-col :span="12">
                    <el-input
                      size="mini"
                      v-model="cur_so.OtherFees"
                      @change="fee_change"
                      :disabled="to_lock_so() || is_view_mode()"
                    />
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12" class="text-right pr-3">
                    {{ $t("Shipping Fee") + " : " }}
                  </el-col>
                  <el-col :span="12">
                    <el-input
                      size="mini"
                      v-model="cur_so.ShipFee"
                      @change="fee_change"
                      :disabled="to_lock_so() || is_view_mode()"
                    />
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12" class="text-right pr-3">
                    {{ $t("Total") + " : " }}
                  </el-col>
                  <el-col :span="12">
                    {{ parseFloat(cur_so.TotalAmount).toFixed(2) }}
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12" class="text-right pr-3">
                    {{ $t("Paid Amount") + " : " }}
                  </el-col>
                  <el-col :span="12">
                    {{ parseFloat(cur_so.PaidAmount).toFixed(2) }}
                  </el-col>
                </el-row>
                <el-row v-if="is_so_complete()">
                  <el-col :span="12" class="text-right pr-3">
                    {{ $t("Returned") + " : " }}
                  </el-col>
                  <el-col :span="12">
                    {{ parseFloat(cur_so.ReturnAmount).toFixed(2) }}
                  </el-col>
                </el-row>
                <el-row v-if="is_so_complete()">
                  <el-col :span="12" class="text-right pr-3">
                    {{ $t("Credit") + " : " }}
                  </el-col>
                  <el-col :span="12">
                    {{ (parseFloat(cur_so.ReturnCredit) + parseFloat(cur_so.SOCredit)).toFixed(2) }}
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12" class="text-right pr-3">
                    {{ $t("Balance") + " : " }}
                  </el-col>
                  <el-col :span="12">
                    {{
                      get_balance().toFixed(2)
                    }}
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </b-card>
        </el-collapse-item>
      </el-collapse>
      <el-collapse v-model="activeCollapse2">
        <el-collapse-item
          class="collapseheader"
          :title="$t('Returns')"
          name="Return"
          v-if="is_so_complete()"
        >
          <el-table
            stripe
            ref="returns_table"
            class="table-responsive table product-table mt-2"
            style="width: 100%"
            header-row-class-name="thead-light"
            :data="cur_so_return"
            :cell-style="{ textAlign: 'center' }"
            :style="{ marginBottom: 1.5 + 'rem' }"
          >
            <el-table-column class="p-0" min-width="100px">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Return Date") }}</div>
              </template>
              <template v-slot="{ row }">
                {{ row.ReturnDate.substr(0, 10) }}
              </template>
            </el-table-column>
            <el-table-column prop="OrderID" class="p-0" min-width="100px">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Return No.") }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="Status" class="p-0" min-width="100px">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Status") }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="ReturnBy" class="p-0" min-width="100px">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Employee") }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="TotalAmount" class="p-0" min-width="100px">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Total") }}</div>
              </template>
              <template v-slot="{ row }">
                ${{ row.TotalAmount }}
              </template>
            </el-table-column>
            <el-table-column prop="Remarks" class="p-0" min-width="100px">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Remarks") }}</div>
              </template>
            </el-table-column>
            <el-table-column class="p-0" min-width="50px" align="center">
              <template slot="header">
                <span class="btn-inner--icon" @click="init_new_return()" v-if="hasUtilityAuth('SOReturn')">
                  <i class="ni ni-fat-add icon-add">
                  </i>
                </span>
              </template>
              <template slot-scope="scope">
                <span class="btn-inner--icon" @click="view_return(scope.$index, scope.row)" v-if="hasUtilityAuth('SOReturn')">
                  <i class="el-icon-view hover_hand"></i>
                </span>
              </template>
            </el-table-column>
          </el-table>
        </el-collapse-item>
        <el-collapse-item
          class="collapseheader"
          :title="$t('Outbound')"
          name="Outbound"
          v-if="cur_outbound.length > 0"
        >
          <el-table
            stripe
            ref="outbound_table"
            class="table-responsive table product-table mt-2"
            style="width: 100%"
            header-row-class-name="thead-light"
            :data="cur_outbound"
            :cell-style="{ textAlign: 'center' }"
            :style="{ marginBottom: 1.5 + 'rem' }"
            @row-click="outbound_row_clicked_event"
          >
            <el-table-column type="expand" class="p-0" min-width="100px">
              <template slot-scope="props">
                <card>
                  <el-table
                    stripe
                    class="table-responsive table product-table mt-2"
                    style="width: 100%"
                    header-row-class-name="thead-light"
                    :data="props.row.Products"
                    :cell-style="{ textAlign: 'center' }"
                    :header-cell-style="{ textAlign: 'center' }"
                    :style="{ marginBottom: 1.5 + 'rem' }"
                  >
                    <el-table-column
                      label="PLU"
                      prop="PLU"
                      class="p-0"
                      min-width="100px"
                    >
                    </el-table-column>
                    <el-table-column
                      label="Product Name"
                      prop="Name1"
                      class="p-0"
                      min-width="100px"
                    >
                    </el-table-column>
                    <el-table-column
                      label="Unit"
                      prop="Unit"
                      class="p-0"
                      min-width="100px"
                    >
                    </el-table-column>
                    <el-table-column
                      label="Price"
                      prop="UnitPrice"
                      class="p-0"
                      min-width="100px"
                    >
                      <template v-slot="{ row }">
                        ${{ row.UnitPrice }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="Qty"
                      prop="OrderQty"
                      class="p-0"
                      min-width="100px"
                    >
                    </el-table-column>
                    <el-table-column
                      label="SubTotal"
                      prop="TotalPrice"
                      class="p-0"
                      min-width="100px"
                    >
                      <template v-slot="{ row }">
                        ${{ row.TotalPrice }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="S/N"
                      prop=""
                      class="p-0"
                      min-width="100px"
                    >
                      <template v-slot="{ row }">
                        {{ row.SerialNumber ? row.SerialNumber.join(',') : '' }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="Remarks"
                      prop="Remarks"
                      class="p-0"
                      min-width="100px"
                    >
                    </el-table-column>
                  </el-table>
                </card>
              </template>
            </el-table-column>
            <el-table-column class="p-0" min-width="100px" align="center">
              <template slot="header">
                <div class="w-100 p-0 text-center">
                  {{ $t("Outbound Date") }}
                </div>
              </template>
              <template v-slot="{ row }">
                {{ row.UpdateDateTime.substr(0, 10) }}
              </template>
            </el-table-column>
            <el-table-column
              label="Outbound No"
              prop="OutboundNo"
              class="p-0"
              min-width="100px"
              align="center"
            >
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Outbound No.") }}</div>
              </template>
            </el-table-column>
            <el-table-column
              label="Status"
              prop="Status"
              class="p-0"
              min-width="100px"
              align="center"
            >
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Status") }}</div>
              </template>
            </el-table-column>
            <el-table-column
              label="Store"
              prop="LocationName"
              class="p-0"
              min-width="100px"
              align="center"
            >
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Store") }}</div>
              </template>
            </el-table-column>
            <el-table-column
              label="Total"
              prop="TotalAmount"
              class="p-0"
              min-width="100px"
              align="center"
            >
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Total") }}</div>
              </template>
              <template v-slot="{ row }">
                ${{ row.TotalAmount }}
              </template>
            </el-table-column>
            <el-table-column
              label="Remarks"
              prop="Remarks"
              class="p-0"
              min-width="100px"
              align="center"
            >
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Remarks") }}</div>
              </template>
            </el-table-column>
          </el-table>
        </el-collapse-item>
        <!-- <el-collapse-item
          class="collapseheader"
          :title="$t('Inbound')"
          name="Inbound"
          v-if="cur_inbound.length > 0"
        >
          <el-table
            stripe
            class="table-responsive table product-table mt-2"
            style="width: 100%"
            header-row-class-name="thead-light"
            :data="cur_inbound"
            :cell-style="{ textAlign: 'center' }"
            :style="{ marginBottom: 1.5 + 'rem' }"
          >
            <el-table-column
              prop="InboundDate"
              class="p-0"
              min-width="100px"
              align="center"
            >
              <template slot="header">
                <div class="w-100 p-0 text-center">
                  {{ $t("Inbound Date") }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="InboundNo" class="p-0" min-width="100px">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("OrderNo") }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="Status" class="p-0" min-width="100px">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Status") }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="LocationName" class="p-0" min-width="100px">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Store") }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="OrderID" class="p-0" min-width="100px">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Inbound") }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="TotalAmount" class="p-0" min-width="100px">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Total") }}</div>
              </template>
            </el-table-column>
          </el-table>
        </el-collapse-item> -->
        <el-collapse-item
          class="collapseheader"
          :title="$t('Payment')"
          name="Payment"
          v-if="!is_shipping() && hasUtilityAuth('SOPayment')"
        >
          <!---------------------- payment of Payment Section ------------------------>
          <div style="margin: 16px">
            <div style="text-align: right">{{ $t("Customer Credit") }} : {{ parseFloat(customer_credit).toFixed(2) }}</div>
            <div>Payment</div>
            <el-table
              stripe
              class="table-responsive table product-table mt-2"
              style="width: 100%"
              header-row-class-name="thead-light"
              :data="cur_so_payment"
            >
              <el-table-column
                label="Payment Type"
                prop="PayType"
                class="p-0"
                align="center"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">
                    {{ $t("Payment Type") }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="Payment Method"
                prop="PaymentMethodID"
                class="p-0"
                align="center"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">
                    {{ $t("Payment Method") }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="Paid Amount"
                prop="PaymentAmount"
                class="p-0"
                align="center"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Paid Amount") }}</div>
                </template>
                <template v-slot="{ row }">
                  ${{ row.PaymentAmount }}
                </template>
              </el-table-column>
              <el-table-column
                label="Employee"
                prop="CreateBy"
                class="p-0"
                align="center"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Employee") }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="Remarks"
                prop="Remarks"
                class="p-0"
                align="center"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Remarks") }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="Date"
                class="p-0"
                align="center"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Date") }}</div>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.CreateDateTime.substr(0, 10) }}
                </template>
              </el-table-column>
              <el-table-column class="p-0" min-width="100px" v-if="!is_so_deleted()">
                <template slot="header">
                  <span class="btn-inner--icon" 
                    @click="init_new_payment()"
                    v-if="hasUtilityAuth('SOPaymentAdd')">
                    <i class="ni ni-fat-add icon-add">
                    </i>
                  </span>
                </template>
                <template slot-scope="scope">
                  <span class="btn-inner--icon" 
                    @click="delete_payment_credit(scope.$index, scope.row, 'payment')"
                    v-if="hasUtilityAuth('SOPaymentDelete')">
                    <i class="ni ni-fat-remove icon-remove">
                    </i>
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!---------------------- credit of Payment Section ------------------------>
          <div style="margin: 16px">
            <div>Credit</div>
            <el-table
              stripe
              class="table-responsive table product-table mt-2"
              style="width: 100%"
              header-row-class-name="thead-light"
              :data="cur_so_credit"
            >
              <el-table-column
                label="Payment Type"
                prop="PayType"
                class="p-0"
                align="center"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">
                    {{ $t("Payment Type") }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="Payment Method"
                prop="PaymentMethodID"
                class="p-0"
                align="center"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">
                    {{ $t("Payment Method") }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="Paid Amount"
                prop="PaymentAmount"
                class="p-0"
                align="center"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Paid Amount") }}</div>
                </template>
                <template v-slot="{ row }">
                  ${{ row.PaymentAmount }}
                </template>
              </el-table-column>
              <el-table-column
                label="Employee"
                prop="CreateBy"
                class="p-0"
                align="center"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Employee") }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="Remarks"
                prop="Remarks"
                class="p-0"
                align="center"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Remarks") }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="Date"
                class="p-0"
                align="center"
                min-width="100px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Date") }}</div>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.CreateDateTime.substr(0, 10) }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-collapse-item>
      </el-collapse>
      <!------------------------------ buttons -------------------------->
      <b-card v-if="currpage == 'edit'">
        <!-- draft -->
        <div v-if="is_so_draft()" class="text-right">
          <el-button type="primary" @click="delete_so" v-if="this.cur_so.SOIDn > 0" :disabled="freeze_button" v-acl:DeleteSO.enable.show>
            {{ $t("Delete SO") }}
          </el-button>
          <el-button type="primary" @click="open_discount_dialogue" :disabled="freeze_button" v-acl:SODiscount.enable.show>
            {{ $t("Discount") }}
          </el-button>
          <el-button type="primary" @click="quick_so" :disabled="freeze_button" v-acl:QuickSO.enable.show>
            {{ $t("Quick SO") }}
          </el-button>
          <el-button type="primary" @click="print_order" :disabled="freeze_button">
            {{ $t("Print Quote") }}
          </el-button>
          <el-button type="primary" @click="submit_click_check_inventory('Pending')" :disabled="freeze_button">
            {{ $t("Post") }}
          </el-button>
          <el-button type="primary" @click="submit_click_check_inventory('Draft')" :disabled="freeze_button">
            {{ $t("Save as Draft") }}
          </el-button>
          <el-button type="primary" @click="cancel_click" :disabled="freeze_button">
            {{ $t("Back") }}
          </el-button>
        </div>
        <!-- pending -->
        <div v-if="is_so_pending()" class="text-right">
          <!-- <el-button type="primary" @click="rollback" v-if="!is_backorder()" :disabled="to_lock_so() || freeze_button">
            {{ $t("RollBack") }}
          </el-button> -->
          <el-button type="primary" @click="delete_so" v-if="is_backorder()" :disabled="to_lock_so() || freeze_button" v-acl:DeleteSO.enable.show>
            {{ $t("Delete SO") }}
          </el-button>
          <el-button type="primary" @click="open_discount_dialogue" :disabled="to_lock_so() || freeze_button" v-acl:SODiscount.enable.show>
            {{ $t("Discount") }}
          </el-button>
          <el-button type="primary" @click="lockUnLockSO('lock')" v-if="is_shipping() && !is_so_locked()" :disabled="freeze_button">
            {{ $t("Lock SO") }}
          </el-button>
          <el-button type="primary" @click="lockUnLockSO('unlock')" v-if="is_shipping() && is_so_locked()" :disabled="freeze_button">
            {{ $t("UnLock SO") }}
          </el-button>
          <el-button type="primary" @click="print_picking" v-if="is_shipping()" :disabled="freeze_button">
            {{ $t("Print Picking") }}
          </el-button>
          <el-button type="primary" @click="print_packing" v-if="is_shipping()" :disabled="freeze_button">
            {{ $t("Print Packing") }}
          </el-button>
          <el-button type="primary" @click="pack" v-if="is_shipping()" :disabled="freeze_button">
            {{ $t("Pack") }}
          </el-button>
          <el-button type="primary" @click="pack_ship" v-if="is_shipping()" :disabled="freeze_button" v-acl:PackShip.enable.show>
            {{ $t("Pack&Ship") }}
          </el-button>
          <el-button type="primary" @click="submit_click_check_inventory('Pending')" :disabled="freeze_button">
            {{ $t("Save Change") }}
          </el-button>
          <el-button type="primary" @click="cancel_click" :disabled="freeze_button">
            {{ $t("Back") }}
          </el-button>
        </div>
        <!-- packed for shipping -->
        <div v-if="is_shipping() && is_so_packed()" class="text-right">
          <el-button type="primary" @click="print_picking" :disabled="freeze_button">
            {{ $t("Print Picking") }}
          </el-button>
          <el-button type="primary" @click="print_packing" :disabled="freeze_button">
            {{ $t("Print Packing") }}
          </el-button>
          <el-button type="primary" @click="print_invoice" :disabled="freeze_button" v-acl:PrintInvoice.enable.show>
            {{ $t("Print Invoice") }}
          </el-button>
          <el-button type="primary" @click="submit_click('Ship')" :disabled="freeze_button">
            {{ $t("Ship") }}
          </el-button>
          <el-button type="primary" class="ml-3" @click="cancel_click" :disabled="freeze_button">
            {{ $t("Back") }}
          </el-button>
        </div>
        <!-- complete -->
        <div v-if="!is_shipping() && is_so_complete()" class="text-right">
          <el-button type="primary" @click="print_invoice" :disabled="freeze_button" v-acl:PrintInvoice.enable.show>
            {{ $t("Print Invoice") }}
          </el-button>
          <el-button type="primary" @click="submit_click('Complete')" :disabled="freeze_button">
            {{ $t("Save Change") }}
          </el-button>
          <el-button type="primary" @click="duplicate" :disabled="freeze_button" v-acl:DuplicateSO.enable.show>
            {{ $t("Duplicate") }}
          </el-button>
          <el-button type="primary" class="ml-3" @click="cancel_click" :disabled="freeze_button">
            {{ $t("Back") }}
          </el-button>
        </div>
        <!-- deleted -->
        <div v-if="is_so_deleted()" class="text-right">
          <el-button type="primary" class="ml-3" @click="cancel_click" :disabled="freeze_button">
            {{ $t("Back") }}
          </el-button>
        </div>
      </b-card>
    </div>

    <b-modal
      ref="product-list-modal"
      :title="$t('Add Product')"
      id="search_modal"
      size="xl"
    >
      <form>
        <!----------------- search filters -------------------------->
        <el-row :gutter="5">
          <!-- <el-col :span="6">
            <el-cascader
              :options="cur_product_tree"
              :props="{ 
                checkStrictly: true,
                multiple: true,
                expandTrigger: 'hover',
                value: 'IDn',
                label: 'Name1',
                children: 'Categorys',
              }"
              clearable>
            </el-cascader>
          </el-col> -->
          <el-col :span="8">
            <b-input-group :prepend="$t('Department') + ':'">
              <el-select
                class="el-dropdown5"
                v-model="cur_search_departmentIDn"
              >
                <el-option
                  :value="0"
                  label="All"
                  @click.native="changed_department">
                </el-option>
                <el-option v-for="(dt, idx) in cur_product_tree"
                  :key="idx"
                  :value="dt.IDn"
                  :label="dt.Name1 + (dt.Name2 && so_show_name2 ? ' - ' + dt.Name2 : '')"
                  @click.native="changed_department">
                </el-option>
              </el-select>
            </b-input-group>
          </el-col>
          <el-col :span="8">
            <b-input-group :prepend="$t('Category') + ':'">
              <el-select
                class="el-dropdown5"
                v-model="cur_search_categoryIDn"
              >
                <el-option
                  :value="0"
                  label="All"
                  @click.native="changed_category">
                </el-option>
                <el-option v-for="(dt, idx) in cur_searched_categorys"
                  :key="idx"
                  :value="dt.IDn"
                  :label="dt.Name1 + (dt.Name2 && so_show_name2 ? ' - ' + dt.Name2 : '')"
                  @click.native="changed_category">
                </el-option>
              </el-select>
            </b-input-group>
          </el-col>
        </el-row>
        <!----------------------------- all product list ----------------------->
        <section class="search_product_section">
          <el-table
            stripe
            class="table-responsive table product-table mt-2"
            style="width: 100%"
            height="270"
            header-row-class-name="thead-light"
            :data="cur_listed_products"
            id="all_product_list"
          >
            <el-table-column
              min-width="10%"
              prop="PLU"
            >
              <template v-slot:header>
                {{$t("PLU")}}
                <el-input
                  v-model="pro_search_plu"
                  size="mini"
                  @change="delay_search_product"/>
              </template>
            </el-table-column>
            <el-table-column
              min-width="18%"
              prop="DisplayName"
            >
              <template v-slot:header>
                {{$t("Name")}}
                <el-input
                  v-model="pro_search_name"
                  size="mini"
                  @change="delay_search_product"/>
              </template>
            </el-table-column>
            <!-- <el-table-column
              v-if="false"
              min-width="10%"
              :label='$t("Barcode")'
              prop="BarCode"
            ></el-table-column> -->
            <el-table-column
              min-width="6%"
              prop="Unit"
            >
              <template v-slot:header>
                {{$t("Unit")}}
                <el-input
                  v-model="pro_search_unit"
                  size="mini"
                  @change="delay_search_product"/>
              </template>
            </el-table-column>
            <el-table-column
              min-width="10%"
              prop="UnitPrice"
            >
              <template v-slot:header>
                {{is_last_order ? $t("Last Price") : $t("Price")}}
                <el-input
                  v-model="pro_search_price"
                  size="mini"
                  @change="delay_search_product"/>
              </template>
              <template v-slot="{ row }">
                ${{ row.UnitPrice }}
              </template>
            </el-table-column>
            <el-table-column
              min-width="10%"
              prop="TopPrice"
              v-if="is_last_order"
            >
              <template v-slot:header>
                {{ $t("Top Price") }}
                <el-input
                  v-model="pro_search_topprice"
                  size="mini"
                  @change="delay_search_product"
                  disabled/>
              </template>
              <template v-slot="{ row }">
                ${{ row.TopPrice }}
              </template>
            </el-table-column>
            <el-table-column
              min-width="10%"
              prop="StoreQty"
            >
              <template v-slot:header>
                {{$t("Inventory")}}
                <el-input
                  v-model="pro_search_inventory"
                  size="mini"
                  @change="delay_search_product"/>
              </template>
            </el-table-column>
            <el-table-column
              min-width="9%"
              prop="OnHold"
            >
              <template v-slot:header>
                {{$t("On Hold")}}
                <el-input
                  v-model="pro_search_onhold"
                  size="mini"
                  @change="delay_search_product"/>
              </template>
            </el-table-column>
            <el-table-column
              min-width="10%"
              prop="OnOrder"
            >
              <template v-slot:header>
                {{$t("On Order")}}
                <el-input
                  v-model="pro_search_onorder"
                  size="mini"
                  @change="delay_search_product"/>
              </template>
            </el-table-column>
            <el-table-column
              min-width="11%"
              prop="OrderDate"
              v-if="is_last_order"
            >
              <template v-slot:header>
                {{$t("Last Order")}}
                <el-input
                  size="mini"
                  disabled/>
              </template>
              <template v-slot="{ row }">
                {{ row.OrderDate.substr(0, 10) }}
              </template>
            </el-table-column>
            <el-table-column min-width="5%" label="">
              <template slot="header">
                <span class="btn-inner--icon">
                  <i class="ni ni-fat-remove icon-clear" @click="clear_product_condition">
                  </i>
                </span>
              </template>
              <template slot-scope="scope">
                <span class="btn-inner--icon" 
                  @click="add_product_to_temp(scope.row, scope.$index)">
                  <i class="ni ni-fat-add icon-add">
                  </i>
                </span>
              </template>
            </el-table-column>
          </el-table>
        </section>
        <hr />
        <!----------------------------- temporarily selected product list ----------------------->
        <el-row :gutter="5">
          <el-col :span="3">
            <div class="product_head">{{ $t("PLU") }}</div>
          </el-col>
          <el-col :span="6">
            <div class="product_head">{{ $t("Name") }}</div>
          </el-col>
          <!-- <el-col v-if="false" :span="4">
            <div class="product_head">{{ $t("Barcode") }}</div>
          </el-col> -->
          <el-col :span="2">
            <div class="product_head">{{ $t("Unit") }}</div>
          </el-col>
          <el-col :span="2">
            <div class="product_head">{{ $t("Price") }}</div>
          </el-col>
          <el-col :span="2">
            <div class="product_head">{{ $t("Inventory") }}</div>
          </el-col>
          <el-col :span="2">
            <div class="product_head">{{ $t("On Hold") }}</div>
          </el-col>
          <el-col :span="2">
            <div class="product_head">{{ $t("On Order") }}</div>
          </el-col>
          <el-col :span="2">
            <div class="product_head">{{ $t("Quantity") }}</div>
          </el-col>
          <el-col :span="2">
            <div class="product_head">&nbsp;</div>
          </el-col>
        </el-row>
        <div style="overflow-y: auto; overflow-x: hidden; height: 242px">
          <el-row
            :gutter="5"
            v-for="(prod, idx) in selected_product_list"
            :key="idx"
            class="product_row"
          >
            <el-col :span="3">
              <div>{{ prod.PLU }}</div>
            </el-col>
            <el-col :span="6">
              <div>{{ prod.DisplayName }}</div>
            </el-col>            
            <!-- <el-col :span="4">
              <div>{{ prod.Barcode ? prod.Barcode : "-" }}</div>
            </el-col> -->
            <el-col :span="2">
              <div>{{ prod.Unit }}</div>
            </el-col>
            <el-col :span="2">
              <div>${{ prod.UnitPrice }}</div>
            </el-col>
            <el-col :span="2">
              <div>{{ prod.StoreQty }}</div>
            </el-col>
            <el-col :span="2">
              <div>{{ prod.OnHold ? prod.OnHold : "-"}}</div>
            </el-col>
            <el-col :span="2">
              <div>{{ prod.OnOrder ? prod.OnOrder : "-" }}</div>
            </el-col>
            <el-col :span="2">
              <div>
                <el-input
                  v-model="prod.OrderQty"
                  size="mini"
                  @change="calculate_recodes(prod)"
                ></el-input>
              </div>
            </el-col>
            <el-col :span="2">
              <div
                class="btn-inner--icon text-center w-100"
                @click="remove_product(prod)"
              >
                <i class="ni ni-fat-remove icon-remove"></i>
              </div>
            </el-col>
          </el-row>
        </div>
      </form>
      <template #modal-footer>
        <button
          type="button"
          class="btn btn-primary"
          @click="submit_product_selection"
        >
          Add
        </button>
        <button type="button" class="btn btn-secondary" @click="close_my_modal">
          Close
        </button>
      </template>
    </b-modal>

    <b-modal ref="import-list-modal" :title="$t('Import')" id="import_modal">
      <b-alert v-if="import_file_warning" show dismissible variant="warning">
        <span class="alert-text"
          ><strong>Warning!</strong> {{ import_file_warning }}</span
        >
      </b-alert>
      <section>
        <input ref="fileuploader" type="file" @change="on_import_change" @click="reset_import_file"/>
        <xlsx-read :file="import_file" @parsed="import_file_parsed">
        </xlsx-read>
      </section>
      <xlsx-workbook>
        <xlsx-sheet
          :collection="downloadsheet.data"
          :sheet-name="downloadsheet.name"
        />
        <xlsx-download filename="so_import_template.xlsx" disable-wrapper-click>
          <template #default="{ download }">
            <el-button
              type="primary"
              class="mt-4"
              icon="el-icon-download"
              size="small"
              @click="download"
              >Download Import Template</el-button
            >
          </template>
        </xlsx-download>
      </xlsx-workbook>
      <template #modal-footer>
        <button
          type="button"
          class="btn btn-secondary"
          @click="close_import_modal"
        >
          Close
        </button>
      </template>
    </b-modal>
    <!-- add payment dialogue -->
    <b-modal
      ref="add-payment-modal"
      :title="$t('Add Payment')"
      id="add-payment-modal"
      size="lg"
    >
      <div style="margin-right: 30px">
        <el-row style="margin: 2px 0" :gutter="40">
          <el-col :span="8" class="text-right" style="margin-top: 5px">
            {{ $t('Payment Type') + ' : ' }}
          </el-col>
          <el-col :span="16">
            <el-select
              v-model="cur_payment_edit.PayType"
              size="medium"
            >
              <el-option
                v-for="type in payment_type_list"
                :key="type"
                :value="type"
                :label="type"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row style="margin: 2px 0" :gutter="40">
          <el-col :span="8" class="text-right" style="margin-top: 5px">
            {{ $t('Payment Method') + ' : ' }}
          </el-col>
          <el-col :span="16">
            <el-select
              v-model="cur_payment_edit.PaymentMethodID"
              size="medium"
            >
              <el-option
                v-for="(method, key) in payment_method_list"
                :key="key"
                :value="key"
                :label="method.Name1"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row style="margin: 2px 0" :gutter="40">
          <el-col :span="8" class="text-right" style="margin-top: 5px">
            {{ $t('Amount') + ' : ' }}
          </el-col>
          <el-col :span="16">
            <el-input
              v-model="cur_payment_edit.PaymentAmount"
            >
            </el-input>
          </el-col>
        </el-row>
        <el-row style="margin: 2px 0" :gutter="40">
          <el-col :span="8" class="text-right" style="margin-top: 5px">
            {{ $t('Remarks') + ' : ' }}
          </el-col>
          <el-col :span="16">
            <el-input
              v-model="cur_payment_edit.Remarks"
            >
            </el-input>
          </el-col>
        </el-row>
      </div>
      <template #modal-footer>
        <button type="button" class="btn btn-primary" @click="add_payment">
          {{ $t('Add') }}
        </button>
        <button type="button" class="btn btn-secondary" @click="close_add_payment_model">
          {{ $t('Close') }}
        </button>
      </template>
    </b-modal>
    <!-- add credit dialogue -->
    <b-modal
      ref="add-credit-modal"
      :title="$t('Add Credit')"
      id="add-credit-modal"
      size="lg"
    >
      <div style="margin-right: 30px">
        <el-row style="margin: 2px 0" :gutter="40">
          <el-col :span="8" class="text-right" style="margin-top: 5px">
            {{ $t('Payment Type') + ' : ' }}
          </el-col>
          <el-col :span="16">
            <el-select
              v-model="cur_credit_edit.PayType"
              size="medium"
            >
              <el-option
                v-for="type in credit_type_list"
                :key="type"
                :value="type"
                :label="type"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row style="margin: 2px 0" :gutter="40">
          <el-col :span="8" class="text-right" style="margin-top: 5px">
            {{ $t('Payment Method') + ' : ' }}
          </el-col>
          <el-col :span="16">
            <el-select
              v-model="cur_credit_edit.PaymentMethodID"
              size="medium"
            >
              <el-option
                v-for="(method, key) in payment_method_list"
                :key="key"
                :value="key"
                :label="method.Name1"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row style="margin: 2px 0" :gutter="40">
          <el-col :span="8" class="text-right" style="margin-top: 5px">
            {{ $t('Amount') + ' : ' }}
          </el-col>
          <el-col :span="16">
            <el-input
              v-model="cur_credit_edit.PaymentAmount"
            >
            </el-input>
          </el-col>
        </el-row>
        <el-row style="margin: 2px 0" :gutter="40">
          <el-col :span="8" class="text-right" style="margin-top: 5px">
            {{ $t('Remarks') + ' : ' }}
          </el-col>
          <el-col :span="16">
            <el-input
              v-model="cur_credit_edit.Remarks"
            >
            </el-input>
          </el-col>
        </el-row>
      </div>
      <template #modal-footer>
        <button type="button" class="btn btn-primary" @click="add_credit">
          {{ $t('Add') }}
        </button>
        <button type="button" class="btn btn-secondary" @click="close_add_credit_model">
          {{ $t('Close') }}
        </button>
      </template>
    </b-modal>
    
    <!-- return dialogue -->
    <b-modal
      ref="return-modal"
      :title="$t('Return')"
      id="return-modal"
      size="xl"
    >
      <div style="margin-right: 30px">
        <el-row style="margin: 2px 0" :gutter="40">
          <el-col :span="3" style="margin-top: 8px;padding-left: 5px;padding-right: 0">
            {{ $t('Returned Date') + ' : ' }}
          </el-col>
          <el-col :span="4">
            <flat-pickr
              v-model="cur_return_edit.ReturnDate"
              :config="pickr_config"
              class="form-control"
              :disabled="return_view_mode"
            >
            </flat-pickr>
          </el-col>
        </el-row>
        <!-- return product list -->
        <el-table
          stripe
          class="table-responsive table product-table mt-2"
          style="width: 100%"
          id="return_product_list"
          header-row-class-name="thead-light"
          :cell-style="{ textAlign: 'center' }"
          :data="cur_return_product"
          :style="{ marginBottom: 1.5 + 'rem' }"
          :default-expand-all=true
        >
          <el-table-column type="expand" width="1px">
            <template slot="header">
              <div class="hide_element"></div>
            </template>
            <template slot-scope="props" >
              <b-textarea
                :placeholder="$t('Remarks')"
                type="textarea"
                rows="1"
                max-rows="1"
                v-model="props.row.Remarks"
                no-resize
                :disabled="return_view_mode"
              ></b-textarea>
              <b-textarea
                v-if="(props.row.SerialNumbers ? JSON.parse(props.row.SerialNumbers) : []).join(',')"
                type="textarea"
                rows="1"
                max-rows="1"
                :value="(props.row.SerialNumbers ? JSON.parse(props.row.SerialNumbers) : []).join(',')"
                no-resize
                disabled
              ></b-textarea>
            </template>
          </el-table-column>
          <el-table-column prop="PLU" class="p-0" min-width="100px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("PLU") }}</div>
            </template>
          </el-table-column>
          <el-table-column class="p-0" min-width="150px">
            <template slot="header">
              <div class="w-100 p-0 text-center">
                {{ $t("Product Name") }}
              </div>
            </template>
            <template v-slot="{ row }">
              <div>
                {{
                  row.ProductName2 && so_show_name2
                    ? row.ProductName1 + "(" + row.ProductName2 + ")"
                    : row.ProductName1
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="Unit" class="p-0" min-width="50px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Unit") }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="UnitPrice" class="p-0" min-width="100px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Sale Price") }}</div>
            </template>
            <template v-slot="{ row }">
              ${{ row.UnitPrice }}
            </template>
          </el-table-column>
          <el-table-column prop="Qty" class="p-0" min-width="100px" v-if="!return_view_mode">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Qty") }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="ReturnQty" class="p-0" min-width="120px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Return Qty") }}</div>
            </template>
            <template v-slot="{ row }">
              <div v-if="return_view_mode">
                {{row.ReturnQty}}
              </div>
              <div v-else>
                <el-input
                  size="mini"
                  v-model="row.ReturnQty"
                  @change="change_return_qty(row)"
                ></el-input>
              </div>
            </template>
          </el-table-column>
          <!-- S/N -->
          <el-table-column class="p-0" width="60px" v-if="!return_view_mode">
            <template slot="header">
              <div class="w-100 p-0 text-center">S/N</div>
            </template>
            <template v-slot="{ row }">
              <div
                v-if="row.HasSerialNumber == 1"
                class="btn-inner--icon text-center w-100"
                @click="selectSN(row)"
              >
                <i class="el-icon-connection hover_hand red_color"></i>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="TotalPrice" class="p-0" min-width="100px">
            <template slot="header">
              <div class="w-100 p-0 text-center">{{ $t("Subtotal") }}</div>
            </template>
            <template v-slot="{ row }">
              ${{ row.TotalPrice }}
            </template>
          </el-table-column>
        </el-table>
        <!-- return add product -->
        <!-- <el-collapse v-model="exchange">
          <el-collapse-item
            class="collapseheader"
            :title="$t('Exchange')"
            name="exchange"
          >
            <el-row :gutter="6">
              <el-col :span="16" >
                <el-button type="primary" @click="add_product">{{ $t("Add Product") }}</el-button>
              </el-col>
            </el-row>
            <el-table
              stripe
              class="table-responsive table product-table mt-2"
              style="width: 100%"
              id="return_product_list"
              header-row-class-name="thead-light"
              :cell-style="{ textAlign: 'center' }"
              :data="cur_return_product"
              :style="{ marginBottom: 1.5 + 'rem' }"
              :default-expand-all=true
            >
              <el-table-column type="expand" width="1px">
                <template slot="header">
                  <div class="hide_element"></div>
                </template>
                <template slot-scope="props" >
                  <b-textarea
                    :placeholder="$t('Remarks')"
                    type="textarea"
                    rows="1"
                    max-rows="1"
                    v-model="props.row.Remarks"
                    no-resize
                    :disabled="to_lock_so()"
                  ></b-textarea>
                  <b-textarea
                    v-if="props.row.HasSerialNumber"
                    type="textarea"
                    rows="1"
                    max-rows="1"
                    :value="(props.row.SerialNumbers ? JSON.parse(props.row.SerialNumbers) : []).join(',')"
                    no-resize
                    disabled
                  ></b-textarea>
                </template>
              </el-table-column>
              <el-table-column prop="PLU" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("PLU") }}</div>
                </template>
              </el-table-column>
              <el-table-column class="p-0" min-width="150px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">
                    {{ $t("Product Name") }}
                  </div>
                </template>
                <template v-slot="{ row }">
                  <div>
                    {{
                      row.ProductName2 && so_show_name2
                        ? row.ProductName1 + "(" + row.ProductName2 + ")"
                        : row.ProductName1
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="Unit" class="p-0" min-width="50px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Unit") }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="UnitPrice" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Sale Price") }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="Qty" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Qty") }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="ReturnQty" class="p-0" min-width="120px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Return Qty") }}</div>
                </template>
                <template v-slot="{ row }">
                  <div>
                    <el-input
                      size="mini"
                      v-model="row.ReturnQty"
                      @change="change_return_qty(row)"
                    ></el-input>
                  </div>
                </template>
              </el-table-column>
              <el-table-column class="p-0" width="60px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">S/N</div>
                </template>
                <template v-slot="{ row }">
                  <div
                    v-if="row.HasSerialNumber == 1"
                    class="btn-inner--icon text-center w-100"
                    @click="selectSN(row)"
                  >
                    <i class="el-icon-connection hover_hand red_color"></i>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="TotalPrice" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Subtotal") }}</div>
                </template>
              </el-table-column>
            </el-table>
          </el-collapse-item>
        </el-collapse> -->
        <!-- remarks and summary -->
        <el-row :gutter="6">
          <el-col :span="12">
            <el-input
              :placeholder="$t('Remarks')"
              type="textarea"
              :autosize="{ minRows: 6, maxRows: 9 }"
              v-model="cur_return_edit.Remarks"
              :disabled="return_view_mode"
            >
            </el-input>
          </el-col>
          <el-col :span="6" :offset="3">
            <el-row>
              <el-col :span="12" class="text-right pr-3">
                {{ $t("Subtotal") + " : " }}
              </el-col>
              <el-col :span="12">
                {{ parseFloat(cur_return_edit.SubTotal).toFixed(2) }}
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12" class="text-right pr-3">
                {{ $t("Discount") + " : " }}
              </el-col>
              <el-col :span="12">
                {{ parseFloat(cur_return_edit.soDiscount).toFixed(2) }}
              </el-col>
            </el-row>
            <el-row v-for="(tax, idx) in cur_return_tax" :key="idx">
              <el-col :span="12" class="text-right pr-3">
                {{ tax.TaxType + " : " }}
              </el-col>
              <el-col :span="12">
                {{ parseFloat(tax.TaxAmount).toFixed(2) }}
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12" class="text-right pr-3">
                {{ $t("Other Fee") + " : " }}
              </el-col>
              <el-col :span="12">
                <div v-if="!return_view_mode">
                  <el-input
                    size="mini"
                    v-model="cur_return_edit.OtherFees"
                    @change="return_fee_change"
                  />
                </div>
                <div v-else>
                  {{ parseFloat(cur_return_edit.OtherFees).toFixed(2) }}
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12" class="text-right pr-3">
                {{ $t("Shipping Fee") + " : " }}
              </el-col>
              <el-col :span="12">
                <div v-if="!return_view_mode">
                  <el-input
                    size="mini"
                    v-model="cur_return_edit.ShipFee"
                    @change="return_fee_change"
                  />
                </div>
                <div v-else>
                  {{ parseFloat(cur_return_edit.ShipFee).toFixed(2) }}
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12" class="text-right pr-3">
                {{ $t("Total") + " : " }}
              </el-col>
              <el-col :span="12">
                {{ parseFloat(cur_return_edit.TotalAmount).toFixed(2) }}
              </el-col>
            </el-row>
            <el-row v-if="return_view_mode">
              <el-col :span="12" class="text-right pr-3">
                {{ $t("Credit") + " : " }}
              </el-col>
              <el-col :span="12">
                {{ parseFloat(cur_return_edit.Credit).toFixed(2) }}
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
      <template #modal-footer>
        <button type="button" class="btn btn-primary" @click="add_return" v-if="!return_view_mode">
          {{ $t('Return') }}
        </button>
        <button type="button" class="btn btn-primary" @click="print_return" v-if="return_view_mode">
          {{ $t('Print') }}
        </button>
        <button type="button" class="btn btn-secondary" @click="close_return_model">
          {{ $t('Close') }}
        </button>
      </template>
    </b-modal>
    
    <!-- discount dialogue -->
    <b-modal
      ref="discount-modal"
      :title="$t('Discount')"
      id="discount-modal"
      size="sm"
    >
      <div>
        <el-row :gutter="10">
          <el-col style="margin-left: 5px; margin-bottom: 5px">
            {{ $t('Please input the discount') }} :
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="20">
            <el-input v-model="cur_so.SoDiscountRate">
            </el-input>
          </el-col>
          <el-col :span="4" style="padding-top: 5px">
            %
          </el-col>
        </el-row>
      </div>
      <template #modal-footer>
        <button type="button" class="btn btn-primary" @click="apply_discount">
          {{ $t('Apply') }}
        </button>
        <button type="button" class="btn btn-secondary" @click="close_discount_model">
          {{ $t('Close') }}
        </button>
      </template>
    </b-modal>
    
    <!-- SN select dialogue -->
    <b-modal
      ref="select-sn-modal"
      :title="$t('Input S/N')"
      id="select-sn-modal"
      size="sm"
    >
      <div>
        <el-row>
          <el-col :span="18">
            <b-input class="form-control" v-model="input_sn" @keyup.enter.native="searchSN"/>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" icon="el-icon-plus" @click="searchSN" style="margin-left: 20px"></el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-table
            id="selectSNTable"
            ref="selectSNTable"
            stripe
            class="table-responsive table product-table mt-2"
            style="width: 100%"
            height=300
            header-row-class-name="thead-light"
            :data="tobe_select_sn"
            @selection-change="selectSNChange"
          >
            <el-table-column type="selection" width="60">
            </el-table-column>
            <el-table-column label="Serial Number">
              <template slot-scope="scope">
                {{ scope.row }}
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row>
          {{tmp_selected_sn.length}} / {{parseInt(parseFloat(return_mode ? cur_sn_so_pro.ReturnQty : cur_sn_so_pro.ShippingQty))}}
        </el-row>
      </div>
      <template #modal-footer>
        <button type="button" class="btn btn-primary" @click="submit_sn">
          {{ $t('Submit') }}
        </button>
        <button type="button" class="btn btn-secondary" @click="close_select_sn_model">
          {{ $t('Close') }}
        </button>
      </template>
    </b-modal>

    <!-- select how to do with backorder -->
    <b-modal
      ref="select-backorder-modal"
      :title="$t('Query')"
      id="select-backorder-modal"
      size="sm"
    >
      <div>
        <el-row>
          {{ $t('Unshipped products remain in this order, please choose how to deal:') }}
        </el-row>
        <el-row>
          <el-radio-group v-model="how_to_deal_backorder" size="small">
            <el-radio :label=1 class="query-backorder-radio">{{ $t('Create a Back Order') }}</el-radio>
            <el-radio :label=2 class="query-backorder-radio" v-if="hasUtilityAuth('ForceCompleteSO')">{{ $t('Complete and close this order') }}</el-radio>
            <el-radio :label=3 class="query-backorder-radio" v-if="hasUtilityAuth('ToNewSO')">{{ $t('Create a new SO') }}</el-radio>
          </el-radio-group>
        </el-row>
      </div>
      <template #modal-footer>
        <button type="button" class="btn btn-primary" @click="submit_select_backorder">
          {{ $t('Submit') }}
        </button>
        <button type="button" class="btn btn-secondary" @click="close_select_backorder_model">
          {{ $t('Close') }}
        </button>
      </template>
    </b-modal>

    <!-- select how to do deleting -->
    <b-modal
      ref="delete-query-modal"
      :title="$t('Query')"
      id="delete-query-modal"
      size="sm"
    >
      <div>
        <el-row>
          {{ $t('This is a Back Order, how would you like to do the deleting?') }}
        </el-row>
        <el-row>
          <el-radio-group v-model="how_to_deal_delete" size="small">
            <el-radio :label=1 class="query-backorder-radio">{{ $t('Just delete this Back Order') }}</el-radio>
            <el-radio :label=2 class="query-backorder-radio">{{ $t('Detach it from the original order') }}</el-radio>
          </el-radio-group>
        </el-row>
      </div>
      <template #modal-footer>
        <button type="button" class="btn btn-primary" @click="submit_delete_query">
          {{ $t('Submit') }}
        </button>
        <button type="button" class="btn btn-secondary" @click="close_delete_query_model">
          {{ $t('Close') }}
        </button>
      </template>
    </b-modal>
    
    <!-------------------------------------- 添加Customer modal -------------------------------------->
    <b-modal ref="add-customer-modal" :title="$t('Add Customer')" id="search_modal" size="xl">
      <form>
        <div style="padding: 0 20px" v-loading="loading">
          <el-row>
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ "*" + $t("Name") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <b-input
                class="form-control"
                :placeholder="$t('Name')"
                v-model="new_customer.Name"
              />
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ $t("Other Name") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <b-input
                class="form-control"
                :placeholder="$t('Other Name')"
                v-model="new_customer.OtherName"
              />
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ "*" + $t("Tel") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <b-input
                class="form-control"
                :placeholder="$t('Tel')"
                v-model="new_customer.Tel"
              />
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ "*" + $t("Type") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <InputAutocomplete2
                :items="company_typelist"
                :placeholder="$t('Type')"
                :key_field="'IDn'"
                :value_field="'CompanyType'"
                v-model="new_customer.CompanyType"
              />
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ "*" + $t("Address1") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <b-input
                class="form-control"
                :placeholder="$t('Address1')"
                v-model="new_customer.Address1"
              />
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ $t("Address2") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <b-input
                class="form-control"
                :placeholder="$t('Address2')"
                v-model="new_customer.Address2"
              />
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ "*" + $t("City") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <InputAutocomplete
                :items="city_list"
                :placeholder="$t('City')"
                v-model="new_customer.City"
              >
              </InputAutocomplete>
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ "*" + $t("Country") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <el-select
                v-model="new_customer.CountryIDn"
              >
                <el-option
                  v-for="(country, IDn) in country_list"
                  :key="IDn"
                  :label="country.Country"
                  :value="parseInt(IDn)"
                  @click.native="changed_country(parseInt(IDn), 4)"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ "*" + $t("Province") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <el-select
                v-model="new_customer.ProvinceIDn"
              >
                <el-option
                  v-for="(province, idx) in new_customer_provincelist"
                  :key="idx"
                  :label="province.Province"
                  :value="parseInt(province.IDn)"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ "*" + $t("Postal Code") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <b-input
                class="form-control"
                :placeholder="$t('Postal Code')"
                v-model="new_customer.ZIP"
              />
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ $t("Payment Term") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <InputAutocomplete2
                :items="pay_periodlist"
                :placeholder="$t('Payment Term')"
                :key_field="'IDn'"
                :value_field="'PayPeriod'"
                v-model="new_customer.PaymentTerm"
              />
            </el-col>
          </el-row>
          <hr>
          <b style="padding-left: 50px">{{ $t("Contact") }}</b>
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ $t("First Name") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <b-input
                class="form-control"
                :placeholder="$t('First Name')"
                v-model="new_customer.Contact.FirstName"
              />
            </el-col>
          </el-row>

          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ $t("Last Name") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <b-input
                class="form-control"
                :placeholder="$t('Last Name')"
                v-model="new_customer.Contact.LastName"
              />
            </el-col>
          </el-row>
          
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ $t("Cel") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <b-input
                class="form-control"
                :placeholder="$t('Tel')"
                v-model="new_customer.Contact.Tel"
              />
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ $t("Email") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <b-input
                class="form-control"
                :placeholder="$t('Email')"
                v-model="new_customer.Contact.Email"
              />
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="8" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ $t("Position") + " : " }}
            </el-col>
            <el-col :span="16" class="pr-6">
              <InputAutocomplete2
                :items="positionlist"
                :placeholder="$t('Position')"
                :key_field="'IDn'"
                :value_field="'PositionName'"
                v-model="new_customer.Contact.Position"
              >
              </InputAutocomplete2>
            </el-col>
          </el-row>
        </div>
      </form>
      <template #modal-footer>
        <button
          type="button"
          class="btn btn-primary"
          @click="submit_new_customer_address"
        >
          {{ $t('Add') }}
        </button>
        <button type="button" class="btn btn-secondary" @click="close_add_customer_modal">
          {{ $t('Close') }}
        </button>
      </template>
    </b-modal>

    <!-------------------------------------- 添加Address modal -------------------------------------->
    <b-modal ref="address-modal" :title="$t('Add Address')" id="search_modal" size="l">
      <form>
        <div style="padding: 0px" v-loading="loading">
          <el-row class="pt-1">
            <el-col :span="7" class="text-right pr-3 pt-1"  style="margin-top: 2px">
            </el-col>
            <el-col :span="17" class="pr-3">
              <b-input
                type="text"
                v-model="search"
                @input="onChange"
                :placeholder="$t('Please input your address')"
                autocomplete="off"
              />
              <ul v-show="isOpen" class="autocomplete2-results">
                <li
                  v-for="(result, i) in search_results"
                  :key="i"
                  @click="setResult(result)"
                  class="autocomplete2-result"
                >
                  {{ result }}
                </li>
              </ul>
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="7" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ "*" + $t("Address1") + " : " }}
            </el-col>
            <el-col :span="17" class="pr-3">
              <b-input
                class="form-control"
                :placeholder="$t('Address1')"
                v-model="cur_address.Street1"
              />
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="7" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ $t("Address2") + " : " }}
            </el-col>
            <el-col :span="17" class="pr-3">
              <b-input
                class="form-control"
                :placeholder="$t('Address2')"
                v-model="cur_address.Street2"
              />
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="7" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ "*" + $t("City") + " : " }}
            </el-col>
            <el-col :span="17" class="pr-3">
              <InputAutocomplete
                :items="city_list"
                :placeholder="$t('City')"
                :key="cur_address.AddressCity"
                v-model="cur_address.AddressCity"
                :initval="cur_address.AddressCity"
              >
              </InputAutocomplete>
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="7" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ "*" + $t("Country") + " : " }}
            </el-col>
            <el-col :span="17" class="pr-3">
              <el-select
                v-model="cur_address.CountryIDn"
              >
                <el-option
                  v-for="(country, IDn) in country_list"
                  :key="IDn"
                  :label="country.Country"
                  :value="parseInt(IDn)"
                  @click.native="changed_country(parseInt(IDn), 0)"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="7" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ "*" + $t("Province") + " : " }}
            </el-col>
            <el-col :span="17" class="pr-3">
              <el-select
                v-model="cur_address.ProvinceIDn"
              >
                <el-option
                  v-for="(province, idx) in cur_provincelist"
                  :key="idx"
                  :label="province.Province"
                  :value="parseInt(province.IDn)"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row class="pt-1">
            <el-col :span="7" class="text-right pr-3 pt-1"  style="margin-top: 2px">
              {{ "*" + $t("Postal Code") + " : " }}
            </el-col>
            <el-col :span="17" class="pr-3">
              <b-input
                class="form-control"
                :placeholder="$t('Postal Code')"
                v-model="cur_address.ZIP"
              />
            </el-col>
          </el-row>
        </div>
      </form>
      <template #modal-footer>
        <button
          type="button"
          class="btn btn-primary"
          @click="submit_address"
        >
          {{ $t("Add") }}
        </button>
        <button type="button" class="btn btn-secondary" @click="cancel_edit_address">
          {{ $t("Close") }}
        </button>
      </template>
    </b-modal>

    <!-------------------------------------- original product modal -------------------------------------->
    <b-modal ref="view-original" :title="$t('View Original Product')" id="search_modal" size="xl">
      <!------------------------- original so product list -------------------------->
      <el-table
        stripe
        class="table-responsive table product-table mt-2"
        style="width: 100%"
        id="ori_product_list"
        header-row-class-name="thead-light"
        :cell-style="{ textAlign: 'center' }"
        :data="ori_data.Products"
        :style="{ marginBottom: 1.5 + 'rem' }"
        :default-expand-all=true
      >
        <el-table-column prop="PLU" class="p-0" min-width="100px">
          <template slot="header">
            <div class="w-100 p-0 text-center">{{ $t("PLU") }}</div>
          </template>
        </el-table-column>
        <el-table-column class="p-0" min-width="150px">
          <template slot="header">
            <div class="w-100 p-0 text-center">
              {{ $t("Product Name") }}
            </div>
          </template>
          <template v-slot="{ row }">
            <div>
              {{
                row.ProductName2 && so_show_name2
                  ? row.ProductName1 + "(" + row.ProductName2 + ")"
                  : row.ProductName1
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="Unit" class="p-0" min-width="50px">
          <template slot="header">
            <div class="w-100 p-0 text-center">
              {{ $t("Unit") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="OriginalPrice" class="p-0" min-width="70px">
          <template slot="header">
            <div class="w-100 p-0 text-center">
              {{ $t("MSRP") }}
            </div>
          </template>
          <template v-slot="{ row }">
            ${{ row.OriginalPrice }}
          </template>
        </el-table-column>
        <el-table-column prop="UnitPrice" class="p-0" min-width="100px">
          <template slot="header">
            <div class="w-100 p-0 text-center">
              {{ $t("Sale Price") }}
            </div>
          </template>
          <template v-slot="{ row }">
            ${{ row.UnitPrice }}
          </template>
        </el-table-column>
        <el-table-column prop="OrderQty" class="p-0" min-width="100px">
          <template slot="header">
            <div class="w-100 p-0 text-center">
              {{ $t("Qty") }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="TotalPrice" class="p-0" min-width="100px">
          <template slot="header">
            <div class="w-100 p-0 text-center">
              {{ $t("Subtotal") }}
            </div>
          </template>
          <template v-slot="{ row }">
            ${{ row.TotalPrice }}
          </template>
        </el-table-column>
      </el-table>
      <template #modal-footer>
        <button type="button" class="btn btn-secondary" @click="close_view_original">
          {{ $t("Close") }}
        </button>
      </template>
    </b-modal>
  </b-container>
</template>
<script>
import {
  Card,
  Table,
  TableColumn,
  Button,
  Pagination,
  Collapse,
  CollapseItem,
  Row,
  Col,
  Tabs,
  TabPane,
  Autocomplete,
  Radio,
  RadioGroup,
  Select,
  Option,
  OptionGroup,
  Cascader,
  Dropdown,
  DropdownMenu,
  DropdownItem,
} from "element-ui";
import SearchAutocomplete from "../Components/SearchAutocomplete";
import InputAutocomplete from "../InputAutocomplete";
import InputAutocomplete2 from "../InputAutocomplete2";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";
import { XlsxRead, XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";
import { hasUtilityAuth } from "../../directives/acl";

const axios = require("axios");

export default {
  components: {
    [Card.name]: Card,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Row.name]: Row,
    [Col.name]: Col,
    [Pagination.name]: Pagination,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Autocomplete.name]: Autocomplete,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [flatPicker.name]: flatPicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Cascader.name]: Cascader,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    SearchAutocomplete,
    InputAutocomplete,
    InputAutocomplete2,
    XlsxRead,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
    // XlsxJson,
    // AddressInput,
  },
  props: {
    shipping: {  // for shipping
      type: Boolean,
      default: false
    }
  },
  watch: {
    current_page: function (newval, oldval) {
      this.get_lists();
    },
    'cur_so.SoldToCountryIDn': function(val){
      if (this.cur_so.SoldToCountryIDn) {
        this.cur_so.SoldToCountryIDn = parseInt(val);
      }
    },
    deep: true,
    'cur_so.ShipToCountryIDn': function(val){
      if (this.cur_so.ShipToCountryIDn) {
        this.cur_so.ShipToCountryIDn = parseInt(val);
      }
    },
    deep: true,
    'cur_so.ComCountryIDn': function(val){
      if (this.cur_so.ComCountryIDn) {
        this.cur_so.ComCountryIDn = parseInt(val);
      }
    },
    deep: true,
    'cur_so.TermIDn': function(val){
      if (this.cur_so.TermIDn) {
        this.cur_so.TermIDn = parseInt(val);
      }
    },
    deep: true,
    'cur_so.OrderByIDn': function(val){
      if (this.cur_so.OrderByIDn) {
        this.cur_so.OrderByIDn = parseInt(val);
      }
    },
    deep: true,
    'cur_so.ShippingMode': function(val){
      if (this.cur_so.ShippingMode) {
        this.cur_so.ShippingMode = parseInt(val);
      }
    },
    deep: true,
  },
  data() {
    return {
      token: "",
      user_id: "",
      current_page: 1,
      page_total: 0,
      per_page: 20,
      per_page_option: [10, 20, 50],
      delay_timer: null,
      so_delay_timer: null,
      so_show_name2: false,
      is_link_so: false,
      link_from_so: "",
      freeze_button: false,
      how_to_deal_backorder: 1,    // 1 for create back order
      how_to_deal_delete: 1,       // 1 for just delete
      to_ship_status: "Packed",
      refresh_list: false,

      // for S/N
      cur_sn_so_pro: "",
      cur_sn_inv: "",
      changed_sn_inv: {},
      input_sn: "",
      tobe_select_sn: [],
      tmp_selected_sn: [],

      currpage: "list",
      currreturnpage: "list",
      activeName: "Complete", // Draft Pending Complete Back Deleted Back PreOrder Contract Special

      quick_add_input: "",

      customer_list: null,
      customer_cname_list: [],
      customer_credit: 0.0,
      pay_period_list: [],
      carrier_list: [],
      carrier_names: [],

      country_list: [],
      province_list: [],
      city_list: [],

      all_tax: [],
      province_taxtype_2_taxlist: [],

      cur_address_list: [],

      isOpen: false,
      search: "",
      search_results: [],
      cur_address: {},
      cur_provincelist: [],
      new_address: {
        IDn: 0,
        Type: "customer",
        ReferenceIDn: 0,
        BillingFlag: 0,
        ShippingFlag: 0,
        Status: 1,
        Name: "",
        Street1: "",
        Street2: "",
        AddressCity: "",
        ProvinceIDn: 2,
        AddressProvince: "British Columbia",
        CountryIDn: 1,
        AddressCountry: "Canada",
        ZIP: "",
        Remarks: "",
        tp: "",
      },

      showReturn: false,
      showOutbound: false,
      activeCollapse1: ["ProductList"],
      activeCollapse2: [],

      store_list: null,
      sales_list: [],

      // Search condition
      cur_start_dt: "",
      cur_end_dt: "",
      cur_date_type: "", // Order, Invoice, Delivery, Return
      cur_customer_search: "",
      cur_customer_id: 0,
      cur_order_no: "",
      cur_invoice_no: "",
      cur_return_no: "",
      cur_remarks: "",
      cur_product_name4: "",
      cur_LocationID: "",
      cur_user_idn: 0,

      // product search conditions
      pro_search_plu: "",
      pro_search_name: "",
      pro_search_unit: "",
      pro_search_price: "",
      pro_search_topprice: "",
      pro_search_inventory: "",
      pro_search_onhold: "",
      pro_search_onorder: "",

      so_qty_changed: false,

      cur_search_departmentIDn: 0,
      cur_search_categoryIDn: 0,
      cur_searched_categorys: [],
      cur_searched_products: [],
      cur_listed_products: [],      // In all product dialogue, listed products

      full_product_list: [],
      full_product_list_flat: [],
      buy_product_list: [],
      inventory_products: {},
      onhold_data: {},
      onorder_data: {},
      cur_product_tree: [],
      selected_product_list: [],   // In all product dialogue, temp selected products
      payment_method_list: [],
      payment_type_list: [],
      credit_type_list: [],
      is_last_order: false,        // if all product dialogue shows last order products

      cur_shipping_list: [],

      cur_payment_edit: {},
      cur_credit_edit: {},
      new_payment: {
        IDn: 0,
        OrderType: "SO",
        ReferenceIDn: 0,
        CustomerIDnType: "Customer",
        CustomerIDn: 0,
        InvoiceNo: "",
        InvoiceAmount: 0.0,
        PaymentMethodID: "Cash",
        PaymentAmount: 0.0,
        Remarks: "",
        CreateBy: "",
        PaymentDateTime: null,
        CreateDateTime: null,
        PayType: "Payment",
        Balance: 0.0,
        Status: "",
      },
      new_credit: {
        IDn: 0,
        OrderType: "SO",
        ReferenceIDn: 0,
        CustomerIDnType: "Customer",
        CustomerIDn: 0,
        InvoiceNo: "",
        InvoiceAmount: 0.0,
        PaymentMethodID: "Cash",
        PaymentAmount: 0.0,
        Remarks: "",
        CreateBy: "",
        PaymentDateTime: null,
        CreateDateTime: null,
        PayType: "Redeem",
        Balance: 0.0,
        Status: "",
      },

      cur_so_list: [],
      cur_so_meta: {},
      cur_so: {},
      cur_so_tax: [],
      cur_so_product: [],
      cur_so_return: [],
      cur_so_payment: [],
      cur_so_credit: [],
      cur_return_edit: {},
      cur_return_product: [],
      cur_return_tax: [],
      return_view_mode: false,
      return_mode: false,
      cur_inbound: [],
      cur_outbound: [],
      cur_related_orders: [],
      cur_so_customer_search: "",
      customer_select: [],
      ori_data: {},

      collection: [],
      import_file: null,
      import_file_warning: "",
      downloadsheet: {
        name: "so import",
        data: [["Barcode", "Name1", "Name2", "Qty", "Price"]],
      },

      // cur_so_product_tax: [],
      // new so
      new_so: {
        SOIDn: 0,
        OrderID: "",

        LocationID: "",
        ComTitle: "",
        ComAddress1: "",
        ComAddress2: "",
        ComCity: "",
        ComProvince: "",
        ComCountry: "",
        ComProvinceIDn: 2,
        ComCountryIDn: 1,
        ComZIP: "",
        ComTEL: "",
        ComExt: "",
        ComFAX: "",

        SoldToCustomerIDn: 0,
        SoldToName: "",
        SoldToOtherName: "",
        SoldToAddressIDn: "",
        SoldToContact: "",
        SoldToAddress1: "",
        SoldToAddress2: "",
        SoldToCity: "",
        SoldToProvince: "",
        SoldToCountry: "",
        SoldToProvinceIDn: "",
        SoldToCountryIDn: "",
        SoldToZIP: "",
        SoldToTEL: "",
        SoldToExt: "",
        SoldToFAX: "",

        ShipToCustomerIDn: 0,
        ShipToName: "",
        ShipToOtherName: "",
        ShipToAddressIDn: "",
        ShipToContact: "",
        ShipToAddress1: "",
        ShipToAddress2: "",
        ShipToCity: "",
        ShipToProvince: "",
        ShipToCountry: "",
        ShipToProvinceIDn: "",
        ShipToCountryIDn: "",
        ShipToZIP: "",
        ShipToTEL: "",
        ShipToExt: "",
        ShipToFAX: "",

        PreOrder: "",
        CustomerPONumber: "",
        PayPeriodIDn: 0,
        FileStartDate: null,
        OrderDate: new Date(),
        ShipDate: null,
        SOEndDate: null,
        ShippingMode: 1,  // default delivery

        OrderByIDn: "",
        OrderByName: "",
        FileEndByIDn: 0,
        FileEndByName: "",

        ShipStatus: "",
        ContractNo: "",
        CarrierIDn: 0,
        CarrierName: "",
        Remarks: "",
        Status: "Draft",
        ShipFee: 0.0,
        TAXFlag: "",
        SubTotal: 0.0,
        TotalTax: 0.0,
        OtherFees: 0.0,
        TotalAmount: 0.0,
        TotalAmountBeforeFee: 0.0,
        Paid: 0,
        PaidAmount: 0.0,
        ReturnAmount: 0.0,
        ReturnCredit: 0.0,
        SOCredit: 0.0,
        Type: "",
        MAC: "",
        TerminalType: "",
        AppUser: "",
        soDiscount: 0.0,
        SoDiscountRate: 0.0,  // %
        ProductDiscount: 0.0,    // only for display, not saved in db
        ContainerNo: "",
        TrackNo: "",
        TermIDn: 1,            // default COD
      },
      picker_config2: {
        allowInput: true,
        dateFormat: "Y-m-d",
        altFormat: "Y-m-d",
        maxDate: new Date().fp_incr(6), // New Date();  YYYY-MM-DD HH:MM
      },
      pickr_config: {
        allowInput: true,
        enableTime: false,
        noCalendar: false,
        dateFormat: "Y-m-d",
        // time_24hr: true
      },
      disable_customer_input: true,
      disable_billing_input: true,
      disable_shipping_input: true,
      disable_store_input: true,
      cur_billing_provincelist: [],
      cur_shipping_provincelist: [],
      cur_store_provincelist: [],
      shipping_status_option: [{
          IDn: 1,
          Status: "Delivery",
        }, {
          IDn: 2,
          Status: "Pick Up",
        },{
          IDn: 3,
          Status: "Price Check",
        },{
          IDn: 4,
          Status: "Ship Collect",
        },{
          IDn: 5,
          Status: "Ship Prepaid",
      }],

      // add customer
      pay_periodlist: [],
      positionlist: [],
      company_typelist: [],
      new_customer: {
        Name: "",
        OtherName: "",
        Tel: "",
        CompanyType: "",
        Address1:"",
        Address2: "",
        ProvinceIDn: 2,
        CountryIDn: 1,
        ZIP: "",
        PaymentTerm: "",
        Contact: {
          FirstName: "",
          LastName: "",
          Tel: "",
          Email: "",
          Position: "",
        },
      },
      loading: false,
      new_customer_provincelist: [],
    };
  },
  computed: {
    apiBaseUrl: function () {
      return axios.defaults.baseURL + "/Api";
    },
  },
  methods: {
    hasUtilityAuth,
    is_so_draft() {
      return this.cur_so.Status == 'Draft';
    },
    is_so_pending() {
      return this.cur_so.Status == 'Pending';
    },
    is_so_complete() {
      return this.cur_so.Status == 'Complete';
    },
    is_so_deleted() {
      return this.cur_so.Status == 'Deleted';
    },
    is_so_packed() {
      return this.cur_so.Status=='Complete' && this.cur_so.ShipStatus=='Packed';
    },
    is_so_shipped() {
      return this.cur_so.Status=='Complete' && this.cur_so.ShipStatus=='Shipped';
    },
    is_view_mode() {
      return this.is_so_complete() || this.is_so_deleted();
    },
    is_linked_backorder() {
      return this.cur_so.OriSOIDn > 0;
    },
    is_backorder() {
      return this.cur_so.Type == 'Back';
    },
    is_shipping() {
      return this.shipping;
    },
    to_lock_so() {
      return this.is_so_locked() && !this.is_shipping();
    },
    is_so_locked() {
      return (this.cur_so.ShipStatus != undefined) && (this.cur_so.ShipStatus == 'Process');
    },
    querySearch_edit(queryString, cb) {
      var customers = this.customer_select;
      var results = queryString ? customers.filter(this.createFilter(queryString)) : customers;
      // call callback function to return suggestions
      cb(results);
    },
    querySearch_list(queryString, cb) {
      var customers = this.customer_select;
      var results = queryString ? customers.filter(this.createFilter(queryString)) : customers;
      if (!queryString) this.customer_clear_list();
      else if (results.length == 1) this.listpage_customer_selected(results[0]);
      // call callback function to return suggestions
      cb(results);
    },
    querySearchCarrierName(queryString, cb) {
      var options = this.carrier_list;
      var results = queryString ? options.filter(this.createCarrierNameFilter(queryString)) : options;
      var matchCarrier = options.filter(this.createCarrierNameAccurateMatchFilter(queryString));
      if (matchCarrier.length > 0) {
        this.cur_so.CarrierIDn = matchCarrier[0].IDn;
      } else {
        this.cur_so.CarrierIDn = 0;
      }
      cb(results);
    },
    createFilter(queryString) {
      return (customer) => {
        return (customer.CompanyName.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    createCarrierNameFilter(queryString) {
      return (carrier) => {
        return (carrier.CarrierName.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    createCarrierNameAccurateMatchFilter(queryString) {
      return (carrier) => {
        return (carrier.CarrierName === queryString);
      };
    },
    setinput_city(city, type) {
      if(type == 0){
        this.cur_address.AddressCity = city;
      } else if(type == 1){
        this.cur_so.ComCity = city;
      }else if(type == 2){
        this.cur_so.SoldToCity = city;
      }else if(type == 3){
        this.cur_so.ShipToCity = city; 
      }
    },
    changed_province() {
      if (this.cur_provincelist.length <= 0) {
        return;
      }

      if (
        !this.cur_address.ProvinceIDn ||
        this.cur_address.ProvinceIDn == undefined ||
        this.cur_address.ProvinceIDn <= 0
      ) {
        this.cur_address.ProvinceIDn = this.cur_provincelist[0].IDn;
      }
      this.cur_address.AddressProvince = "";
      for (var i = 0; i < this.cur_provincelist.length; i++) {
        if (this.cur_provincelist[i].IDn == this.cur_address.ProvinceIDn) {
          this.cur_address.AddressProvince = this.cur_provincelist[i].Province;
        }
      }
      if (this.cur_address.AddressProvince == "") {
        // Didn't find Province
        this.cur_address.ProvinceIDn = this.cur_provincelist[0].IDn;
      }
    },
    changed_country_newaddr() {
      if (
        !this.cur_address.CountryIDn ||
        this.cur_address.CountryIDn == undefined ||
        this.cur_address.CountryIDn <= 0
      ) {
        this.cur_address.CountryIDn = 1; // Set default to Canada
      }

      for (var idx in this.country_list) {
        if (this.country_list[idx].IDn == this.cur_address.CountryIDn) {
          this.cur_address.AddressCountry = this.country_list[idx].Country;
        }
      }
      this.cur_provincelist = [];
      for (var idx in this.province_list) {
        if (this.province_list[idx].CountryIDn == this.cur_address.CountryIDn) {
          this.cur_provincelist.push(this.province_list[idx]);
        }
      }
      this.changed_province();
    },
    changed_country(countryIDn, type) {
      var tempCountryIDn = countryIDn;
      // type 0: this.cur_address.CountryIDn
      // type 1: cur_so.ComCountryIDn       store
      // type 2: cur_so.SoldToCountryIDn    Billing Address
      // type 3: cur_so.ShipToCountryIDn    Shipping Address
      // type 4: new_customer.CountryIDn    new customer
      if (
        !tempCountryIDn ||
        tempCountryIDn == undefined ||
        tempCountryIDn <= 0
      ) {
        if( type == 0){  
           this.cur_address.CountryIDn = tempCountryIDn;
        } else if( type == 1){
          this.cur_so.ComCountryIDn = tempCountryIDn;
        } else if( type == 2){
          this.cur_so.SoldToCountryIDn = tempCountryIDn;
        } else if( type == 3){
          this.cur_so.ShipToCountryIDn = tempCountryIDn;
        } else if( type == 4){
          this.new_customer.CountryIDn = tempCountryIDn;
        } 
      }
      if(  type == 0 ){
        for (var idx in this.country_list) {
          if (this.country_list[idx].IDn == tempCountryIDn) {
            this.cur_address.AddressCountry = this.country_list[idx].Country;
          }
        }
      }
      var temp_provincelist = [];
      for (var idx in this.province_list) {
        if (this.province_list[idx].CountryIDn == tempCountryIDn) {
          temp_provincelist.push(this.province_list[idx]);
        }
      }
      if( type == 0){  
        this.cur_provincelist = temp_provincelist;
      } else if( type == 1){
        this.cur_store_provincelist = temp_provincelist;
      } else if( type == 2){
        this.cur_billing_provincelist = temp_provincelist;
      } else if( type == 3){
        this.cur_shipping_provincelist = temp_provincelist;
      } else if( type == 4){
        this.new_customer_provincelist = temp_provincelist;
      }
    },
    onChange() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("w", this.search);
      bodyFormData.append("cIDn", this.cur_address.CountryIDn);
      bodyFormData.append("postalcode", "1");  // or 0

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/MultiAdd/search",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.search_results = rt.data.addresslist;
              that.isOpen = true;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            alert(response.statusText);
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    cancel_edit_address() {
      this.$refs["address-modal"].hide();
    },
    async submit_address() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      for (var key in this.cur_address) {
        if (key == "ReferenceIDn") {
          bodyFormData.append(key, this.cur_so.SoldToCustomerIDn);
        } else {
          bodyFormData.append(key, this.cur_address[key]);
        }
        this.cur_payment_edit["ReferenceIDn"] = this.cur_so.SOIDn;
      }

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/MultiAdd/submit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              var newaddr = rt.data;
              that.cur_address_list.push(newaddr);
              if (that.cur_address.tp == "billing") {
                that.cur_so.SoldToAddressIDn = newaddr.IDn;
                that.setinput_soldto_address();
              } else if (that.cur_address.tp == "shipping") {
                that.cur_so.ShipToAddressIDn = newaddr.IDn;
                that.setinput_shipto_address();
              }
              that.calculate_el_select_width2();
              that.$refs["address-modal"].hide();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            alert(response.statusText);
          }
        })
        .catch(function (error) {
          //XXXXX that.$router.push("/login");
        });
    },
    setResult(result) {
      // Result: 110 Charles Street East, Toronto, ON, Canada
      this.search = result;
      var addrs = result.split(",");
      if (addrs[0] != undefined) {
        this.cur_address.Street1 = addrs[0];
        if (addrs[1] != undefined) {
          this.cur_address.AddressCity = addrs[1].trim();
        }
        var CIDn = 1; // Default to Canada
        if (addrs[3] != undefined) {
          var country = addrs[3].trim();
          for (var idx in this.country_list) {
            if (this.country_list[idx].Country == country) {
              CIDn = this.country_list[idx].IDn;
              this.cur_address.AddressCountry = this.country_list[idx].Country;
              break;
            }
          }
        }
        this.cur_address.CountryIDn = CIDn;
        var PIDn = 2; // Default to British Columbia
        if (addrs[2] != undefined) {
          var province = addrs[2].trim();
          for (var idx in this.province_list) {
            if (this.province_list[idx].ProvinceID == province) {
              PIDn = this.province_list[idx].IDn;
              this.cur_address.AddressProvince = province;
              break;
            }
          }
        }
        if (addrs[4] != undefined) {
          this.cur_address.ZIP = addrs[4].trim();
        }
        this.cur_address.ProvinceIDn = PIDn;
      } else {
        this.cur_address.Street1 = result;
      }
      this.isOpen = false;
    },
    start_new_address(tp) {
      if (this.cur_so.SoldToCustomerIDn == undefined ||
          this.cur_so.SoldToCustomerIDn <= 0) {
        alert("Please select customer first");
        return;
      }
      this.cur_address = JSON.parse(JSON.stringify(this.new_address));
      if (tp == "shipping") {
        // this.cur_address.ShippingFlag = 1;
        this.cur_address.tp = tp;
      } else if (tp == "billing") {
        // this.cur_address.BillingFlag = 1;
        this.cur_address.tp = tp;
      } else {
        alert("Some wrong with address type: " + tp);
        return;
      }
      this.changed_country_newaddr();
      this.search = "";
      this.search_results = [];
      this.$refs["address-modal"].show();
    },
    outbound_row_clicked_event(row, column, event) {
      event.preventDefault();
      let table = this.$refs.outbound_table;
      table.toggleRowExpansion(row);
    },
    print_order() {
      if (!this.cur_so.SOIDn) {
        alert('Please save first');
        return;
      }
      var url = this.apiBaseUrl + "/Web/Sales/Pdf?token=" + this.token;
      url += "&user_id=" + this.user_id;
      url += "&SOIDn=" + this.cur_so.SOIDn;
      window.open(url, "_blank");
    },
    print_picking() {
      var url = this.apiBaseUrl + "/Web/Sales/Pdf?token=" + this.token;
      url += "&user_id=" + this.user_id;
      url += "&SOIDn=" + this.cur_so.SOIDn;
      url += "&pickpack=pick";
      window.open(url, "_blank");
    },
    print_packing() {
      var url = this.apiBaseUrl + "/Web/Sales/Pdf?token=" + this.token;
      url += "&user_id=" + this.user_id;
      url += "&SOIDn=" + this.cur_so.SOIDn;
      url += "&pickpack=pack";
      window.open(url, "_blank");
    },
    print_invoice() {
      this.print_order();
      this.refresh_list = true;
    },
    print_return() {
      var url = this.apiBaseUrl + "/Web/Sales/Pdf?token=" + this.token;
      url += "&user_id=" + this.user_id;
      url += "&SOIDn=" + this.cur_so.SOIDn;
      url += "&return=" + this.cur_return_edit.IDn;
      window.open(url, "_blank");
    },
    pack_ship_submit() {
      if (this.check_inventory()) {
        return;
      }
      
      let ship_over_zero = false;
      let back_order = false;
      let ship_over_orderqty_product = '';
      for (var i = 0; i < this.cur_so_product.length; i++) {
        let order_qty = parseFloat(this.cur_so_product[i].OrderQty);
        let ship_qty = parseFloat(this.cur_so_product[i].ShippingQty);
        if (order_qty > ship_qty) {
          back_order = true;
        }
        if (order_qty < ship_qty) {
          ship_over_orderqty_product += "\n" + this.cur_so_product[i].ProductName1;
        }
        if (ship_qty > 0) {
          ship_over_zero = true;
        }
      }
      if (!ship_over_zero) {
        alert("Empty Shipping is not allowed");
        return;
      }
      if (ship_over_orderqty_product) {
        alert("The following product's Shipping Qty is larger than Order Qty which is not allowed:" + ship_over_orderqty_product);
        return;
      }
      if (back_order) {
        this.$refs["select-backorder-modal"].show();
        return;
      }
      this.submit_click('Complete');
    },
    pack() {
      this.to_ship_status = "Packed";
      this.pack_ship_submit();
    },
    pack_ship() {
      this.to_ship_status = "Shipped";
      this.pack_ship_submit();
    },
    submit_select_backorder() {
      switch(this.how_to_deal_backorder) {
        case 1:
          this.submit_click('BackOrder');   // create back order, normal handling
          break;
        case 2:
          this.submit_click('Complete');      // no back order and close
          break;
        case 3:
          this.submit_click('NewSo');      // create new so
          break;
        default:
          break;
      }
      this.$refs["select-backorder-modal"].hide();
    },
    close_select_backorder_model() {
      this.$refs["select-backorder-modal"].hide();
    },
    submit_delete_query() {
      switch(this.how_to_deal_delete) {
        case 1:
          this.submit_click('Deleted');       // just delete
          break;
        case 2:
          this.submit_click('Split');         // split the back order
          break;
        default:
          break;
      }
      this.$refs["delete-query-modal"].hide();
    },
    close_delete_query_model() {
      this.$refs["delete-query-modal"].hide();
    },
    refresh_product_cost() {
      if (this.cur_so_product) {
        for (let k in this.cur_so_product) {
          let so_product = this.cur_so_product[k];
          let product = this.full_product_list_flat[so_product.ProductIDn];
          if (product) {
            so_product.LatestCost = product.LatestCost;
            so_product.AverageCost = product.AverageCost;
            // refresh originalprice according to customer and store
            var priceList = this.customer_list[this.cur_so.SoldToCustomerIDn].PriceList;
            so_product.OriginalPrice = this.get_price_by_level(product, priceList);
          }
        }
      }
    },
    refresh_product_with_inventory() {
      var dpt, cat, prd;
      for (var i = 0; i < this.full_product_list.length; i++) {
        dpt = this.full_product_list[i]['IDn'];
        for (var j = 0; j < this.full_product_list[i]["Categorys"].length; j++) {
          cat = this.full_product_list[i]["Categorys"][j]['IDn'];
          for (var k = 0; k < this.full_product_list[i]["Categorys"][j]["Products"].length; k++) {
            prd = this.full_product_list[i]["Categorys"][j]["Products"][k]['IDn'];
            if (this.inventory_products.hasOwnProperty(dpt) &&
                this.inventory_products[dpt].hasOwnProperty(cat) &&
                this.inventory_products[dpt][cat].hasOwnProperty(prd)) {
              this.full_product_list[i]["Categorys"][j]["Products"][k]['StoreQty'] = this.inventory_products[dpt][cat][prd].StoreQty;
              this.full_product_list[i]["Categorys"][j]["Products"][k]['StoreCost'] = this.inventory_products[dpt][cat][prd].StoreCost;
              this.full_product_list[i]["Categorys"][j]["Products"][k]['LatestCost'] = this.inventory_products[dpt][cat][prd].LatestCost;
              this.full_product_list[i]["Categorys"][j]["Products"][k]['AverageCost'] = this.inventory_products[dpt][cat][prd].AverageCost;
              this.full_product_list[i]["Categorys"][j]["Products"][k]['HasInventory'] = true;
            } else {
              this.full_product_list[i]["Categorys"][j]["Products"][k]['StoreQty'] = 0;
              this.full_product_list[i]["Categorys"][j]["Products"][k]['StoreCost'] = 0;
              this.full_product_list[i]["Categorys"][j]["Products"][k]['LatestCost'] = -1;
              this.full_product_list[i]["Categorys"][j]["Products"][k]['AverageCost'] = -1;
              this.full_product_list[i]["Categorys"][j]["Products"][k]['HasInventory'] = false;
            }

            // set on hold, on order
            this.full_product_list[i]["Categorys"][j]["Products"][k]['OnHold'] =
              this.onhold_data.hasOwnProperty(prd) ? parseFloat(this.onhold_data[prd]).toFixed(2) : '0.00';
            this.full_product_list[i]["Categorys"][j]["Products"][k]['OnOrder'] =
              this.onorder_data.hasOwnProperty(prd) ? parseFloat(this.onorder_data[prd]).toFixed(2) : '0.00';
          }
        }
      }
    },
    add_cur_order_qty() {
      for (var key in this.cur_so_product) {
        this.cur_so_product[key]["CurOrderQty"] = this.cur_so_product[key]["OrderQty"];
      }
    },
    on_import_change(event) {
      this.import_file = event.target.files ? event.target.files[0] : null;
    },
    reset_import_file() {
      this.import_file = null;
      this.$refs.fileuploader.value = '';
    },
    import_file_parsed(filedata) {
      this.import_file_warning = "";
      this.selected_product_list = [];
      // console.log('import_file_parsed', arguments, filedata);
      var sheets = filedata.Sheets[Object.keys(filedata.Sheets)[0]];
      var a = sheets["A1"] != undefined ? sheets["A1"].w.trim() : "";
      var b = sheets["B1"] != undefined ? sheets["B1"].w.trim() : "";
      var c = sheets["C1"] != undefined ? sheets["C1"].w.trim() : "";
      var d = sheets["D1"] != undefined ? sheets["D1"].w.trim() : "";
      var e = sheets["E1"] != undefined ? sheets["E1"].w.trim() : "";
      var find;
      if (
        a != "Barcode" ||
        b != "Name1" ||
        c != "Name2" ||
        d != "Qty" ||
        e != "Price" ||
        sheets["F1"] != undefined
      ) {
        this.import_file_warning = "Import File has something wrong";
        return;
      }
      var priceList = this.customer_list[this.cur_so.SoldToCustomerIDn].PriceList;
      for (var idx = 2; idx < 10000; idx++) {
        // Max 10000 for safe
        a = sheets["A" + idx] != undefined ? sheets["A" + idx].w.trim() : "";
        b = sheets["B" + idx] != undefined ? sheets["B" + idx].w.trim() : "";
        c = sheets["C" + idx] != undefined ? sheets["C" + idx].w.trim() : "";
        d = sheets["D" + idx] != undefined ? sheets["D" + idx].w.trim() : "";
        e = sheets["E" + idx] != undefined ? sheets["E" + idx].w.trim() : "";
        if (!a && !b && !c) {
          break;
        }

        d = parseFloat(d);  // qty
        e = parseFloat(e);  // price
        if (isNaN(d)) {
          this.import_file_warning = "Qty is not a number at line " + idx;
          return;
        }

        find = false;
        for (var i = 0; i < this.full_product_list.length; i++) {
          for (var j = 0; j < this.full_product_list[i]["Categorys"].length; j++) {
            for (var k = 0; k < this.full_product_list[i]["Categorys"][j]["Products"].length; k++) {
              let product = this.full_product_list[i]["Categorys"][j]["Products"][k];
              if ((
                    (a.trim() != '' && product.Barcode == a) ||
                    (b.trim() != '' && product.Name1 == b) ||
                    (c.trim() != '' && product.Name2 == c)
                  ) && product.HasInventory) {
                let orgprice = this.get_price_by_level(product, priceList);

                // lastorder price or msrp(orgprice)
                let price = orgprice;
                if (product.UnitPrice != undefined) {
                  price = product.UnitPrice;
                }

                if (isNaN(e)) {
                  e = price;
                }

                let prod = {
                  IDn: 0,
                  key: 0,
                  ProductIDn: product.IDn,
                  PLU: product.PLU,
                  Barcode: product.Barcode,
                  Name1: product.Name1,
                  Name2: product.Name2,
                  Unit: product.Unit,
                  Weigh: product.Weigh,
                  HasSerialNumber: product.HasSerialNumber,
                  OriginalPrice: orgprice,
                  UnitPrice: parseFloat(e).toFixed(2),
                  OrderQty: parseFloat(d).toFixed(2),
                  CurOrderQty: 1,
                  TotalPrice: (parseFloat(e) * parseFloat(d)).toFixed(2),
                  StoreQty: product.StoreQty,
                  StoreCost: product.StoreCost,
                  LatestCost: product.LatestCost,
                  AverageCost: product.AverageCost,
                  Status: "Draft", // Delete, Post, Draft
                  Remarks: product.Remarks,
                  SerialNumber: [],
                  ReferenceIDn: 0,
                  DisplayName: product.DisplayName,
                  TaxTypeIDn: product.TaxTypeIDn,
                };
                prod["Discount"] = parseFloat((prod.OriginalPrice - prod.UnitPrice) / prod.OriginalPrice * 100).toFixed(2);
                this.selected_product_list.unshift(prod);
                find = true;
                break;
              }
            }
            if (find) {
              break;
            }
          }
          if (find) {
            break;
          }
        }
        if (!find) {
          this.import_file_warning = "Can't find product at line " + idx;
          return;
        }
      }
      this.close_import_modal();
      this.submit_product_selection();
    },
    refresh_all_so_product() {
      this.cur_so.ProductDiscount = 0;
      for (var i in this.cur_so_product) {
        if (this.cur_so_product[i]["OriginalPrice"] && this.cur_so_product[i]["OriginalPrice"] != 0) {
          this.cur_so_product[i]["Discount"] = 
          parseFloat((this.cur_so_product[i]["OriginalPrice"] - this.cur_so_product[i]["UnitPrice"]) 
                     / this.cur_so_product[i]["OriginalPrice"] * 100).toFixed(2);
        } else {
          this.cur_so_product[i]["Discount"] = 0;
        }

        let qty = this.cur_so.Status == "Pending" ? this.cur_so_product[i].ShippingQty : this.cur_so_product[i].OrderQty;
        if (qty) {
          this.cur_so.ProductDiscount +=
            (parseFloat(this.cur_so_product[i].OriginalPrice) -
              parseFloat(this.cur_so_product[i].UnitPrice)) *
            parseFloat(qty);
        }

        // prevent .00
        this.cur_so_product[i].ShippingQty = parseFloat(this.cur_so_product[i].ShippingQty).toFixed(2);

        // set warranty date for retail store, only when warranty date is not set.
        this.set_warranty_date_for_soproduct(this.cur_so_product[i]);
      }
    },
    dateFormat(date, fmt) {
      var o = {
      "M+": date.getMonth() + 1, //月份
      "d+": date.getDate(), //日
      "h+": date.getHours(), //小时
      "m+": date.getMinutes(), //分
      "s+": date.getSeconds(), //秒
      "q+": Math.floor((date.getMonth() + 3) / 3), //季度
      "S": date.getMilliseconds() //毫秒
      };
      if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
      for (var k in o)
      if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
      return fmt;
    },
    set_warranty_date_for_soproduct(so_product) {
      // only do when shipping
      if (!(this.is_so_pending() && this.is_shipping())) {
        return;
      }
      let product = this.full_product_list_flat[so_product.ProductIDn];
      if (this.is_retail && !so_product.WarrantyDate && product && product.WarrantyPeriod) {
        let num = parseInt(product.WarrantyPeriod.substring(0, product.WarrantyPeriod.length - 1));
        let unit = product.WarrantyPeriod.substring(product.WarrantyPeriod.length - 1);
        if (!isNaN(num)) {
          if (unit ==="D") {
            so_product.WarrantyDate = new Date();
            so_product.WarrantyDate.setDate(so_product.WarrantyDate.getDate() + num);
            so_product.WarrantyDate = this.dateFormat(so_product.WarrantyDate, 'yyyy-MM-dd');
          } else if (unit ==="M") {
            so_product.WarrantyDate = new Date();
            so_product.WarrantyDate.setMonth(so_product.WarrantyDate.getMonth() + num);
            so_product.WarrantyDate = this.dateFormat(so_product.WarrantyDate, 'yyyy-MM-dd');
          } else if (unit ==="Y") {
            so_product.WarrantyDate = new Date();
            so_product.WarrantyDate.setFullYear(so_product.WarrantyDate.getFullYear() + num);
            so_product.WarrantyDate = this.dateFormat(so_product.WarrantyDate, 'yyyy-MM-dd');
          }
        }
      }
    },
    product_unitprice_change(row) {
      if (isNaN(parseFloat(row.UnitPrice))) {
        row.UnitPrice = 0;
      }
      if (row.OriginalPrice && row.OriginalPrice != 0) {
        row.Discount = parseFloat((row.OriginalPrice - row.UnitPrice) / row.OriginalPrice * 100).toFixed(2);
      } else {
        row.Discount = 0;
      }
      this.update_so_info_delay();
    },
    product_discount_change(row) {
      if (isNaN(parseFloat(row.Discount))) {
        row.Discount = 0;
      }
      row.UnitPrice = parseFloat(row.OriginalPrice - row.Discount * row.OriginalPrice / 100).toFixed(2);
      this.update_so_info_delay();
    },
    set_cur_product_name4(val) {
      this.cur_product_name4 = val;
    },
    clickTab(tab, event) {
      this.refresh_list = false;
      this.current_page = 1;
      this.page_total = 0;
      // this.per_page = 20;
      this.cur_so_customer_search = "";
      
      // DO NOT clear search conditions
      this.get_lists();
      this.calculate_el_select_width6();
    },
    fee_change() {
      this.cur_so.ShipFee = parseFloat(this.cur_so.ShipFee);
      if (isNaN(this.cur_so.ShipFee)) {
        this.cur_so.ShipFee = "0.00";
      } else {
        this.cur_so.ShipFee = this.cur_so.ShipFee.toFixed(2);
      }

      this.cur_so.OtherFees = parseFloat(this.cur_so.OtherFees);
      if (isNaN(this.cur_so.OtherFees)) {
        this.cur_so.OtherFees = "0.00";
      } else {
        this.cur_so.OtherFees = this.cur_so.OtherFees.toFixed(2);
      }

      if (this.cur_so.TotalAmountBeforeFee) {
        this.cur_so.TotalAmount = this.cur_so.TotalAmountBeforeFee
                                  + parseFloat(this.cur_so.ShipFee) + parseFloat(this.cur_so.OtherFees);
      } else {
        this.update_so_info();
      }
      this.$forceUpdate();
    },
    return_fee_change() {
      this.cur_return_edit.ShipFee = parseFloat(this.cur_return_edit.ShipFee);
      if (isNaN(this.cur_return_edit.ShipFee)) {
        this.cur_return_edit.ShipFee = "0.00";
      } else {
        this.cur_return_edit.ShipFee = this.cur_return_edit.ShipFee.toFixed(2);
      }

      this.cur_return_edit.OtherFees = parseFloat(this.cur_return_edit.OtherFees);
      if (isNaN(this.cur_return_edit.OtherFees)) {
        this.cur_return_edit.OtherFees = "0.00";
      } else {
        this.cur_return_edit.OtherFees = this.cur_return_edit.OtherFees.toFixed(2);
      }

      this.calc_for_return();
      this.$forceUpdate();
    },
    customer_selected(customer) {
      this.cur_so_customer_search = customer.CompanyName;
      this.cur_so.SoldToCustomerIDn = customer.IDn;
      this.cur_so.SoldToName = customer.CompanyName;
      this.cur_so.SoldToOtherName = customer.OtherName;
      this.cur_so.SoldToContact = customer.ContactFirstName1 + " " + customer.ContactLastName1;
      this.cur_so.SoldToTEL = customer.TEL;
      this.cur_so.SoldToExt = customer.ContactExt1;
      this.cur_so.SoldToFAX = customer.FAX;

      this.cur_so.ShipToCustomerIDn = this.cur_so.SoldToCustomerIDn;
      this.cur_so.ShipToName = this.cur_so.SoldToName;
      this.cur_so.ShipToOtherName = this.cur_so.SoldToOtherName;
      this.cur_so.ShipToContact = this.cur_so.SoldToContact;
      this.cur_so.ShipToTEL = this.cur_so.SoldToTEL;
      this.cur_so.ShipToExt = this.cur_so.SoldToExt;
      this.cur_so.ShipToFAX = this.cur_so.SoldToFAX;

      this.cur_address_list = customer.Address;
      if (this.cur_address_list && Array.isArray(this.cur_address_list) && this.cur_address_list.length > 0) {
        // use 1st to set soldto and shipto as default
        var key = Object.keys(this.cur_address_list)[0];
        this.cur_so.SoldToAddressIDn = this.cur_address_list[key].IDn;
        this.cur_so.ShipToAddressIDn = this.cur_so.SoldToAddressIDn;
        for (let k in this.cur_address_list) {
          if (this.cur_address_list[k].BillingFlag) {
            this.cur_so.SoldToAddressIDn = this.cur_address_list[k].IDn;
          }
          if (this.cur_address_list[k].ShippingFlag) {
            this.cur_so.ShipToAddressIDn = this.cur_address_list[k].IDn;
          }
        }
        this.setinput_soldto_address();
        this.setinput_shipto_address();
      } else {
        // clear 
        this.set_all_address_empty();
      }
      this.refresh_product_by_history();

      if (customer.PaymentTermIDn && customer.PaymentTermIDn > 0) {
        this.cur_so.TermIDn = customer.PaymentTermIDn;
      } else {
        this.cur_so.TermIDn = 1;   // default COD
      }

      this.calculate_el_select_width2();
    },
    listpage_customer_selected(customer) {
      this.cur_customer_search = customer.CompanyName;
      this.cur_customer_id = customer.IDn;
    },
    customer_clear_list() {
      this.cur_customer_search = "";
      this.cur_customer_id = 0;
    },
    customer_clear_edit() {
      this.cur_so_customer_search = "";
      // this.cur_so.SoldToCustomerIDn = 0;
    },
    carrier_selected(carrier) {
      this.cur_so.CarrierName = carrier.CarrierName;
      this.cur_so.CarrierIDn = carrier.IDn;
    },
    clear_so_product_taxes() {     // to refresh product tax
      if (this.cur_so_product) {
        this.cur_so_product.forEach((product, index) => {
          if (product.Taxes != undefined) {
            product.Taxes = undefined;
          }
        });
      }
    },
    set_all_address_empty() {
      this.cur_so.SoldToAddressIDn = "";
      this.cur_so.SoldToAddress1 = "";
      this.cur_so.SoldToAddress2 = "";
      this.cur_so.SoldToCity = "";
      this.cur_so.SoldToProvince = "";
      this.cur_so.SoldToCountry = "";
      this.cur_so.SoldToProvinceIDn = "";
      this.cur_so.SoldToCountryIDn = "";
      this.cur_so.SoldToZIP = "";

      this.cur_so.ShipToAddressIDn = "";
      this.cur_so.ShipToAddress1 = "";
      this.cur_so.ShipToAddress2 = "";
      this.cur_so.ShipToCity = "";
      this.cur_so.ShipToProvince = "";
      this.cur_so.ShipToCountry = "";
      this.cur_so.ShipToProvinceIDn = "";
      this.cur_so.ShipToCountryIDn = "";
      this.cur_so.ShipToZIP = "";

      this.clear_so_product_taxes();
    },
    setinput_soldto_address() {
      var value = false;
      for (let k in this.cur_address_list) {
        if (this.cur_so.SoldToAddressIDn == this.cur_address_list[k].IDn) {
          value = this.cur_address_list[k];
          break;
        }
      }
      if (!value) {
        return;
      }
      // this.cur_so.SoldToAddressIDn = value.IDn;
      // this.cur_so.SoldToName = value.Name;
      this.cur_so.SoldToAddress1 = value.Street1;
      this.cur_so.SoldToAddress2 = value.Street2;
      this.cur_so.SoldToCity = value.AddressCity;
      this.cur_so.SoldToProvinceIDn = value.ProvinceIDn;
      this.cur_so.SoldToProvince = this.get_privence(value.ProvinceIDn);
      this.cur_so.SoldToCountryIDn = value.CountryIDn;
      this.cur_so.SoldToCountry = this.get_country(value.CountryIDn);
      this.cur_so.SoldToZIP = value.ZIP;
      
      this.clear_so_product_taxes();
      
      this.changed_country(this.cur_so.SoldToCountryIDn, 2);

      // calculating tax is based on billing address
      this.update_so_info();
    },
    soldto_province_changed() {
      this.clear_so_product_taxes();
      // calculating tax is based on billing address
      this.update_so_info();
    },
    setinput_shipto_address() {
      var value = false;
      for (let k in this.cur_address_list) {
        if (this.cur_so.ShipToAddressIDn == this.cur_address_list[k].IDn) {
          value = this.cur_address_list[k];
          break;
        }
      }
      if (!value) {
        return;
      }
      // this.cur_so.ShipToAddressIDn = value.IDn;
      this.cur_so.ShipToName = value.Name;
      this.cur_so.ShipToAddress1 = value.Street1;
      this.cur_so.ShipToAddress2 = value.Street2;
      this.cur_so.ShipToCity = value.AddressCity;
      this.cur_so.ShipToProvinceIDn = value.ProvinceIDn;
      this.cur_so.ShipToProvince = this.get_privence(value.ProvinceIDn);
      this.cur_so.ShipToCountryIDn = value.CountryIDn;
      this.cur_so.ShipToCountry = this.get_country(value.CountryIDn);
      this.cur_so.ShipToZIP = value.ZIP;

      this.changed_country(this.cur_so.ShipToCountryIDn, 3);
    },
    get_privence(idn) {
      if (this.province_list[idn] != undefined) {
        return this.province_list[idn].ProvinceID;
      }
      return "";
    },
    get_country(idn) {
      if (this.country_list[idn] != undefined) {
        return this.country_list[idn].CountryID;
      }
      return "";
    },
    // only new draft should set init to true
    store_changed(init=false) {
      if (!init && this.cur_so.preLocationID == this.cur_so.LocationID) {
        return;
      }
      // change store alert
      if (!init) {
        let HasSerialNumber = false;
        for (let k in this.cur_so_product) {
          if (this.cur_so_product[k].HasSerialNumber == 1) {
            HasSerialNumber = true;
            break;
          }
        }
        if (HasSerialNumber) {
          var alertMsg = "To change store will immediately clear the S/N already set, are you sure to change store?";
          var r = confirm(alertMsg);
          if (r == false) {
            this.cur_so.LocationID = this.cur_so.preLocationID;
            return;
          }
            
          // clear so product sn
          for (let k in this.cur_so_product) {
            this.cur_so_product[k].SerialNumbers = '[]';
          }
          // clear inv cache
          this.changed_sn_inv = {};
          // clear so product sn in database
          if (this.cur_so.SOIDn > 0) {
            var bodyFormData = new FormData();
            bodyFormData.append("token", this.token);
            bodyFormData.append("user_id", this.user_id);
            bodyFormData.append("SOIDn", this.cur_so.SOIDn);
            bodyFormData.append("LocationID", this.cur_so.preLocationID);

            var that = this;
            axios({
              method: "post",
              url: "/Api/Web/Sales/SO/clear_sn",
              data: bodyFormData,
            })
            .then(function (response) {
              if (response.status == "200") {
                var rt = response.data;
                if (rt.status == 0) {
                } else if (rt.status == 4) {
                  alert(that.$t("No Permission"));
                  that.$router.go(-1);
                } else if (rt.message != undefined) {
                  alert(rt.message);
                }
              } else {
                that.$router.push("/login");
              }
            })
            .catch(function (error) {
              // console.log(error);
              that.$router.push("/login");
            });
          }
        }
      }
      this.cur_so.preLocationID = this.cur_so.LocationID;

      let cur_store = this.store_list[this.cur_so.LocationID];
      this.cur_so.ComTitle = cur_store.LocationName || "";
      this.cur_so.ComAddress1 = cur_store.LocationAddress1 || "";
      this.cur_so.ComAddress2 = cur_store.LocationAddress2 || "";
      this.cur_so.ComCity = cur_store.LocationCity || "";
      this.cur_so.ComProvinceIDn = cur_store.ProvinceIDn || 0;
      this.cur_so.ComCountryIDn = cur_store.CountryIDn || 0;
      this.cur_so.ComProvince = cur_store.ProvinceIDn
        ? this.province_list[cur_store.ProvinceIDn].Province
        : "";
      this.cur_so.ComCountry = cur_store.CountryIDn
        ? this.country_list[cur_store.CountryIDn].Country
        : "";
      this.cur_so.ComZIP = cur_store.LocactionZIP || "";
      this.cur_so.ComTEL = cur_store.LocationTel || "";
      this.cur_so.ComExt = cur_store.LocationTelExt || "";
      this.cur_so.ComFAX = cur_store.LocationFax || "";

      this.changed_country(this.cur_so.ComCountryIDn, 1);
      this.calculate_el_select_width2();

      if (!init) {
        var bodyFormData1 = new FormData();
        bodyFormData1.append("token", this.token);
        bodyFormData1.append("user_id", this.user_id);
        bodyFormData1.append("LocationID", this.cur_so.LocationID);

        // refresh inventory
        var that = this;
        axios({
          method: "post",
          url: "/Api/Web/Sales/SO/inventory",
          data: bodyFormData1,
        })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.inventory_products = rt.data.inventory_products;
              that.onhold_data = rt.data.onhold_data;
              that.onorder_data = rt.data.onorder_data;
              that.refresh_product_with_inventory();
              that.refresh_product_cost();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });

        // if not init, change locationid in so products
        for (let k in this.cur_so_product) {
          this.cur_so_product[k].LocationID = this.cur_so.LocationID;
        }
      }

      this.$forceUpdate();
    },
    preorder_changed() {
      this.$forceUpdate();
    },
    clear_product_condition() {
      this.pro_search_plu = "";
      this.pro_search_name = "";
      this.pro_search_unit = "";
	    this.pro_search_price = "";
      this.pro_search_inventory = "";
      this.pro_search_onhold = "";
      this.pro_search_onorder = "";
      this.search_product();
    },
    delay_search_product() {
      if (this.delay_search_timer) {
        clearTimeout(this.delay_search_timer);
        this.delay_search_timer = null;
      }
      var that = this;
      this.delay_search_timer = setTimeout(() => {
        that.search_product();
      }, 400);
    },
    matching_product(prod) {
      if ((!prod.PLU
          || this.pro_search_plu.length < 2
          || prod.PLU.search(this.pro_search_plu) >= 0) 
          &&
          (this.pro_search_name.length < 2
          || (prod.Name1 && prod.Name1.search(this.pro_search_name) >= 0)
          || (prod.Name2 && prod.Name2.search(this.pro_search_name) >= 0))
          &&
          (!prod.Unit
          || this.pro_search_unit.length < 1
          || prod.Unit.search(this.pro_search_unit) >= 0)
          &&
          (!prod.UnitPrice
          || this.pro_search_price.length < 2
          || prod.UnitPrice.search(this.pro_search_price) >= 0)
          &&
          (!prod.StoreQty
          || this.pro_search_inventory.length < 2
          || prod.StoreQty.toString().search(this.pro_search_inventory) >= 0)
          &&
          (!prod.OnHold
          || this.pro_search_onhold.length < 2
          || prod.OnHold.search(this.pro_search_onhold) >= 0)
          &&
          (!prod.OnOrder
          || this.pro_search_onorder.length < 2
          || prod.OnOrder.search(this.pro_search_onorder) >= 0)) {
        return true;
      }
      return false;
    },
    remove_so_product(idx, row) {
      this.so_qty_changed = true;
      row.OrderQty = 0;
      row.ShippingQty = 0;
      row.BackQty = 0;
      this.cur_so_product.push(this.cur_so_product.splice(idx, 1)[0]);

      // move sn of this row to inv
      for (let dpt in this.inventory_products) {
        for (let cat in this.inventory_products[dpt]) {
          for (let prd in this.inventory_products[dpt][cat]) {
            let inv = this.inventory_products[dpt][cat][prd];
            if (row.ProductIDn == inv.ProductIDn) {
              let invList = inv.SerialNumber ? JSON.parse(inv.SerialNumber) : [];
              let rowList = row.SerialNumbers ? JSON.parse(row.SerialNumbers) : [];
              invList = rowList.concat(invList);
              inv.SerialNumberList = invList;
              inv.SerialNumber = JSON.stringify(invList);
              row.SerialNumbers = "";
              this.add_changed_sn_inv(inv);
            }
          }
        }
      }
      this.update_so_info_delay();
    },
    search_product() {
      this.cur_listed_products = [];
      if (this.pro_search_plu.length < 2
          && this.pro_search_name.length < 2
          && this.pro_search_unit.length < 1
          && this.pro_search_price.length < 2
          && this.pro_search_inventory.length < 2
          && this.pro_search_onhold.length < 2
          && this.pro_search_onorder.length < 2) {
        this.cur_listed_products = this.cur_searched_products.slice();
      } else {
        for (var i = 0; i < this.cur_searched_products.length; i++) {
          if (this.matching_product(this.cur_searched_products[i])) {
            this.cur_listed_products.push(this.cur_searched_products[i]);
          }
        }
      }
    },
    search_department(dept) {
      return dept.IDn == this.cur_search_departmentIDn;
    },
    restore_all_department() {
      this.cur_searched_products = [];
      for (var i = 0; i < this.cur_product_tree.length; i++) {
        for (var j = 0; j < this.cur_product_tree[i].Categorys.length; j++) {
          for (var k = 0; k < this.cur_product_tree[i].Categorys[j].Products.length; k++) {
            this.cur_searched_products.push(
              this.cur_product_tree[i].Categorys[j].Products[k]
            );
          }
        }
      }
    },
    changed_department() {
      this.cur_searched_categorys = [];
      this.cur_search_categoryIDn = 0;
      if (this.cur_search_departmentIDn == 0) {
        this.restore_all_department();
        this.search_product();
        return;
      }
      let findout = this.cur_product_tree.find(this.search_department);
      if (findout == undefined) {
        return;
      }
      this.cur_searched_categorys = findout.Categorys;
      this.cur_searched_products = [];
      for (var i=0; i<this.cur_searched_categorys.length; i++) {
        this.cur_searched_products = this.cur_searched_products.concat(this.cur_searched_categorys[i].Products);
      }
      this.search_product();
    },
    search_category(cate) {
      return cate.IDn == this.cur_search_categoryIDn;
    },
    changed_category() {
      this.cur_searched_products = [];
      if (this.cur_search_categoryIDn == 0) {
        this.changed_department();
        return;
      }
      let findout = this.cur_searched_categorys.find(this.search_category);
      if (findout == undefined) {
        this.search_product();
        return;
      }
      this.cur_searched_products = findout.Products;
      this.search_product();
    },
    remove_product(prod) {
      for (var i = 0; i < this.selected_product_list.length; i++) {
        if (this.selected_product_list[i].IDn == prod.IDn) {
          this.selected_product_list.splice(i, 1);
          break;
        }
      }
    },
    remove_product_sn(prod, sn) {
      for (var i = 0; i < prod.SerialNumber.length; i++) {
        if (prod.SerialNumber[i] == sn) {
          prod.SerialNumber.splice(i, 1);
        }
      }
    },
    calculate_recodes(prod) {
      prod.TotalPrice = parseFloat(prod.UnitPrice) * parseFloat(prod.OrderQty);
    },

    // In all product dialogue, add product to the temp product list
    add_product_to_temp(row, index) {
      var priceList = this.customer_list[this.cur_so.SoldToCustomerIDn].PriceList;
      var orgprice = this.get_price_by_level(row, priceList);

      // lastorder price or msrp(orgprice)
      var price = orgprice;
      if (row.UnitPrice != undefined) {
        price = row.UnitPrice;
      }

      let prod = {
        IDn: new Date().getTime(),
        key: 0,
        ProductIDn: row.IDn,
        PLU: row.PLU,
        Barcode: row.Barcode,
        Name1: row.Name1,
        Name2: row.Name2,
        Unit: row.Unit,
        Weigh: row.Weigh,
        HasSerialNumber: row.HasSerialNumber,
        OriginalPrice: orgprice,
        UnitPrice: price,
        OrderQty: 1,
        CurOrderQty: 1,
        TotalPrice: price,
        StoreQty: row.StoreQty,
        StoreCost: row.StoreCost,
        LatestCost: row.LatestCost,
        AverageCost: row.AverageCost,
        Status: "Draft", // Delete, Post, Draft
        Remarks: row.Remarks,
        SerialNumber: [],
        ReferenceIDn: 0,
        DisplayName: row.DisplayName,
        Discount: parseFloat((orgprice - price) / orgprice * 100).toFixed(2),     // only for display, not saved in db
        TaxTypeIDn: row.TaxTypeIDn,
        OnHold: row.OnHold,
        OnOrder: row.OnOrder,
      };
      this.selected_product_list.unshift(prod);
    },
    add_draft_so() {
      this.init_edit_page_data();
      this.new_so.OrderDate = new Date();
      this.new_so.ShipDate = this.getBusinessDaysLater(new Date(), 2);
      this.new_so.OrderByIDn = parseInt(this.user_id);
      this.cur_so = JSON.parse(JSON.stringify(this.new_so));
      this.cur_so_customer_search = "";
      // Use first sttore as Defailt Store
      this.cur_so.LocationID = Object.keys(this.store_list)[0]; // this.store_list[Object.keys(this.store_list)[0]].LocationID;
      this.cur_so.preLocationID = this.cur_so.LocationID;
      this.store_changed(true);
      this.activeCollapse1 = ["AddressInfo", "SODetail", "ProductList"];
      this.activeCollapse2 = [];
      this.showReturn = false;
      this.showOutbound = false;
      this.cur_so.SoDiscountRate = 0;
      this.$refs["product-list-modal"].hide();

      this.currpage = "edit";
      this.get_extra();

      this.calculate_el_select_width2();
    },
    close_my_modal() {
      this.selected_product_list = [];
      this.$refs["product-list-modal"].hide();
    },
    close_import_modal() {
      this.$refs["import-list-modal"].hide();
    },
    close_add_payment_model() {
      this.$refs["add-payment-modal"].hide();
    },
    close_add_credit_model() {
      this.$refs["add-credit-modal"].hide();
    },
    link_other_order(row, event, column) {
      this.is_link_so = true;
      this.link_from_so = this.cur_so;
      this.edit_so(row, event, column);
    },
    init_edit_page_data() {
      this.cur_sn_so_pro = "";
      this.cur_sn_inv = "";
      this.changed_sn_inv = {};
      this.input_sn = "";
      this.tobe_select_sn = [];
      this.tmp_selected_sn = [];
    },
    edit_so(row, column, event) {
      if ((this.is_shipping() && !hasUtilityAuth('ShippingEdit')) || (!this.is_shipping() && !hasUtilityAuth('SOEdit'))) return;
      this.init_edit_page_data();
      this.cur_so = JSON.parse(JSON.stringify(row));
      this.cur_so.preLocationID = this.cur_so.LocationID;
      this.so_qty_changed = false;

      this.cur_so_customer_search = this.cur_so.SoldToName;
      var customers = this.customer_select;
      var results = customers.filter((customer) => {
        return (this.cur_so.SoldToCustomerIDn == customer.IDn);
      });
      if (results.length > 0) {
        this.cur_address_list = results[0].Address;
      } else {
        alert("SO customer is not found, maybe it is inactive");
        return;
      }

      this.cur_so.ShipFee = parseFloat(this.cur_so.ShipFee).toFixed(2);
      this.cur_so.OtherFees = parseFloat(this.cur_so.OtherFees).toFixed(2);

      if (this.is_so_packed() && this.is_shipping()) {
        // when pack, the only field can be filled is Track No which is in sodetail
        this.activeCollapse1 = ["SODetail", "ProductList"];
        this.calculate_el_select_width2();
      } else {
        this.activeCollapse1 = ["ProductList"];
      }
      this.activeCollapse2 = [];
      this.showReturn = false;
      this.showOutbound = false;
      this.$refs["product-list-modal"].hide();
      switch (this.cur_so.Status) {
        case "Complete":
          this.showOutbound = true;
          break;
        case "Draft":
        case "Pending":
        case "Deleted":
          break;
      }

      this.currpage = "edit";
      this.get_extra();
      if (this.cur_so.SoldToCustomerIDn > 0 && (this.is_so_draft() || this.is_so_pending())) {
        this.refresh_product_by_history();
      }

      // init province dropdown
      this.changed_country(this.cur_so.ComCountryIDn, 1);
      this.changed_country(this.cur_so.SoldToCountryIDn, 2);
      this.changed_country(this.cur_so.ShipToCountryIDn, 3);
    },
    async build_product() {
      this.cur_listed_products = [];
      if (this.cur_so.SoldToCustomerIDn == undefined ||
          this.cur_so.SoldToCustomerIDn <= 0) {
        alert("Please select customer first");
        return;
      }
      this.changed_department();
      this.$refs["product-list-modal"].show();
    },
    async add_product() {
      this.is_last_order = false;
      this.cur_product_tree = [];
      for (var key1 in this.full_product_list) {
        let dept = JSON.parse(JSON.stringify(this.full_product_list[key1]));
        dept.Categorys = [];
        for (var key2 in this.full_product_list[key1].Categorys) {
          let cate = JSON.parse(JSON.stringify(this.full_product_list[key1].Categorys[key2]));
          cate.Products = [];
          for (var key3 in this.full_product_list[key1].Categorys[key2].Products) {
            let product = JSON.parse(JSON.stringify(this.full_product_list[key1].Categorys[key2].Products[key3]));
            if (product['HasInventory']) {
              cate.Products.push(product);
            }
          }
          dept.Categorys.push(cate);
        }
        this.cur_product_tree.push(dept);
      }
      this.calculate_el_select_width3();
      return this.build_product();
    },
    async import_product() {
      if (this.cur_so.SoldToCustomerIDn == undefined ||
          this.cur_so.SoldToCustomerIDn <= 0) {
        alert("Please select customer first");
        return;
      }
      this.import_file = null;
      this.so_qty_changed = true;
      this.selected_product_list = [];
      this.$refs["import-list-modal"].show();
    },
    view_original() {
      this.$refs["view-original"].show();
    },
    close_view_original() {
      this.$refs["view-original"].hide();
    },
    async last_order_product() {
      this.is_last_order = true;
      this.cur_product_tree = [];
      for (var key1 in this.buy_product_list) {
        let dept = JSON.parse(JSON.stringify(this.buy_product_list[key1]));
        dept.Categorys = [];
        for (var key2 in this.buy_product_list[key1].Categorys) {
          let cate = JSON.parse(JSON.stringify(this.buy_product_list[key1].Categorys[key2]));
          cate.Products = [];
          // cate.Products = Object.values(
          //   this.buy_product_list[key1].Categorys[key2].Products
          // );
          for (var key3 in this.buy_product_list[key1].Categorys[key2].Products) {
            let product = JSON.parse(JSON.stringify(this.buy_product_list[key1].Categorys[key2].Products[key3]));
            // if product is not for sale in this store, here is the place to filter it.
            if (this.inventory_products.hasOwnProperty(key1) &&
                this.inventory_products[key1].hasOwnProperty(key2) &&
                this.inventory_products[key1][key2].hasOwnProperty(key3)) {
              product['StoreQty'] = this.inventory_products[key1][key2][key3].StoreQty;
              product['StoreCost'] = this.inventory_products[key1][key2][key3].StoreCost;
              product['LatestCost'] = this.inventory_products[key1][key2][key3].LatestCost;
              product['AverageCost'] = this.inventory_products[key1][key2][key3].AverageCost;

              let prd = product.ProductIDn
              // set on hold, on order
              product['OnHold'] = this.onhold_data.hasOwnProperty(prd) ? parseFloat(this.onhold_data[prd]).toFixed(2) : '0.00';
              product['OnOrder'] = this.onorder_data.hasOwnProperty(prd) ? parseFloat(this.onorder_data[prd]).toFixed(2) : '0.00';

              cate.Products.push(product);
            }
          }
          dept.Categorys.push(cate);
        }
        this.cur_product_tree.push(dept);
      }
      return this.build_product();
    },
    async quickAdd(){
      if (this.cur_so.SoldToCustomerIDn == undefined ||
          this.cur_so.SoldToCustomerIDn <= 0) {
        alert("Please select customer first");
        return;
      }

      if (this.quick_add_input.length < 2) {
        alert("Please input more than 1 charactor");
        return;
      }

      this.is_last_order = false;
      this.cur_product_tree = [];
      var matched_products = [];
      var search_key = this.quick_add_input.toLowerCase();
      for (var key1 in this.full_product_list) {
        var dept = JSON.parse(JSON.stringify(this.full_product_list[key1]));
        dept.Categorys = [];
        for (var key2 in this.full_product_list[key1].Categorys) {
          var cate = JSON.parse(JSON.stringify(this.full_product_list[key1].Categorys[key2]));
          cate.Products = [];
          for (var key3 in this.full_product_list[key1].Categorys[key2].Products) {
            var product = JSON.parse(JSON.stringify(this.full_product_list[key1].Categorys[key2].Products[key3]));
            if(String(product.Name1).toLowerCase().includes(search_key) || 
               String(product.Name2).toLowerCase().includes(search_key) || 
               String(product.PLU).toLowerCase().includes(search_key) || 
               String(product.Barcode).toLowerCase().includes(search_key) )
            {
              // matched
              if (product['HasInventory']) {
                cate.Products.push(product);
                matched_products.push(product);
              }
            }
          }
          if (cate.Products.length != 0) {
            dept.Categorys.push(cate);
          }
        }
        if (dept.Categorys.length != 0) {
          this.cur_product_tree.push(dept);
        }
      }
      // if only one matched, add it to so products directly
      if (matched_products.length === 1) {
        this.add_product_to_temp(matched_products[0]);
        this.submit_product_selection();
      } else if (matched_products.length === 0) {
        alert("No product found");
      } else {
        this.build_product();
      }
      this.quick_add_input = "";
    },
    submit_product_selection() {
      this.so_qty_changed = true;

      var so_product;
      for (let k in this.selected_product_list) {
        // new product
        so_product = {};
        so_product["IDn"] = 0;
        so_product["SOIDn"] = this.cur_so.SOIDn;
        so_product["CustomerIDn"] = this.cur_so.SoldToCustomerIDn;
        so_product["LocationID"] = this.cur_so.LocationID;
        so_product["ProductIDn"] = this.selected_product_list[k]["ProductIDn"];
        so_product["InventoryIDn"] = "";   // so product not use inventoryidn
        so_product["PLU"] = this.selected_product_list[k]["PLU"];
        so_product["Barcode"] = this.selected_product_list[k]["Barcode"];
        so_product["ProductName1"] = this.selected_product_list[k]["Name1"];
        so_product["ProductName2"] = this.selected_product_list[k]["Name2"];
        so_product["OrderQty"] = this.selected_product_list[k]["OrderQty"];
        so_product["CurOrderQty"] = so_product["OrderQty"];
        so_product["Weigh"] = this.selected_product_list[k]["Weigh"];
        so_product["Unit"] = this.selected_product_list[k]["Unit"];
        so_product["OriginalPrice"] = parseFloat(this.selected_product_list[k]["OriginalPrice"]) || 0;
        if (so_product["OriginalPrice"] == "NaN")
          so_product["OriginalPrice"] = -1;
        so_product["UnitPrice"] = parseFloat(this.selected_product_list[k]["UnitPrice"]) || 0;
        if (so_product["UnitPrice"] == "NaN") so_product["UnitPrice"] = -1;
        so_product["TotalPrice"] = parseFloat(so_product["UnitPrice"]) * parseFloat(so_product["OrderQty"]) || 0; // Default Order Qty is 1
        if (so_product["TotalPrice"] == "NaN") so_product["TotalPrice"] = -1;
        so_product["Discount"] = this.selected_product_list[k]["Discount"];
        if (so_product["Discount"] == "NaN") so_product["Discount"] = 0;
        so_product["Remarks"] = this.selected_product_list[k]["Remarks"];
        so_product["ShippingQty"] = so_product["OrderQty"];
        so_product["BackQty"] = 0;
        so_product["seqOrder"] = 0;
        so_product["SerialNumbers"] = "";
        so_product["ReferenceIDn"] = "";
        so_product["Type"] = "Local"; //valid values: Local
        so_product["StoreQty"] = parseFloat(this.selected_product_list[k]["StoreQty"]) || 0;
        if (so_product["StoreQty"] == "NaN") so_product["StoreQty"] = -1;
        so_product["StoreCost"] = parseFloat(this.selected_product_list[k]["StoreCost"]) || 0;
        if (so_product["StoreCost"] == "NaN") so_product["StoreCost"] = -1;
        so_product["LatestCost"] = parseFloat(this.selected_product_list[k]["LatestCost"]) || 0;
        if (so_product["LatestCost"] == "NaN") so_product["LatestCost"] = -1;
        so_product["AverageCost"] = parseFloat(this.selected_product_list[k]["AverageCost"]) || 0;
        if (so_product["AverageCost"] == "NaN") so_product["AverageCost"] = -1;
        so_product["ExpiryDate"] = null; // ???
        so_product["TotalTax"] = 0;
        so_product["HasSerialNumber"] = this.selected_product_list[k]["HasSerialNumber"];  // int
        so_product["TaxTypeIDn"] = this.selected_product_list[k]["TaxTypeIDn"];
        this.set_warranty_date_for_soproduct(so_product);
        this.cur_so_product.push(so_product);
      }
      this.update_so_info();  // new product, get product remark
      this.selected_product_list = [];
      this.$refs["product-list-modal"].hide();
    },

    async submit_click(status = "Draft") {
      this.post_so(status);
    },
    async submit_click_check_inventory(status = "Draft") {
      if (this.check_inventory()) {
        return;
      }
      this.post_so(status);
    },
    check_inventory() {
      let not_find = '';
      for (var i = 0; i < this.cur_so_product.length; i++) {
        let find = false;
        for (let dpt in this.inventory_products) {
          for (let cat in this.inventory_products[dpt]) {
            for (let prd in this.inventory_products[dpt][cat]) {
              let inv = this.inventory_products[dpt][cat][prd];
              if (this.cur_so_product[i].ProductIDn == inv.ProductIDn) {
                find = true;
              }
            }
          }
        }
        if (!find) {
          not_find += '\n' + this.cur_so_product[i].ProductName1
        }
      }
      if (not_find) {
        alert('The following products are not for sale in this store:' + not_find);
      }
      return not_find;
    },
    cancel_click() {
      if (this.is_link_so) {
        this.is_link_so = false;
        this.edit_so(this.link_from_so);
      } else {
        this.currpage = "list";
        if (this.refresh_list) {
          this.clickTab();
        }
      }
      this.calculate_el_select_width6();
    },
    create_customer_datalist() {
      this.customer_cname_list = [];
      for (var key in this.customer_list) {
        this.customer_cname_list.push(
          this.customer_list[key].CompanyName +
            "-" +
            this.customer_list[key].IDn
        );
        this.customer_list[key].value = this.customer_list[key].CompanyName;
      }
    },
    setStartandEndDate() {
      // Get current time
      var today = new Date();
      // var month = 5;
      // today.setFullYear(2020, month-1, 31);
      // Get Current day, month, year
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //Jan: mm="01"    Dec: mm="12"
      var yyyy = today.getFullYear();
      // Get 1 month ago day, month, year
      var dd_1_Month_Ago = dd;
      var mm_1_Month_Ago;
      var yyyy_1_Month_Ago = yyyy;
      // Case 1: January now!
      if(mm === "01"){
        mm_1_Month_Ago = "12";  // Set month to be Dec
        yyyy_1_Month_Ago = parseFloat(yyyy) - 1; // Set year to be last year
      }
      // Case 2: March now!
      else if(mm === "03"){
        mm_1_Month_Ago = parseFloat(mm) - 1;   // Set month to be last month

        // Rule: if current date is March 29/30/31, 1 month ago is Feb 28/29, depends on if leap year
        if(parseFloat(dd) > 28){
          if(((0 == parseFloat(yyyy) % 4) && (0 != parseFloat(yyyy) % 100) || (0 == parseFloat(yyyy) % 400))){
            dd_1_Month_Ago = "29";
          } else {
            dd_1_Month_Ago = "28";
          }
        }
      }
      // Case 3: Feb, Apr, June, Sept or Nov now!
      else if(mm === "02" || mm === "04" || mm === "06" || mm === "09" || mm === "11"){
        mm_1_Month_Ago = parseFloat(mm) - 1;   // Set month to be last month
      }
      // Case 4: May, July, Aug, Oct or Dec now!
      else {
        mm_1_Month_Ago = parseFloat(mm) - 1;   // Set month to be last month

        // Rule: if current date is May 31, 1 month ago is Apr 30
        if(parseFloat(dd) > 30){
          dd_1_Month_Ago = "30";
        }
      }

      // Set Start Date and End Date
      this.cur_end_dt = yyyy + '-' + mm + '-' + dd;
      this.cur_start_dt = yyyy_1_Month_Ago + '-' + mm_1_Month_Ago + '-' + dd_1_Month_Ago;
      this.cur_date_type = "Order";
    },
    setSearchConditions(bodyFormData) {
      if (this.cur_start_dt) {
        bodyFormData.append("start_dt", this.cur_start_dt);
      }
      if (this.cur_end_dt) {
        bodyFormData.append("end_dt", this.cur_end_dt);
      }
      if (this.cur_date_type) {
        bodyFormData.append("cur_date_type", this.cur_date_type);
      }
      if (this.cur_customer_id) {
        bodyFormData.append("SoldToCustomerIDn", this.cur_customer_id);
      }
      if (this.cur_order_no) {
        bodyFormData.append("OrderID", this.cur_order_no);
      }
      if (this.cur_invoice_no) {
        bodyFormData.append("InvoiceNo", this.cur_invoice_no);
      }
      if (this.cur_return_no) {
        bodyFormData.append("ReturnNo", this.cur_return_no);
      }
      if (this.cur_remarks) {
        bodyFormData.append("Remarks", this.cur_remarks);
      }
      if (this.cur_product_name4) {
        bodyFormData.append("Name4", this.cur_product_name4);
      }
      if (this.cur_LocationID) {
        bodyFormData.append("LocationID", this.cur_LocationID);
      }
      if (this.cur_user_idn) {
        bodyFormData.append("OrderByIDn", this.cur_user_idn);
      }
      return bodyFormData;
    },
    reset_search() {
      // clear list page search product input
      document.getElementById("list_search_product").children[0].value = "";

      this.cur_product_name4 = "";
      this.cur_user_idn = 0;
      this.cur_LocationID = "";
      this.cur_invoice_no = "";
      this.cur_return_no = "";
      this.cur_order_no = "";
      this.cur_customer_search = "";
      this.cur_customer_id = 0;
      this.cur_remarks = "";
      this.setStartandEndDate();
      this.get_lists();
    },
    async get_lists() {
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("page", this.current_page);
      bodyFormData.append("per_page", this.per_page);
      bodyFormData = this.setSearchConditions(bodyFormData);
      bodyFormData.append("Status", this.activeName);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Sales/SO/list",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              if (rt.data.Status != undefined) {
                that.activeName = rt.data.Status;
              }
              that.cur_so_list = rt.data.list_list;
              that.cur_so_meta = rt.data.list_meta;
              if (that.cur_so_meta.cnt != undefined) {
                that.page_total = that.cur_so_meta.cnt;
                that.per_page = rt.data.per_page;
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            this.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_init_data() {
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }
      this.setStartandEndDate();

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("page", this.current_page);
      bodyFormData.append("per_page", this.per_page);
      bodyFormData = this.setSearchConditions(bodyFormData);
      bodyFormData.append("Status", this.activeName);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Sales/SO",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_so_list = rt.data.list_list;
              that.cur_so_meta = rt.data.list_meta;
              if (that.cur_so_meta.cnt != undefined) {
                that.page_total = that.cur_so_meta.cnt;
                that.per_page = rt.data.per_page;
              }

              that.customer_list = rt.data.customer_list;
              that.create_customer_datalist();
              that.customer_select = Object.values(that.customer_list);
              that.pay_period_list = rt.data.pay_period_list;
              that.carrier_list = Object.values(rt.data.carrier_list);
              that.carrier_names = rt.data.carrier_names;
              that.full_product_list = rt.data.idxproducts;
              that.flat_product();
              that.store_list = rt.data.store_list;
              that.sales_list = rt.data.sales_list;
              that.country_list = rt.data.country_list;
              that.province_list = rt.data.province_list;
              that.city_list = rt.data.city_list;
              that.so_show_name2 = rt.data.so_show_name2;
              that.all_tax = rt.data.all_tax;
              that.province_taxtype_2_taxlist = rt.data.province_taxtype_2_taxlist;
              that.calculate_el_select_width6();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_extra() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("page", this.current_page);
      bodyFormData.append("per_page", this.per_page);
      bodyFormData.append("SOIDn", this.cur_so.SOIDn);
      bodyFormData.append("LocationID", this.cur_so.LocationID);
      bodyFormData.append("CustomerIDn", this.cur_so.SoldToCustomerIDn);
      bodyFormData.append("OriSOIDn", this.cur_so.OriSOIDn);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Sales/SO/extra",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_so_tax = rt.data.so_tax;
              that.cur_so_product = rt.data.so_product;
              that.refresh_all_so_product();
              that.cur_so_return = rt.data.so_return;
              that.inventory_products = rt.data.inventory_products;
              that.onhold_data = rt.data.onhold_data;
              that.onorder_data = rt.data.onorder_data;

              that.add_cur_order_qty();
              that.refresh_product_with_inventory();
              that.cur_so_payment = rt.data.so_payment;
              that.cur_so_credit = rt.data.so_credit;
              that.cur_outbound = rt.data.outbound;
              that.cur_inbound = rt.data.inbound;
              that.payment_method_list = rt.data.payment_method_list;
              that.payment_type_list = rt.data.payment_type_list;
              that.credit_type_list = rt.data.credit_type_list;
              that.customer_credit = rt.data.customer_credit;
              that.cur_related_orders = rt.data.related_orders;
              that.cur_so.show_ori = rt.data.show_ori;
              that.ori_data = rt.data.ori_data;
              if (that.ori_data == undefined) {
                that.ori_data = {};
              }
              that.$forceUpdate();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    get_price_by_level(prd, price_level) {
      var price = prd.APrice;
      if (price_level == "B") {
        price = prd.BPrice;
      } else if (price_level == "C") {
        price = prd.CPrice;
      } else if (price_level == "D") {
        price = prd.DPrice;
      } else if (price_level == "E") {
        price = prd.EPrice;
      } else if (price_level == "F") {
        price = prd.FPrice;
      } else if (price_level == "G") {
        price = prd.GPrice;
      } else if (price_level == "H") {
        price = prd.HPrice;
      } else if (price_level == "I") {
        price = prd.IPrice;
      } else if (price_level == "J") {
        price = prd.JPrice;
      }
      return price;
    },
    // refresh unitprice according to customer purchase history
    // if no history, set unitprice to PriceX according to customer's price group.
    refresh_unitprice() {
      var customer_price = this.customer_list[this.cur_so.SoldToCustomerIDn].PriceList;
      var dpt, cat, prd;
      for (var i = 0; i < this.full_product_list.length; i++) {
        dpt = this.full_product_list[i];
        for (var j = 0; j < this.full_product_list[i]["Categorys"].length; j++ ) {
          cat = this.full_product_list[i]["Categorys"][j];
          for (var k = 0; k < this.full_product_list[i]["Categorys"][j]["Products"].length; k++ ) {
            prd = this.full_product_list[i]["Categorys"][j]["Products"][k];
            // if find history
            if (this.buy_product_list[dpt.IDn] != undefined &&
              this.buy_product_list[dpt.IDn]["Categorys"][cat.IDn] != undefined &&
              this.buy_product_list[dpt.IDn]["Categorys"][cat.IDn]["Products"][prd.IDn] != undefined
            ) {
              this.full_product_list[i]["Categorys"][j]["Products"][k]["UnitPrice"] = 
                this.buy_product_list[dpt.IDn]["Categorys"][cat.IDn]["Products"][prd.IDn].UnitPrice;
            } else {
              var price = this.get_price_by_level(prd, customer_price);
              this.full_product_list[i]["Categorys"][j]["Products"][k]["UnitPrice"] = price;
            }
          }
        }
      }
    },

    // full_product_list is three-tier structure, construct a flat product structure
    async flat_product() {
      this.full_product_list_flat = {};
      var categorys, products, prd;
      for (var i = 0; i < this.full_product_list.length; i++) {
        categorys = this.full_product_list[i]["Categorys"];
        for (var j = 0; j < categorys.length; j++) {
          products = categorys[j]['Products'];
          for (var k = 0; k < products.length; k++) {
            prd = products[k];
            this.full_product_list_flat[prd['IDn']] = prd;
          }
        }
      }
    },

    // get customer purchase history
    async refresh_product_by_history() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("CustomerIDn", this.cur_so.SoldToCustomerIDn);
      bodyFormData.append("LocationID", this.cur_so.LocationID);
      bodyFormData.append("CountryIDn", this.cur_so.SoldToCountryIDn);
      bodyFormData.append("ProvinceIDn", this.cur_so.SoldToProvinceIDn);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Sales/SO/buy_product",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.buy_product_list = rt.data.buy_product_list;
              that.refresh_unitprice();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },

    async duplicate() {
      // freeze buttons to avoid misoperation
      this.freeze_button = true;

      var bodyFormData = new FormData();

      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("SOIDn", this.cur_so.SOIDn);
      
      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Sales/SO/duplicate",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              if (rt.data.to_status != undefined) {
                that.activeName = rt.data.to_status;
              }

              that.currpage = "list";
              that.clickTab();
              that.calculate_el_select_width6();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
            that.freeze_button = false;
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },

    async post_so(to_status = "Draft") {
      // delete check
      if (to_status == 'Deleted' && (this.cur_so_payment != undefined && this.cur_so_payment.length > 0)) {
        alert("Order that has payment can't be deleted");
        return;
      }

      // freeze buttons to avoid misoperation
      this.freeze_button = true;

      var bodyFormData = new FormData();
      if (!this.cur_so.Status) this.cur_so.Status = "Draft";
      let from_status = this.cur_so.Status;

      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("so_new_status", to_status);
      bodyFormData.append("so_from_status", from_status);
      bodyFormData.append("so", JSON.stringify(this.cur_so));
      bodyFormData.append("so_tax", JSON.stringify(this.cur_so_tax));
      bodyFormData.append("so_product", JSON.stringify(this.cur_so_product));
      bodyFormData.append("changed_sn_inv", JSON.stringify(this.changed_sn_inv));
      if (this.is_shipping()) {
        bodyFormData.append("to_ship_status", this.to_ship_status);
      }

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Sales/SO/update",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              if (rt.data.to_status != undefined) {
                that.activeName = rt.data.to_status;
              }
              if (rt.data.action == "print invoice") {
                that.cur_so.SOIDn = rt.data.SOIDn;
                that.print_invoice();
              }

              that.currpage = "list";
              that.clickTab();
              that.calculate_el_select_width6();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
            that.freeze_button = false;
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    qty_changed(row) {
      if (isNaN(parseFloat(row.OrderQty))) {
        row.OrderQty = 0;
      }

      if (isNaN(parseFloat(row.ShippingQty))) {
        row.ShippingQty = 0;
      }

      // it means to delete product when set order qty to 0 .
      if (row.OrderQty <= 0) {
        row.ShippingQty = 0;
      }

      this.so_qty_changed = true;
      this.update_so_info_delay();
    },
    update_so_info_delay() {
      this.calc_for_so();
    },
    update_so_info() {
      this.calc_for_so();
    },
    // update_so_info_delay() {
    update_so_info_delay_bak() {
      if (this.so_delay_timer) {
        clearTimeout(this.so_delay_timer);
        this.so_delay_timer = null;
      }
      var that = this;
      this.so_delay_timer = setTimeout(() => {
        that.update_so_info();
      }, 400);
    },
    // update_so_info() {
    update_so_info_bak() {
      var bodyFormData = new FormData();
      if (!this.cur_so.Status) this.cur_so.Status = "Draft";
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("SOIDn", this.cur_so.SOIDn);
      bodyFormData.append("Status", this.cur_so.Status);
      bodyFormData.append("CustomerIDn", this.cur_so.SoldToCustomerIDn);
      bodyFormData.append("LocationID", this.cur_so.LocationID);
      bodyFormData.append("CountryIDn", this.cur_so.SoldToCountryIDn);
      bodyFormData.append("ProvinceIDn", this.cur_so.SoldToProvinceIDn);
      bodyFormData.append("so_product", JSON.stringify(this.cur_so_product));

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Sales/SO/update_product",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_so_product = rt.data.so_product;
              that.cur_so_tax = rt.data.so_tax;
              that.update_so();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    update_so() {
      if (this.cur_so.SoDiscountRate < 0 || this.cur_so.SoDiscountRate > 100) {
        this.cur_so.SoDiscountRate = 0;
      }
      var discountPercentForMul = parseFloat(100 - this.cur_so.SoDiscountRate) / 100;
      this.cur_so.SubTotal = 0;
      this.cur_so.TotalTax = 0;
      this.cur_so.TotalAmount = 0;
      this.cur_so.ProductDiscount = 0;
      for (let k in this.cur_so_product) {
        this.cur_so.SubTotal +=
          Number(this.cur_so_product[k]["TotalPrice"]) || 0;

        let qty = this.cur_so.Status == "Pending" ? this.cur_so_product[k].ShippingQty : this.cur_so_product[k].OrderQty;
        if (qty) {
          this.cur_so.ProductDiscount +=
            (parseFloat(this.cur_so_product[k].OriginalPrice) -
              parseFloat(this.cur_so_product[k].UnitPrice)) *
            parseFloat(qty);
        }
      }
      this.cur_so.ProductDiscount = parseFloat(this.cur_so.ProductDiscount);
      for (let k in this.cur_so_tax) {
        this.cur_so_tax[k]["TaxAmount"] = parseFloat(this.cur_so_tax[k]["TaxAmount"]) || 0;
        this.cur_so_tax[k]["TaxAmount"] *= discountPercentForMul;
        this.cur_so_tax[k]["TaxAmount"] = parseFloat(this.cur_so_tax[k]["TaxAmount"].toFixed(2));
        this.cur_so.TotalTax += this.cur_so_tax[k]["TaxAmount"];
      }
      this.cur_so.SubTotal = parseFloat(this.cur_so.SubTotal) || 0;
      this.cur_so.soDiscount = (this.cur_so.SubTotal * this.cur_so.SoDiscountRate) / 100;
      this.cur_so.soDiscount = parseFloat(this.cur_so.soDiscount.toFixed(2));
      this.cur_so.TotalAmountBeforeFee = this.cur_so.SubTotal + this.cur_so.TotalTax - this.cur_so.soDiscount;
      this.cur_so.TotalAmount = this.cur_so.TotalAmountBeforeFee
                                + parseFloat(this.cur_so.ShipFee) + parseFloat(this.cur_so.OtherFees);
      this.cur_so.TotalAmount = parseFloat(this.cur_so.TotalAmount.toFixed(2));
    },
    calc_for_so() {
      let new_so_tax = new Object;
      let new_so_subtotal = 0;
      this.cur_so.ProductDiscount = 0;
      for (var prokey in this.cur_so_product) {
        let prod = this.cur_so_product[prokey];
        let qty = parseFloat(this.is_so_pending() ? prod.ShippingQty : prod.OrderQty);

        // update LatestCost AverageCost
        let product = this.full_product_list_flat[prod.ProductIDn];
        if (product) {
          prod.LatestCost = product.LatestCost;
          prod.AverageCost = product.AverageCost;
          // refresh originalprice according to customer and store
          var priceList = this.customer_list[this.cur_so.SoldToCustomerIDn].PriceList;
          prod.OriginalPrice = this.get_price_by_level(product, priceList);
        }
        if (prod.OriginalPrice && prod.OriginalPrice != 0) {
          prod.Discount = parseFloat((prod.OriginalPrice - prod.UnitPrice) / prod.OriginalPrice * 100).toFixed(2);
        } else {
          prod.Discount = 0;
        }

        if (qty > 0) {
          prod.TotalPrice = this.so_round(parseFloat(prod.UnitPrice) * qty);
          prod.BackQty = parseFloat(prod.OrderQty) - parseFloat(prod.ShippingQty);
          let pro_totaltax = 0;
          // Tax should keep fixed once created, so do not refresh the tax already created.
          // Tax should be re-created when the billing addr changed, so clean product tax then.
          let taxes = prod.Taxes;
          if (taxes == undefined) {
            taxes = [];
            if (this.province_taxtype_2_taxlist.hasOwnProperty(this.cur_so.SoldToProvinceIDn)
               && this.province_taxtype_2_taxlist[this.cur_so.SoldToProvinceIDn].hasOwnProperty(prod.TaxTypeIDn)) {
              let taxlist = this.province_taxtype_2_taxlist[this.cur_so.SoldToProvinceIDn][prod.TaxTypeIDn];
              for (let k1 in taxlist) {
                let taxid = taxlist[k1];
                if (this.all_tax.hasOwnProperty(taxid)) {
                  taxes.push(JSON.parse(JSON.stringify(this.all_tax[taxid])));
                }
              }
            }
          }
          for (var key in taxes) {
            let tax = taxes[key];
            // re-calc tax
            let TaxAmount = 0;
            if (tax.ValueType == '%') {
              TaxAmount = prod.TotalPrice * parseFloat(tax.TaxValue) / 100.0;
            } else {
              TaxAmount = qty * parseFloat(tax.TaxValue);
            }
            TaxAmount = this.so_round(TaxAmount);

            tax.TaxAmount = TaxAmount;
            tax.TaxID = tax.TaxID ? tax.TaxID : tax.ID;

            // add to pro
            pro_totaltax += TaxAmount;

            // add to so
            if (!new_so_tax.hasOwnProperty(tax.TaxID)) {
              new_so_tax[tax.TaxID] = {
                TaxID: tax.TaxID,
                TaxName: tax.TaxName,
                TaxType: tax.TaxType,
                TaxValue: tax.TaxValue,
                ValueType: tax.ValueType,
                TaxAmount: TaxAmount
              };
            } else {
              new_so_tax[tax.TaxID].TaxAmount += TaxAmount;
            }
          }
          prod.Taxes = taxes;
          prod.TotalTax = this.so_round(pro_totaltax);
          prod.Total = prod.TotalPrice + prod.TotalTax;

          this.cur_so.ProductDiscount += (parseFloat(prod.OriginalPrice) - parseFloat(prod.UnitPrice)) * parseFloat(qty);
          new_so_subtotal += prod.TotalPrice;
        } else {
          prod.TotalPrice = 0.00;
          prod.TotalTax = 0.00;
          prod.Total = 0.00;
          prod.BackQty = prod.OrderQty;
        }
      }

      this.cur_so.ProductDiscount = this.so_round(this.cur_so.ProductDiscount);
      // so discount rate applies to two places, 1 is so discount amount, 2 is so tax
      if (this.cur_so.SoDiscountRate < 0 || this.cur_so.SoDiscountRate > 100) {
        this.cur_so.SoDiscountRate = 0;
      }
      let so_discountRate = parseFloat(this.cur_so.SoDiscountRate);
      this.cur_so.SubTotal = new_so_subtotal;
      this.cur_so.soDiscount = Math.round(new_so_subtotal * so_discountRate) / 100;   // no need to *100 cause so_discountRate is with %
      this.cur_so.TotalTax = 0;
      this.cur_so_tax = [];
      for (var key in new_so_tax) {
        let tax_amount = new_so_tax[key].TaxAmount;
        tax_amount -= tax_amount * so_discountRate / 100;
        new_so_tax[key].TaxAmount = this.so_round(tax_amount);
        this.cur_so.TotalTax += new_so_tax[key].TaxAmount;
        this.cur_so_tax.push(new_so_tax[key]);
      }
      this.cur_so.TotalAmountBeforeFee = this.cur_so.SubTotal + this.cur_so.TotalTax - this.cur_so.soDiscount;
      this.cur_so.TotalAmount = this.cur_so.TotalAmountBeforeFee
                                + parseFloat(this.cur_so.ShipFee) + parseFloat(this.cur_so.OtherFees);
      this.cur_so.TotalAmount = this.so_round(this.cur_so.TotalAmount);
    },
    format_date(date) {
      let p = new Intl.DateTimeFormat("en", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      })
        .formatToParts(date)
        .reduce((acc, part) => {
          acc[part.type] = part.value;
          return acc;
        }, {});
      return `${p.year}-${p.month}-${p.day} ${p.hour}:${p.minute}:${p.second}`;
    },
    get_balance() {
      var balance = parseFloat(this.cur_so.TotalAmount) - parseFloat(this.cur_so.PaidAmount) 
                   - parseFloat(this.cur_so.ReturnAmount) + parseFloat(this.cur_so.ReturnCredit) + parseFloat(this.cur_so.SOCredit);
      return balance;
    },
    init_new_payment() {
      // default to pay balance
      var amount = this.get_balance();
      amount = parseFloat(amount.toFixed(2));
      if (amount <= 0) {
        alert("Payment is allowed only when balance is positive");
        return;
      }
      this.cur_payment_edit = JSON.parse(JSON.stringify(this.new_payment));
      this.cur_payment_edit["CustomerIDn"] = this.cur_so.SoldToCustomerIDn;
      this.cur_payment_edit["ReferenceIDn"] = this.cur_so.SOIDn;
      this.cur_payment_edit["InvoiceNo"] = this.cur_so.OrderID;
      this.cur_payment_edit["InvoiceAmount"] = this.cur_so.TotalAmount;
      this.cur_payment_edit["PaymentAmount"] = amount > 0 ? amount : 0;
      this.$refs["add-payment-modal"].show();
    },
    init_new_credit() {
      this.cur_credit_edit = JSON.parse(JSON.stringify(this.new_credit));
      this.cur_credit_edit["CustomerIDn"] = this.cur_so.SoldToCustomerIDn;
      this.cur_credit_edit["ReferenceIDn"] = this.cur_so.SOIDn;
      this.cur_credit_edit["InvoiceNo"] = this.cur_so.OrderID;
      this.cur_credit_edit["InvoiceAmount"] = this.cur_so.TotalAmount;
      this.cur_credit_edit["PaymentAmount"] = 0;
      this.$refs["add-credit-modal"].show();
    },
    add_payment() {
      if (!this.cur_payment_edit.PaymentAmount || this.cur_payment_edit.PaymentAmount <= 0) {
        alert("Please input a number greater than 0");
        return;
      }

      if (this.cur_payment_edit.PaymentAmount > this.get_balance()) {
        alert("Amount can not be greater than balance");
        return;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      for (let k in this.cur_payment_edit) {
        if (this.cur_payment_edit[k]) {
          bodyFormData.append(k, this.cur_payment_edit[k]);
        }
      }

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Sales/SO/add_payment",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_payment_edit = rt.data.payment;
              that.cur_so_payment = rt.data.so_payment;
              that.cur_so_credit = rt.data.so_credit;
              that.cur_so['PaidAmount'] = rt.data.PaidAmount;
              that.customer_credit = rt.data.customer_credit;
              that.close_add_payment_model();
              that.refresh_list = true;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    add_credit() {
      if (!this.cur_credit_edit.PaymentAmount || this.cur_credit_edit.PaymentAmount <= 0) {
        alert("Please input a number greater than 0");
        return;
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      for (let k in this.cur_credit_edit) {
        if (this.cur_credit_edit[k]) {
          bodyFormData.append(k, this.cur_credit_edit[k]);
        }
      }

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Sales/SO/add_credit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_so_credit = rt.data.so_credit;
              that.customer_credit = rt.data.customer_credit;
              that.close_add_credit_model();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    init_new_return() {
      this.return_mode = true;
      this.return_view_mode = false;
      this.cur_return_edit = {
        IDn: 0,
        SOIDn: this.cur_so.SOIDn,
        OrderID: '',
        SoOrderID: this.cur_so.OrderID,
        SubTotal: 0.0,
        TotalTax: 0.0,
        OtherFees: 0.0,
        ShipFee: 0.0,
        TotalAmount: 0.0,
        ReturnDate: new Date(),
        ReturnBy: '',
        Status: 'Returned',
        CreateDateTime: '',
        UpdateDateTime: '',
        TAXFlag: '',
        Remarks: '',
        soDiscount: this.cur_so.soDiscount,
        SoDiscountRate: this.cur_so.SoDiscountRate,
      };
      var new_so_product = JSON.parse(JSON.stringify(this.cur_so_product));
      for (var key in new_so_product) {
        new_so_product[key].Qty = new_so_product[key].ShippingQty;
        new_so_product[key].ReturnQty = 0.0;
        new_so_product[key].TotalPrice = 0.0;
        new_so_product[key].SerialNumbersOut = new_so_product[key].SerialNumbers;
        new_so_product[key].SerialNumbers = "";
        new_so_product[key].SOProductIDn = new_so_product[key].IDn;
      }
      for (var k1 = 0; k1 < this.cur_so_return.length; k1++) {
        for (var k2 = 0; k2 < this.cur_so_return[k1].Products.length; k2++) {
          for (var k3 = 0; k3 < new_so_product.length; k3++) {
            if (this.cur_so_return[k1].Products[k2].SOProductIDn == new_so_product[k3].IDn) {
              new_so_product[k3].Qty -= this.cur_so_return[k1].Products[k2].ReturnQty;
              if (new_so_product[k3].Qty <= 0.001) {
                new_so_product.splice(k3, 1);
              }
            }
          }
        }
      }

      if (new_so_product.length == 0) {
        alert("All products have been returned.");
        return;
      }

      this.cur_return_product = new_so_product;
      this.calc_for_return();
      this.$refs["return-modal"].show();
    },
    change_return_qty(row) {
      if (parseFloat(row.ReturnQty) > parseFloat(row.Qty)) {
        alert("Return Qty can't be larger than Qty");
        row.ReturnQty = 0.0;
      }
      this.calc_for_return();
    },
    // Round to keep 2 decimal places
    so_round(amount) {
      return Math.round(amount * 100) / 100;
    },
    calc_for_return() {
      let new_so_tax = new Object;
      let new_so_subtotal = 0;
      for (var prokey in this.cur_return_product) {
        let prod = this.cur_return_product[prokey];
        let qty = parseFloat(prod.ReturnQty);
        if (qty > 0) {
          let pro_totaltax = 0;
          let taxes = prod.Taxes;
          prod.TotalPrice = this.so_round(parseFloat(prod.UnitPrice) * qty);
          for (var key in taxes) {
            let tax = taxes[key];
            // re-calc tax
            let TaxAmount = 0;
            if (tax.ValueType == '%') {
              TaxAmount = prod.TotalPrice * parseFloat(tax.TaxValue) / 100.0;
            } else {
              TaxAmount = qty * parseFloat(tax.TaxValue);
            }
            TaxAmount = this.so_round(TaxAmount);

            // only TaxAmount needs update for each tax
            tax.TaxAmount = TaxAmount;

            // add to pro
            pro_totaltax += TaxAmount;

            // add to so
            if (!new_so_tax.hasOwnProperty(tax.TaxID)) {
              new_so_tax[tax.TaxID] = {
                TaxID: tax.TaxID,
                TaxName: tax.TaxName,
                TaxType: tax.TaxType,
                TaxValue: tax.TaxValue,
                ValueType: tax.ValueType,
                TaxAmount: TaxAmount
              };
            } else {
              new_so_tax[tax.TaxID].TaxAmount += TaxAmount;
            }
          }
          prod.TotalTax = this.so_round(pro_totaltax);
          prod.Total = prod.TotalPrice + prod.TotalTax;
          
          new_so_subtotal += prod.TotalPrice;
        } else {
          prod.TotalPrice = 0.00;
          prod.TotalTax = 0.00;
          prod.Total = 0.00;
        }
      }

      // so discount rate applies to two places, 1 is so discount amount, 2 is so tax
      let so_discountRate = parseFloat(this.cur_return_edit.SoDiscountRate);
      this.cur_return_edit.SubTotal = new_so_subtotal;
      this.cur_return_edit.soDiscount = Math.round(new_so_subtotal * so_discountRate) / 100;   // no need to *100 cause so_discountRate is with %
      this.cur_return_edit.TotalTax = 0;
      this.cur_return_tax = [];
      for (var key in new_so_tax) {
        let tax_amount = new_so_tax[key].TaxAmount;
        tax_amount -= tax_amount * so_discountRate / 100;
        new_so_tax[key].TaxAmount = this.so_round(tax_amount);
        this.cur_return_edit.TotalTax += new_so_tax[key].TaxAmount;
        this.cur_return_tax.push(new_so_tax[key]);
      }
      // the formula
      this.cur_return_edit.TotalAmount = this.cur_return_edit.SubTotal - this.cur_return_edit.soDiscount + this.cur_return_edit.TotalTax 
                                            + parseFloat(this.cur_return_edit.OtherFees) + parseFloat(this.cur_return_edit.ShipFee);
      this.cur_return_edit.TotalAmount = this.so_round(this.cur_return_edit.TotalAmount);
    },
    add_return() {
      let return_product = [];
      for (var key in this.cur_return_product) {
        if (this.cur_return_product[key].ReturnQty > 0.0) {
          return_product.push(this.cur_return_product[key]);
        }
      }
      if (return_product.length == 0) {
        alert("Please input return-qty larger than 0 for at least one product");
        return;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      // bodyFormData.append("SOIDn", this.cur_so.SOIDn);
      bodyFormData.append("return", JSON.stringify(this.cur_return_edit));
      bodyFormData.append("return_product", JSON.stringify(this.cur_return_product));
      bodyFormData.append("return_tax", JSON.stringify(this.cur_return_tax));

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Sales/SO/add_return",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_so_return = rt.data.so_return;
              that.cur_so_credit = rt.data.so_credit;
              that.customer_credit = rt.data.customer_credit;
              that.cur_so.ReturnAmount = rt.data.ReturnAmount;
              that.cur_so.ReturnCredit = rt.data.ReturnCredit;
              that.close_return_model();
              that.cur_return_edit.IDn = rt.data.returnidn;
              that.print_return();
              that.refresh_list = true;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    close_return_model() {
      this.$refs["return-modal"].hide();
    },
    view_return(index, row) {
      this.return_view_mode = true;
      this.cur_return_edit = row;
      this.cur_return_product = row.Products;
      this.cur_return_tax = row.Taxes;
      this.$refs["return-modal"].show();
    },
    open_discount_dialogue() {
      this.$refs["discount-modal"].show();
    },
    apply_discount() {
      var rate = parseFloat(this.cur_so.SoDiscountRate);
      if (rate > 100) {
        alter("Discount must less than 100%");
        return;
      }
      if (rate < 0) {
        alter("Discount must greater than 0%");
        return;
      }
      this.update_so_info_delay();

      // modify so remark
      var remarkTemplate = "% Discount applied to this order";
      var regex = "[0-9.]+" + remarkTemplate;
      regex = new RegExp(regex);
      if (this.cur_so.Remarks.search(regex) >= 0) {
        var newRemark = (rate == 0) ? "" : this.cur_so.SoDiscountRate + remarkTemplate;
        this.cur_so.Remarks = this.cur_so.Remarks.replace(regex, newRemark);
      } else {
        this.cur_so.Remarks += "\n" + this.cur_so.SoDiscountRate + remarkTemplate;
      }
      this.cur_so.Remarks = this.cur_so.Remarks.trim();
      
      this.$refs["discount-modal"].hide();
    },
    close_discount_model() {
      this.$refs["discount-modal"].hide();
    },
    delete_so() {
      var alertMsg = "Are you sure to delete this Order?";
      var r = confirm(alertMsg);
      if (r == false) {
        return;
      }

      // check linked backorder, ask how to handle if yes
      if (this.is_linked_backorder()) {
        this.$refs["delete-query-modal"].show();
      } else {
        this.submit_click('Deleted');
      }
    },
    rollback() {
      var alertMsg = "Are you sure to rollback this Order?";
      var r = confirm(alertMsg);
      if (r == false) {
        return;
      }
      this.submit_click('Draft');
    },
    lockUnLockSO(type) {
      var shipStatus = type === 'lock' ? "Process" : "";
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("SOIDn", this.cur_so.SOIDn);
      bodyFormData.append("ShipStatus", shipStatus);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Sales/SO/shipstatus",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_so.ShipStatus = rt.data.so.ShipStatus;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    delete_payment_credit(index, row, type) {
      var alertMsg = "Payment delete is a crucial action. Make sure you understand the consequences before acting on it.\n\nAre you sure to continue?";
      var url = "/Api/Web/Sales/SO/delete_payment";
      if (type != 'payment') {
        alertMsg = "Credit delete is a crucial action. Make sure you understand the consequences before acting on it.\n\nAre you sure to continue?";
        url = "/Api/Web/Sales/SO/delete_credit";
      }
      var r = confirm(alertMsg);
      if (r == false) {
        return;
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("IDn", row.IDn);
      bodyFormData.append("ReferenceIDn", this.cur_so.SOIDn);
      bodyFormData.append("CustomerIDn", this.cur_so.SoldToCustomerIDn);

      var that = this;
      axios({
        method: "post",
        url: url,
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_so_payment = rt.data.so_payment;
              that.cur_so_credit = rt.data.so_credit;
              that.cur_so['PaidAmount'] = rt.data.PaidAmount;
              that.customer_credit = rt.data.customer_credit;
              that.refresh_list = true;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    formatDate(timestamp) {
      var utcTime = new Date(timestamp * 1000);
      return this.format_date(utcTime);
    },
    disableCustomerInput(){
      if(this.disable_customer_input){
        this.disable_customer_input=false;
        return;
      }
      this.disable_customer_input=true;
    },
    disableBillingInput(){
      if(this.disable_billing_input){
        this.disable_billing_input=false;
        return;
      }
      this.disable_billing_input=true;
    },
    addNewBilling(){
      this.start_new_address("billing");
    },
    disableShippingInput(){
      if(this.disable_shipping_input){
        this.disable_shipping_input=false;
        return;
      }
      this.disable_shipping_input=true;
    },
    addNewShipping(){
      this.start_new_address("shipping");
    },
    disableStoreInput(){
      if(this.disable_store_input){
        this.disable_store_input=false;
        return;
      }
      this.disable_store_input=true;
    },
    calculate_el_select_width2(){
      setTimeout(()=>{
        for(var i = 0; i < document.getElementsByClassName("el-dropdown2").length; i++){
          document.getElementsByClassName("el-dropdown2")[i].children[0].children[0].style.borderRadius = "0.375rem";
          document.getElementsByClassName("el-dropdown2")[i].style.width = document.getElementsByClassName("form-control")[0].clientWidth + "px";
          document.getElementsByClassName("el-dropdown2")[i].style.width = (parseFloat(document.getElementsByClassName("el-dropdown2")[i].style.width) + 2.06) + 'px';
          document.getElementsByClassName("el-dropdown2")[i].children[0].children[0].style.height = "43px";
        }
        for(var i = 0; i < document.getElementsByClassName("el-dropdown4").length; i++){
          document.getElementsByClassName("el-dropdown4")[i].children[0].children[0].style.borderRadius = "0 0.375rem 0.375rem 0";
          document.getElementsByClassName("el-dropdown4")[i].style.width = (parseFloat(document.getElementsByClassName("height_adjust_input")[0].clientWidth)) + "px";
          document.getElementsByClassName("el-dropdown4")[i].children[0].children[0].style.height = "46px";
        }
        for(var i = 0; i < document.getElementsByClassName("el-dropdown3").length; i++){
          document.getElementsByClassName("el-dropdown3")[i].style.width = document.getElementsByClassName("form-control")[0].clientWidth + "px";
          document.getElementsByClassName("el-dropdown3")[i].style.width = (parseFloat(document.getElementsByClassName("el-dropdown3")[i].style.width) + 2.06) + 'px';
        }
      },1);
    },
    calculate_el_select_width3(){
      setTimeout(()=>{
        for(var i = 0; i < document.getElementsByClassName("el-dropdown5").length; i++){
          document.getElementsByClassName("el-dropdown5")[i].children[0].children[0].style.borderRadius = "0 0.375rem 0.375rem 0";
          document.getElementsByClassName("el-dropdown5")[i].children[0].children[0].style.height = "43px";
        }
      }, 1);
    },
    calculate_el_select_width6(){
      setTimeout(()=>{
        for(var i = 0; i < document.getElementsByClassName("el-dropdown6").length; i++){
          document.getElementsByClassName("el-dropdown6")[i].children[0].children[0].style.borderRadius = "0 0.375rem 0.375rem 0";
          document.getElementsByClassName("el-dropdown6")[i].style.width = document.getElementsByClassName("form-control")[0].clientWidth - 4 + "px";
          document.getElementsByClassName("el-dropdown6")[i].children[0].children[0].style.height = "43px";
        }
      }, 1);
    },
    getBusinessDaysLater(curDate, businessDays) {
      var newDay = curDate.getDay() + businessDays;
      if (curDate.getDay() == 6) {
        businessDays += 1;
      } else if (curDate.getDay() == 7) {
        businessDays = businessDays;
      } else if (newDay > 5) {
        businessDays += 2;
      };
      var time = curDate.getTime();
      time += 24*3600*1000*businessDays
      return new Date(time);
    },
    quick_so() {
      // if has sn, return
      for (var i = 0; i < this.cur_so_product.length; i++) {
        if (this.cur_so_product[i].HasSerialNumber == 1 && this.cur_so_product[i].OrderQty != 0) {
          alert(this.cur_so_product[i].ProductName1 + " has Serial Number, can't do quick-so");
          return;
        }
      }

      this.submit_click_check_inventory('Complete');
    },
    getSummariesForComplete(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if ([4,5,6].includes(index)) {
          const values = data.map(item => Number(item[column.property]));
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = '$' + sums[index].toFixed(2);
        } else if (index === 0) {
          sums[index] = 'SubTotal:';
        } else {
          sums[index] = '';
        }
      });

      return sums;
    },
    selectSN(row){
      if (!this.return_mode) {
        // shipping
        for (let dpt in this.inventory_products) {
          for (let cat in this.inventory_products[dpt]) {
            for (let prd in this.inventory_products[dpt][cat]) {
              let inv = this.inventory_products[dpt][cat][prd];
              if (row.ProductIDn == inv.ProductIDn) {
                if (inv.SerialNumberListOri == undefined) {
                  inv.SerialNumberListOri =  inv.SerialNumber ? JSON.parse(inv.SerialNumber) : [];  // to keep sn in order
                }
                inv.SerialNumberList = inv.SerialNumber ? JSON.parse(inv.SerialNumber) : [];
                this.cur_sn_so_pro = row;
                this.cur_sn_inv = inv;
                this.input_sn = "";
                this.tmp_selected_sn = row.SerialNumbers ? JSON.parse(row.SerialNumbers) : [];
                
                // handle used SN by this product, recover the order
                if (this.tmp_selected_sn.length > 0) {
                  let mix_pro_inv_sn = [];
                  for (let i in inv.SerialNumberListOri) {
                    let sn = inv.SerialNumberListOri[i];
                    if (this.tmp_selected_sn.includes(sn)) {
                      mix_pro_inv_sn.push(sn);
                    } else if (inv.SerialNumberList.includes(sn)) {
                      mix_pro_inv_sn.push(sn);
                    }
                  }
                  // sn may be poped from so to inv, so inv may has some new sn
                  for (let i in inv.SerialNumberList) {
                    let sn = inv.SerialNumberList[i];
                    if (!mix_pro_inv_sn.includes(sn)) {
                      mix_pro_inv_sn.unshift(sn);
                    }
                  }
                  // so may be saved, sn may only exist in so
                  for (let i in this.tmp_selected_sn) {
                    let sn = this.tmp_selected_sn[i];
                    if (!mix_pro_inv_sn.includes(sn)) {
                      mix_pro_inv_sn.unshift(sn);
                    }
                  }
                  this.tobe_select_sn = mix_pro_inv_sn;
                } else {
                  this.tobe_select_sn = inv.SerialNumberList;
                }

                this.$refs["select-sn-modal"].show();
                return;
              }
            }
          }
        }
      } else {
        // return mode
        this.cur_sn_so_pro = row;
        this.tobe_select_sn = row.SerialNumbersOut ? JSON.parse(row.SerialNumbersOut) : [];
        // remove returned sn
        for (var k1 = 0; k1 < this.cur_so_return.length; k1++) {
          for (var k2 = 0; k2 < this.cur_so_return[k1].Products.length; k2++) {
            if (this.cur_so_return[k1].Products[k2].SOProductIDn == row.SOProductIDn) {
              let return_sn = this.cur_so_return[k1].Products[k2].SerialNumbers;
              return_sn = return_sn ? JSON.parse(return_sn) : [];
              this.tobe_select_sn = this.tobe_select_sn.filter(sn => !return_sn.includes(sn));
              break;
            }
          }
        }
        this.tmp_selected_sn = row.SerialNumbers ? JSON.parse(row.SerialNumbers) : [];
        if (this.tobe_select_sn.length > 0) {
          this.$refs["select-sn-modal"].show();
          return;
        }
      }
      alert("No S/N found for this product");
    },
    selectSNChange(val) {
      this.tmp_selected_sn = val;
    },
    searchSN() {
      if (this.tmp_selected_sn.includes(this.input_sn)) {
        alert("This S/N is already selected");
        return;
      }
      let table = this.$refs.selectSNTable;
      for (let i in this.tobe_select_sn) {
        let sn = this.tobe_select_sn[i];
        if (sn == this.input_sn) {
          table.toggleRowSelection(sn);
          this.input_sn = "";
          return;
        }
      }
      alert("Input S/N does not exist");
    },
    submit_sn() {
      if (this.tmp_selected_sn.length > parseInt(parseFloat(this.return_mode ? this.cur_sn_so_pro.ReturnQty : this.cur_sn_so_pro.ShippingQty))) {
        alert("Can't select more than " + (this.return_mode ? "return quantity" : "shipping quantity"));
        return;
      }
      this.cur_sn_so_pro.SerialNumbers = JSON.stringify(this.tmp_selected_sn);
      this.cur_sn_so_pro.SerialNumbersList = this.tmp_selected_sn;
      // this.cur_sn_so_pro.SerialNumbersStr = this.tmp_selected_sn.join(',');

      if (!this.return_mode) {
        // shipping
        let inventory_sn = [];
        for (let i in this.tobe_select_sn) {
          let sn = this.tobe_select_sn[i];
          if (!this.tmp_selected_sn.includes(sn)) {
            inventory_sn.push(sn);
          }
        }
        this.cur_sn_inv.SerialNumberList = inventory_sn;
        this.cur_sn_inv.SerialNumber = JSON.stringify(inventory_sn);
        this.add_changed_sn_inv(this.cur_sn_inv);
      } else {
        // return mode
      }
      this.$refs["select-sn-modal"].hide();
      this.$forceUpdate();
    },
    close_select_sn_model() {
      this.$refs["select-sn-modal"].hide();
    },
    add_changed_sn_inv(inv) {
      this.changed_sn_inv[inv.InvIDn] = inv;
    },
    handleCommand(selected) {
      this.per_page = selected;
      this.current_page=1;
      this.get_lists();
    },
    async get_customer_list() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Sales/Customer",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.company_typelist = rt.data.company_types;
              that.positionlist = rt.data.positions;
              that.pay_periodlist = rt.data.pay_periods;
              let empty_customer = {
                Name: "",
                OtherName: "",
                Tel: "",
                CompanyType: "",
                Address1:"",
                Address2: "",
                ProvinceIDn: 2,
                CountryIDn: 1,
                ZIP: "",
                PaymentTerm: "",
                Contact: {
                  FirstName: "",
                  LastName: "",
                  Tel: "",
                  Email: "",
                  Position: "",
                },
              };
              that.changed_country(empty_customer.CountryIDn, 4);
              that.new_customer = empty_customer;
              that.$refs["add-customer-modal"].show();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    addNewCustomer(){
      this.get_customer_list();
    },
    async submit_new_customer_address(){
      // Validate form
      if( this.new_customer.Name === ""  ||
          this.new_customer.Tel === ""  || 
          this.new_customer.CompanyType === ""  || 
          this.new_customer.Address1 === ""  || 
          this.new_customer.City === ""  || 
          this.new_customer.ProvinceIDn === ""  || 
          this.new_customer.CountryIDn === ""  || 
          this.new_customer.ZIP === ""  )
      {
        alert("Form not validated. Please complete all * fields.");
        return;
      }

      var re = /^[\d.\-()]+$/;
      var match_result = this.new_customer.Tel.match(re);
      if (match_result == null) {
        alert("Tel can only accept number, dot, dash, parentheses");
        return;
      }

      // Show loading in progress animation
      this.loading = true;

      let new_customer_addressProvince = "";
      let new_customer_addressCountry = "";
      
      // Get province name (FULL name, not province code. "Alberta" not "AB") from provinceIDn
      for (var i = 0; i < this.new_customer_provincelist.length; i++) {
        if(this.new_customer.ProvinceIDn === this.new_customer_provincelist[i].IDn){
          new_customer_addressProvince = this.new_customer_provincelist[i].Province;
        }
      }
      // Get country name from countryIDn
      let temp_country_list = JSON.parse(JSON.stringify(this.country_list));
      for (var i = 0; i < Object.keys(temp_country_list).length; i++) {
        if(this.new_customer.CountryIDn === temp_country_list[Object.keys(temp_country_list)[0]].IDn){
          new_customer_addressCountry = temp_country_list[Object.keys(temp_country_list)[0]].Country;
        }
      }

      // Create new address in MultiAdd
      let new_customer_address = {
        IDn: 0,
        Type: "customer",
        ReferenceIDn: 0,
        BillingFlag: 0,
        ShippingFlag: 0,
        Status: 1,
        Name: "",
        Street1: this.new_customer.Address1,
        Street2: this.new_customer.Address2,
        AddressCity: this.new_customer.City,
        ProvinceIDn: this.new_customer.ProvinceIDn,
        AddressProvince: new_customer_addressProvince,
        CountryIDn: this.new_customer.CountryIDn,
        AddressCountry: new_customer_addressCountry,
        ZIP: this.new_customer.ZIP,
        Remarks: "",
      };
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      for (var key in new_customer_address) {
        bodyFormData.append(key, new_customer_address[key]);
      }
      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/MultiAdd/submit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_address_list = [];
              var newaddr = rt.data;
              that.cur_address_list.push(newaddr);
              // console.log("that.cur_address_list", that.cur_address_list);
              if (newaddr.BillingFlag == 1) {
                that.cur_po.PurchaseFAddressIDn = newaddr.IDn;
              }
              that.submit_new_customer(rt.data.IDn, newaddr);
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            alert(response.statusText);
          }
        })
        .catch(function (error) {
          //XXXXX that.$router.push("/login");
        });
    },
    async submit_new_customer(address_IDn, newaddr){
      var valid = true;
      if(!valid){
        return;
      } else {
        var bodyFormData = new FormData();
        bodyFormData.append("token", this.token);
        bodyFormData.append("user_id", this.user_id);
        bodyFormData.append("opt", "add");
        bodyFormData.append("address_added", address_IDn);
        bodyFormData.append("CompanyName", this.new_customer.Name);
        bodyFormData.append("OtherName", this.new_customer.OtherName);
        bodyFormData.append("TEL", this.new_customer.Tel);
        bodyFormData.append("ContactFirstName1", this.new_customer.Contact.FirstName);
        bodyFormData.append("ContactLastName1", this.new_customer.Contact.LastName);
        bodyFormData.append("ContactCell1", this.new_customer.Contact.Tel);
        bodyFormData.append("ContactEMail1", this.new_customer.Contact.Email);
        bodyFormData.append("UserIDn", this.user_id);
        bodyFormData.append("Status", 1);
        if (this.new_customer.Contact.Position.IDn != undefined) {
          bodyFormData.append("ContactPositionIDn1", this.new_customer.Contact.Position.IDn);
        }
        bodyFormData.append("CompanyTypeIDn", this.new_customer.CompanyType.IDn);
        if (this.new_customer.PaymentTerm.IDn != undefined) {
          bodyFormData.append("PaymentTermIDn", this.new_customer.PaymentTerm.IDn);
        }
        bodyFormData.append("from_SO_flag", true);   // Can add customer from SO or Customer
        var that = this;
        axios({
          method: "post",
          url: "/Api/Web/Sales/Customer/submit",
          data: bodyFormData,
        })
          .then(function (response) {
            if (response.status == "200") {
              var rt = response.data;
              if (rt.status == 0) {
                var customer = rt.data[rt.data.length - 1];
                customer.Address = [newaddr];
                that.customer_list[customer.IDn] = customer;
                // that.cur_customer_name = customer.CompanyName;
                that.create_customer_datalist();
                that.customer_select = Object.values(that.customer_list);
                that.customer_selected(customer);
                that.loading = false;
                that.close_add_customer_modal();
              } else if (rt.status == 4) {
                that.loading = false;
                alert(that.$t("No Permission"));
                that.$router.go(-1);
              } else if (rt.message != undefined) {
                that.loading = false;
                alert(rt.message);
              }
            } else {
              that.loading = false;
              alert(response.statusText);
            }
          })
          .catch(function (error) {
            that.$router.push("/login");
          });
      }
    },
    close_add_customer_modal(){
      this.$refs["add-customer-modal"].hide();
    },
    get_selected_sn() {
      return this.tmp_selected_sn;
    },
  },
  mounted() {
    this.is_retail = getStore("is_retail");
    // this.is_retail = true;                        ///  test
    this.token = getStore("token");
    this.user_id = getStore("user_id");
    if (!this.token || !this.user_id) {
      this.$router.push("/login");
      return;
    }
    if (this.is_shipping()) {
      this.activeName = "Pending";  // BackOrder or Pending
    }
    this.get_init_data();

    // SN select modal, make selected SN to be checked
    this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
      if (modalId == "select-sn-modal") {
        // here a temp variable is needed because tmp_selected_sn will be changed by selectSNChange after toggleRowSelection
        var snList = this.get_selected_sn();
        if (snList.length > 0) {
          for (let i in snList) {
            this.$refs.selectSNTable.toggleRowSelection(snList[i]);
          }
        }
      }
    });

    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId == "return-modal") {
        this.return_mode = false;
      }
    });
  },
};
</script>
<style>
.content {
  padding-top: 40px;
}
hr.solid {
  border-top: 1px solid #bbb;
}
.input-label {
  display: inline-block;
  width: 130px;
}
.el-collapse-item__header {
  background-color: #a5d1fa;
  font-size: 1.3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  height: 2.2rem;
  border-radius: 0.75rem 0.75rem 0 0;
}
.el-collapse-item__content {
  padding-bottom: 0.5rem;
  border: 3px solid rgb(165, 209, 250);
}
.el-collapse-item__wrap {
  display: contents;
  content: "";
  clear: both;
  width: calc(100% - 5px);
  overflow-y: visible;
}

.addressinfo .el-collapse-item__content,
.productlist .el-collapse-item__content,
.sodetail .el-collapse-item__content {
  padding: 0;
}
.product_popup {
  padding: 2%;
  position: fixed;
  top: calc(50% - 21rem);
  left: 15%;
  height: 42rem;
  width: 70%;
  z-index: 10;
  background: white;
}
.group-append-text .input-group-text {
  flex: 1;
}

.product-table th,
.product-table td {
  padding: 0.1rem !important;
}
.product-table th div,
.product-table td div {
  padding: 0.1rem !important;
  line-height: 1.25rem;
  overflow: visible;
}

#search_modal .modal-title,
#import_modal .modal-title {
  font-size: 1.5rem;
}
#search_modal hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.search_product_section {
  overflow: auto;
}
.search_product_section .el-table td,
.el-table th {
  padding: 2px 0;
}
.product_row {
  border-top: 1px solid gray;
  padding-top: 5px;
}
.product_sn {
  max-width: 10rem;
  display: inline-flex;
}
.product_sn_col {
  max-height: 4rem;
  overflow: auto;
}
.btn-inner--icon .icon-clear {
  font-size: 1.8rem;
}
.btn-inner--icon .icon-clear:hover {
  cursor: pointer;
}
.btn-inner--icon .icon-add {
  color: green;
  font-size: 1.8rem;
}
.btn-inner--icon .icon-add:hover {
  cursor: pointer;
}
.btn-inner--icon .icon-remove {
  color: red;
  font-size: 1.8rem;
}
.btn-inner--icon .icon-remove:hover {
  cursor: pointer;
}
.so_title {
  font-weight: bold;
  font-size: 1.2rem;
}
.so_card_title {
  font-weight: 500;
  font-size: 1.2rem !important;
  padding: 0;
  line-height: inherit;
  color: inherit;
}
.so-edit .el-card__header {
  padding: 0.1rem 1rem;
}
.so-edit div {
  font-size: 1rem;
}
.so-edit .el-card__body {
  padding: 0.2rem;
}
.so-edit .icon-add {
  color: #404040;
  background-color: rgba(0, 255, 0, 0.5);
  border-radius: 50%;
}
.el-input.is-disabled .el-input__inner{
  background-color: #e9ecef !important;
  border: 1px solid #cad1d7;
  color: #32325d
}
.err{
  border:1px solid red;
}
.height_adjust_input {
  height: 46px;
}
#product_list .el-table__expand-icon>.el-icon{
  display: none;
}
#product_list textarea{
  height: 30px;
  padding-top: 3px;
  padding-bottom: 3px;
}
#return_product_list .el-table__expand-icon>.el-icon{
  display: none;
}
#return_product_list textarea{
  height: 30px;
  padding-top: 3px;
  padding-bottom: 3px;
}
#all_product_list .el-table__body-wrapper{
  height: 207px !important;
}
#selectSNTable .el-table__body-wrapper{
  height: 260px !important;
}
#selectSNTable .el-table-column--selection {
    padding-left: 20px !important;
    padding-top: 5px !important;
}
.el-table__footer-wrapper div {
  text-align: center;
  font-weight: bold;
}
.left_padding {
  padding-left: 15px !important;
}
.hover_hand:hover {
  cursor: pointer;
}
.red_color {
  color: red;
}
.query-backorder-radio {
  margin-top: 20px;
}
</style>